
import "./../../styles/home.css";
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom'
import CryptoJS from "crypto-js";
import  EncryptText from "../../encrypt-decrypt/encrypt";
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Alert,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { Form, FormControl, Table, Pagination, Button, Dropdown, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import DecryptText from "../../encrypt-decrypt/decrypt";
import { getStudentRegistration, otpVerify, studentRegistrationStatus, trackingCodeVerify } from "../../services";
import OTPInput, { ResendOTP } from "otp-input-react";
import  '../../styles/tracking.css'
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import NavbarPublic from "../../components/navbar-public";
import Footermain from "../../components/footer";
import LiveChatButton from "../livechat/LiveChatButton";

function Tracking() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [login, setLogin] = useState("login");
    const encrpt = EncryptText;
    const decrypt =DecryptText
    const getReg = searchParams.get('getReg')
    const status = searchParams.get('status')
    var getStatus =decrypt(status)
  const navigate = useNavigate();
  const [modelBulk, setModelBulk] = useState(false);
  const [validated, setValidated] = useState(false);
  const [trackingCode, setTrackingCode] = useState("");
  const [getTrackingData, setTrackingData] = useState(null);
  const steps = [
    { id: 1, label: 'Personal Info', component: <StepOne getTrackingData={getTrackingData} /> },
    { id: 2, label: 'Contact Details', component: <StepTwo getTrackingData={getTrackingData} /> },
    { id: 3, label: 'Tracking Info', component: <StepThree getTrackingData={getTrackingData} /> },
    { id: 4, label: 'Confirm Status', component: <StepFour getTrackingData={getTrackingData} /> },
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  // Move to the next step
  const handleNextStep = () => {
    if (currentStep < steps.length) {
      setCompletedSteps([...completedSteps, currentStep]);
      setCurrentStep(currentStep + 1);
    }
  };

  // Move to the previous step
  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  useEffect((e)=>{
   try{
    studentRegistrationStatus(getReg).then((response) => {
      if(response.data.error && getStatus === 'student')
        {
          toast.error(response.data.error)
        }
        if(response.data.finalStatus && getStatus === 'student')
          {
            toast.success(response.data.finalStatus)
          }
          if(response.data.managerPending && getStatus === 'student')
            {
              toast.success(response.data.managerPending)
            }
            if(response.data.success && getStatus === 'student')
              {
                setModelBulk(true)
              }    
              if(response.data.success && getStatus === 'manager')
                {
                  setModelBulk(false)
                }    
        
    }).catch((error)=>{toast.error(error)})
   }
   catch(error){
    toast.error(error)
   }
    
   
    
  },[])
  const trackingSearchClick=(event)=>{
    event.preventDefault();
    const form = event.currentTarget;
    if (trackingCode === '') {
      event.stopPropagation();
      setValidated(true);
      toast.error('Please fill in the required field.');
      return;
    }
    setValidated(false);
    
    try{
        trackingCodeVerify({getReg,trackingCode}).then((response) => {
       
        if(response.data != undefined)
          {
           
            if(response.data.error)
              toast.error(response.data.error)
            if(response.data.success == true)
                setModelBulk(false)
                setTrackingData(response.data.data)
                
          }
          
      }).catch((error)=>{toast.error(error)})
    }
    catch(error){toast.error(error)}
   
    
  }
    return (
      <div>
         <NavbarPublic btnLogin={login}/>
        <Container fluid >
        <div className="row text-center">
        <h4 style={{fontWeight:"bold"}}></h4></div>
     
                    <Modal
         size={"md"}
         show={modelBulk}
         onHide={() => setModelBulk(true)}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
         style={{
          zIndex: 1050, // Adjust the z-index as needed
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
        }}
       >
         <Modal.Header style={{background:"#c90057"}}  closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m1d" style={{color:"white"}}>
           <span >Tracking</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
        <Form noValidate validated={validated} onSubmit={trackingSearchClick}>
              <Row>
              <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>Tracking Code</Form.Label>
             
              <Form.Control
            type="text"
            placeholder="Enter Your Tracking Code"
            value={trackingCode}
            onChange={(e)=>{setTrackingCode(e.target.value)}}
            isInvalid={trackingCode === ''}
          />
           <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
  <Form.Text className="text-muted">
  Please enter your tracking code send by us in your email.
  </Form.Text>
          </Form.Group>
              </Row>
              <br/>
              <br/>
              <Row>
                
                <Form.Group className="mb-1">
                  <Button
                    style={{ background: "#7f083c", border: "2px solid #c90057", width: "100%" }}
                   
                    type="submit"
                  >
                    Search
                  </Button>
                </Form.Group>
              </Row>
            </Form>
        </Modal.Body>
      </Modal> 
      <div className="row text-center">
      <h4 style={{fontWeight:"bold",textTransform:"uppercase"}}>{getStatus} Tracking Information</h4></div>
      <div className="container">
      {/* Step Indicator */}
      <div className="stepIndicator">
        {steps.map((step) => (
          <div
            key={step.id}
            className={`step ${
              currentStep === step.id
                ? 'current'
                : completedSteps.includes(step.id)
                ? 'completed'
                : 'upcoming'
            }`}
          >
            <div className="icon">
              {completedSteps.includes(step.id) ? <FaCheckCircle /> : <FaCircle />}
            </div>
            <div className="label">{step.label}</div>
            {step.id !== steps[steps.length - 1].id && <div className="connector"></div>}
          </div>
        ))}
      </div>

      {/* Step Content with Animation */}
      <div className="content">
        {steps.map((step) => (
          <CSSTransition
            key={step.id}
            in={currentStep === step.id}
            timeout={500}
            classNames="content"
            unmountOnExit
          >
            <div>{step.component}</div>
          </CSSTransition>
        ))}
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button
          onClick={handlePreviousStep}
          className="previousButton"
          disabled={currentStep === 1}
        >
          Previous
        </button>
        <button
          onClick={handleNextStep}
          className="nextButton"
        >
          {currentStep === steps.length ? 'Submit' : 'Next Step'}
        </button>
      </div>
    </div>

      <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />  
    <div 
    >
      <LiveChatButton style={{ margin: '0 auto' }} getReg={getReg}/>
    </div>
    </Container>
    
    <Footermain/>
      </div>
    );
  }
  const StepOne = ({ getTrackingData }) => (
    <div>
    <h4 style={{ textAlign: 'center', color: '#000', fontWeight: 'bold' }}>Personal Info</h4>
    <br />
    {getTrackingData ? (
      <table style={{ border: '1px solid #ddd', padding: '10px', borderCollapse: 'collapse'
      ,width:'100%',color: '#000', fontWeight: 'bold' }}>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Name</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.fullName}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Gender</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.gender === 'male'?'Male':getTrackingData.gender === 'female'?'Female':'Others'}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>DOB</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{new Date(getTrackingData.dob).toLocaleDateString('en-US', { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  })}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Age</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.age}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Course Name</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.courseFullName}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Course Type</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.proType}</td>
        </tr>
      </table>
    ) : (
      <p>Loading data...</p> // or a suitable message when data is not available
    )}
  </div>
  

  );
  
  const StepTwo = ({ getTrackingData }) => (
    <div>
    <h4 style={{ textAlign: 'center', color: '#000', fontWeight: 'bold' }}>Contact Details</h4>
    <br />
    {getTrackingData ? (
      <table style={{ border: '1px solid #ddd', padding: '10px', borderCollapse: 'collapse',width:'100%'
      ,color: '#000', fontWeight: 'bold' }}>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Phone</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.phone}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Email</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.email}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Address Line1</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.pAddress1}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Address Line2</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.pAddress2}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>City</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.pCity}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>District</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.pDistrict}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Zipcode</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.pPost}</td>
        </tr>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>State</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.pState}</td>
        </tr>
      </table>
    ) : (
      <p>Loading data...</p> // or a suitable message when data is not available
    )}
  </div>
  

  );
  
  const StepThree = ({ getTrackingData }) => (
    <div>
    <h4 style={{ textAlign: 'center', color: '#000', fontWeight: 'bold' }}>Tracking Info</h4>
    <br />
    {getTrackingData ? (
      <table style={{ border: '1px solid #ddd', padding: '10px', borderCollapse: 'collapse'
      ,width:'100%',color: '#000', fontWeight: 'bold' }}>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Applicant</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.processingStatus}</td>
        </tr>
      
      </table>
    ) : (
      <p>Loading data...</p> // or a suitable message when data is not available
    )}
  </div>
  

  );
  
  const StepFour = ({ getTrackingData }) => (
    <div>
    <h4 style={{ textAlign: 'center', color: '#000', fontWeight: 'bold' }}>Confirm Status</h4>
    <br />
    {getTrackingData ? (
      <table style={{ border: '1px solid #ddd', padding: '10px', borderCollapse: 'collapse'
      ,width:'100%',color: '#000', fontWeight: 'bold' }}>
        <tr>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>Process State</td>
          <td style={{ padding: '10px', border: '1px solid #ddd' }}>{getTrackingData.trackingStatus}</td>
        </tr>
        
      </table>
    ) : (
      <p>Loading data...</p> // or a suitable message when data is not available
    )}
  </div>
  

  );
  
  export default Tracking;