import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fontsource/cormorant-garamond"; // Defaults to weight 400
import "@fontsource/cormorant-garamond/400.css"; // Specify weight
import "@fontsource/cormorant-garamond/400-italic.css"; // Specify weight and style
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

reportWebVitals();
