import React, { useState,useEffect,useRef } from 'react';
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Alert,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { Form, FormControl, Table, Pagination, Button, Dropdown, Modal, ProgressBar, Image  } from 'react-bootstrap';
import ImageIcon from '@mui/icons-material/Image';
import { CSVLink } from 'react-csv';
import AddIcon from '@mui/icons-material/Add';
import SignaturePad from "react-signature-canvas";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  PictureAsPdf,
  InsertDriveFile,
  Description,
  TableChart,
  TextSnippet,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import { addCourse, addEnglishBook, deleteCourse, deleteEnglishBook, deleteEnglishBookPDF, getCourseAndDepartments, getEnglishBook, getEnglishBookFiles, getStudentRegistration, getStudentRegistrationAll, postBulkExcelFile, postBulkExcelFileCourse, publicRegistration, sendOTP, studentRegister, updateCourse, updateEnglishBook } from '../../services';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import './../../styles/modelFulscreen.css';
import PdfFlip from '../../components/pdf-flip';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './../../styles/custom-calander.css'
import DecryptText from '../../encrypt-decrypt/decrypt';
import EncryptText from '../../encrypt-decrypt/encrypt';
import Loader from '../../components/loader-custom';
import { useNavigate } from 'react-router-dom';
import NavbarPublic from '../../components/navbar-public';
import Footermain from '../../components/footer';
import { useSearchParams } from 'react-router-dom';
function Admission() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const decry =DecryptText
  const encry =EncryptText
  const [loading, setLoading] = useState(false);
  const getData = searchParams.get('status')
  var getDataStatus =decry(getData)

 const [formValues, setFormValues] = useState({
   regNo: '',fName: '', mName: '',lName: '',gender: '',dob: '',age: '',bGroup: '',mStatus: '', nation: '',con1: '',
   con2: '', email: '',fatName: '',fPhone: '',fOccu: '',moName: '',moPhone: '', mOccu: '',tAddress1: '',tAddress2: '',
   tCity: '',tDistrict: '', tPost: '',tState: '',pAddress1: '',pAddress2: '',pCity: '',pDistrict: '',pPost: '', pState: '',
   emConName: '', relation: '',emPhone: '',emEmail: '',academic: [{ course: "", university: "",yearOfPassout: "", totalMarks: "",percentage: "",
   }], program: '',degreeLev: '',study: '',totalYear: '',otp:'',otpVerify:false,studentVerify:false,managerVerify:false,
   proType: '',courseFullName: '',curriculum: null,curriculumOther: '',paricipatin: '',resource: '', skil: '', sports: '',
   tcFiles: [],applicantPhotoFile: [], markFiles: [],otherFiles: [],regName: '',regEmail: '',stuSign: null,diractorName: '',diractorEmail: '',diractorSign: null,
   studentVerifyUrl:'',managerVerifyUrl:'',experienceStatus:'',workExperience: [{ institute: "", place: "",durationFrom: "", durationTo: "",positionHeld: ""}],
   appliedFor:'',processingStatus:'',trackingCode:'',trackingStatus:'',commands:[]
 
 });
 const [selectedFile, setSelectedFile] = useState(null);
 const [preview, setPreview] = useState(null);
 const [error, setError] = useState(null);
 const [dob, setDob] = useState(null);
 const [stuRegNo, setStuRegNo] = useState("");
  const [validated, setValidated] = useState(false);
  const [courseGet, setCourseGet] = useState([]);
 const currentYear = new Date().getFullYear();
 const handleDateChange = (date) => {
   setDob(date);
   setFormValues(prevValues => ({
     ...prevValues,
     dob:new Date(date),
     age:(calculateAge(date))+' Years'
   }));
   // setValidated(true); // Reset validation when date changes
 };
 const years = [];
 for (let i = 1950; i <= currentYear; i++) {
   years.push(i);
 }
 const [errors, setErrors] = useState({
    email: "",
    phone: "",
experience:""
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/; // Simple 10-digit number regex
    return phoneRegex.test(phone);
  };

 // Handle add row
 const handleAddRow = () => {
   const isAnyFieldEmpty = formValues.academic.some(detail => {
     return (
       detail.course === "" ||
       detail.university === "" ||
       detail.yearOfPassout === "" ||
       detail.totalMarks === "" ||
       detail.percentage === ""
     );
   });
 
   if (isAnyFieldEmpty) {
     // Show an error or toast message if needed
     toast.error("Please fill in all the fields before adding a new row.");
     return;
   }
   setFormValues(prevValues => ({
       ...prevValues,
       academic: [
           ...prevValues.academic,
           { course: "", university: "", yearOfPassout: "", totalMarks: "", percentage: "" }
       ]
   }));
};

const handleDeleteRow = index => {
   setFormValues(prevValues => ({
       ...prevValues,
       academic: prevValues.academic.filter((_, i) => i !== index)
   }));
};
const handleExperienceAddRow = () => {
    const isAnyFieldEmpty = formValues.workExperience.some(detail => {
      return (
        detail.institute === "" ||
        detail.place === "" ||
        detail.durationFrom === "" ||
        detail.durationTo === "" ||
        detail.positionHeld === ""
      );
    });
  
    if (isAnyFieldEmpty) {
      // Show an error or toast message if needed
      toast.error("Please fill in all the fields before adding a new row.");
      return;
    }
    setFormValues(prevValues => ({
        ...prevValues,
        workExperience: [
            ...prevValues.workExperience,
            { institute: "", place: "", durationFrom: "", durationTo: "", positionHeld: "" }
        ]
    }));
 };
 
 const handleExperienceDeleteRow = index => {
    setFormValues(prevValues => ({
        ...prevValues,
        workExperience: prevValues.workExperience.filter((_, i) => i !== index)
    }));
 };

 const maxDate = new Date();
 maxDate.setFullYear(maxDate.getFullYear() - 15);
 const calculateAge = (selectedDate) => {
   const today = new Date();
   const birthDate = new Date(selectedDate);
   let age = today.getFullYear() - birthDate.getFullYear();
   const month = today.getMonth() - birthDate.getMonth();
   if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
     age--;
   }
   return age;
 };

 useEffect( (e)=>{
  let apiProtocol = window.location.protocol;
  let apiHost = window.location.host;
    getStudentRegistration().then((response) => {
     if(response.data != undefined)
       {
     setStuRegNo(response.data)
  
       setFormValues(prevValues => ({
         ...prevValues,
         regNo:response.data,
         studentVerifyUrl:apiProtocol + '//' + apiHost + '/public-registration-email-link?getReg='+response.data+'&status='+encry('student'),
        managerVerifyUrl:apiProtocol + '//' + apiHost + '/public-registration-email-link?getReg='+response.data+'&status='+encry('manager')
 
       }));
       }
   
   }).catch((error)=>{console.log(error)})
   
   getCourseAndDepartments().then((response) => {
     if(response.data != undefined)
     {
       setCourseGet(response.data)
     }
    
   }).catch((error)=>{console.log(error)})
      
   
 },[])

 async function handleSubmit( event){
   event.preventDefault();
   const form = event.currentTarget;
   if (form.checkValidity() === false) {
     event.stopPropagation();
     setValidated(true);
     toast.error('Please fill in the required fields.');
     return;
   }
   if (!formValues.applicantPhotoFile.length) {
    toast.error('Please upload the required files.');
    setValidated(true);
    return;
  }
   if(formValues.experienceStatus === '')
   {
    setErrors({
        ...errors,
        experience: formValues.experienceStatus ? "" : "Please select an option.",
      });
      toast.error('Please select an option.');
      setValidated(true);
      return;
   }
    
     
   try {
    
     setLoading(true)
    
  
     const formData = new FormData();

       formData.append('academic', JSON.stringify(formValues.academic));
       if (formValues.applicantPhotoFile && formValues.applicantPhotoFile.length > 0) {
        formData.append('applicantPhotoFile', formValues.applicantPhotoFile[0]); // Only one curriculum file
    }
       if(formValues.experienceStatus === 'yes')
        formData.append('workExperience', JSON.stringify(formValues.workExperience));
       // Append form fields
       Object.keys(formValues).forEach(key => {
         if (!['tcFiles', 'markFiles', 'otherFiles', 'curriculum', 'academic', 'applicantPhotoFile', 'workExperience'].includes(key)) {
             formData.append(key, formValues[key]);
         }
     });
      
     const responses = await publicRegistration(formData)
 .then(async (response) => {
   try {
     if (response.data) {
       toast.success("Your application has been saved successfully! Please check your email for application tracking information.");
       setTimeout(() => {
        navigate('/');
      }, 5000);
     }
   } catch (error) {
     // Catch any errors that occur inside the .then() block
     toast.error("Error sending OTP: " + error.message);
   }
 })
 .catch((error) => {
   // Catch errors from studentRegister or the whole promise chain
   toast.error("Error during registration: " + error.message);
 });

   }
   catch (error) {
     toast.error('Failed to save registration. Please try again.');
   }
   finally{
     setLoading(false)
   }
   setValidated(false);
   
  
 };
 const handleAcademicChange = (index, event) => {
   const { name, value } = event.target;
   setFormValues(prevValues => ({
       ...prevValues,
       academic: prevValues.academic.map((item, i) =>
           i === index ? { ...item, [name]: value } : item
       )
   }));
};
const handleExperienceChange = (index, event) => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
        ...prevValues,
        workExperience: prevValues.workExperience.map((item, i) =>
            i === index ? { ...item, [name]: value } : item
        )
    }));
 };
 const handleInputChange = (e,status) => {
   const { name, value } = e.target;
   let updatedValues
  
   setFormValues((prevValues) => {
     // Update the selected field (like 'program')
    
     if(status === 'program')
     {
       updatedValues = { ...prevValues, [value]: value };
       const selectedCourse = courseGet.find(course => course.courseId === value);
 
         if (selectedCourse) {
           // Update additional fields based on the selected course
           updatedValues = {
             ...updatedValues,
             program:selectedCourse.courseShortName,
             totalYear: selectedCourse.years+' Years',
             proType: selectedCourse.statusOfStudy== 'biblical'?"Biblical Program":selectedCourse.proType == 'lay'?"Lay People Program":"Short Study Program",
             courseFullName: selectedCourse.courseFullName,
            
           };
         }
     }
        if(status === 'experience')
            updatedValues = { ...prevValues, experienceStatus: value };
       if(status === 'fName')
       {
       
         updatedValues = { ...prevValues, fName: value, 
        appliedFor:getDataStatus,
        processingStatus:'New Application',
        trackingStatus:'Your applied application verify  procss is inprogress . We will respond to you soon!. ',
        
       }}
       if(status === 'mName')
         updatedValues = { ...prevValues, mName: value };
       if(status === 'lName')
         updatedValues = { ...prevValues, lName: value };
       if(status === 'gender')
         updatedValues = { ...prevValues, gender: value };
       if(status === 'bGroup')
         updatedValues = { ...prevValues, bGroup: value };
       if(status === 'mStatus')
         updatedValues = { ...prevValues, mStatus: value };
       if(status === 'nation')
         updatedValues = { ...prevValues, nation: value };
       if(status === 'con1')
         updatedValues = { ...prevValues, con1: value };
       if(status === 'con2')
         updatedValues = { ...prevValues, con2: value };
       if(status === 'email')
         updatedValues = { ...prevValues, email: value };
       if(status === 'fatName')
         updatedValues = { ...prevValues, fatName: value };
       if(status === 'fPhone')
         updatedValues = { ...prevValues, fPhone: value };
       if(status === 'fOccu')
         updatedValues = { ...prevValues, fOccu: value };
       if(status === 'moName')
         updatedValues = { ...prevValues, moName: value };
       if(status === 'moPhone')
         updatedValues = { ...prevValues, moPhone: value };
       if(status === 'mOccu')
         updatedValues = { ...prevValues, mOccu: value };
       if(status === 'tAddress1')
         updatedValues = { ...prevValues, tAddress1: value };
       if(status === 'tAddress2')
         updatedValues = { ...prevValues, tAddress2: value };
       if(status === 'tCity')
         updatedValues = { ...prevValues, tCity: value };
       if(status === 'tDistrict')
         updatedValues = { ...prevValues, tDistrict: value };
       if(status === 'tPost')
         updatedValues = { ...prevValues, tPost: value };
       if(status === 'tState')
         updatedValues = { ...prevValues, tState: value };
       if(status === 'pAddress1')
         updatedValues = { ...prevValues, pAddress1: value };
       if(status === 'pAddress2')
         updatedValues = { ...prevValues, pAddress2: value };
       if(status === 'pCity')
         updatedValues = { ...prevValues, pCity: value };
       if(status === 'pDistrict')
         updatedValues = { ...prevValues, pDistrict: value };
       if(status === 'pPost')
         updatedValues = { ...prevValues, pPost: value };
       if(status === 'pState')
         updatedValues = { ...prevValues, pState: value };
       if(status === 'emConName')
         updatedValues = { ...prevValues, emConName: value };
       if(status === 'relation')
         updatedValues = { ...prevValues, relation: value };
       if(status === 'emPhone')
         updatedValues = { ...prevValues, emPhone: value };
       if(status === 'emEmail')
         updatedValues = { ...prevValues, emEmail: value };
       if(status === 'degreeLev')
         updatedValues = { ...prevValues, degreeLev: value };
       if(status === 'study')
         updatedValues = { ...prevValues, study: value };

     if (status === "email") {
      setErrors({
        ...errors,
        email: validateEmail(value) ? "" : "Invalid email address",
      });
    }

    if (status === "con1") {
      setErrors({
        ...errors,
        phone: validatePhone(value) ? "" : "Invalid phone number",
      });
      
    }
    if (status === "experience") {
      setErrors({
        ...errors,
        experience: '',
      });
      
    }
    
     return updatedValues;
   });
 };
 const calculateTotalYears = (workExperience) => {
    return workExperience.reduce((total, detail) => {
      if (detail.durationFrom && detail.durationTo) {
        const years = parseInt(detail.durationTo) - parseInt(detail.durationFrom);
        return total + years;
      }
      return total;
    }, 0);
  };
  //upload passport photo
  const handleFileChange = (event, status) => {
    const files = event.target.files;
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp', 'heic', 'heif'];

    if (files.length > 0) {
        let allValid = true;

        // Check if all files are valid
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                allValid = false;
                break;
            }
        }

        if (allValid) {
          const filePreview = URL.createObjectURL(files[0]);
          setPreview(filePreview);
            // Update the appropriate file array in formValues based on status
            setFormValues(prevValues => {
                let updatedFiles;
                if (status === "stu") {
                    updatedFiles = Array.from(files);
                    return {
                        ...prevValues,
                        applicantPhotoFile: updatedFiles
                    };
                }
            });
            setValidated(false); // Reset validation if valid files are selected
        } else {
            event.target.value = ''; // Clear invalid selection
            setFormValues(prevValues => ({
                ...prevValues,
                [status === "stu" ? "applicantPhotoFile":[]] :[] // Reset the appropriate file array
            }));
            setPreview(null);
            setValidated(true); // Show validation error
            toast.warn('Please select valid image files (.jpg, .jpeg, .png, .webp, .heic, .heif)');
        }
    }
};
 

  // Delete photo function
  const handleDeletePhoto = (event, status) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [status === "stu" ? "applicantPhotoFile":[]] :[] // Reset the appropriate file array
  }));
    setPreview(null);
  };
  const [login, setLogin] = useState("login");
 return (
   <div>
     <NavbarPublic btnLogin={login}/>
     <Container fluid >
        <div className="row text-center">
        <h4 style={{fontWeight:"bold",textTransform:"uppercase"}}>{getDataStatus} Admission Form</h4></div>
       <Row className="mb-12" >
       <div style={{width:"100%",textAlign:"center"}}>
        <div style={{display:"flex",width:"100%",justifyContent:"center"}}>
         <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>{getDataStatus === 'student'?'Student ':getDataStatus === 'faculty'?'Faculty ':'Staff'} Registration Number: &nbsp;</span>
         <span style={{fontSize:"15pt",color:"blue",fontWeight:"bold"}}>{stuRegNo}</span></div></div>
       
     </Row>
     <Row className="mb-4">
        <Form.Group as={Col} md="3">
          {/* Add other form fields if needed */}
        </Form.Group>
        <Form.Group as={Col} md="3">
          {/* Add other form fields if needed */}
        </Form.Group>
        <Form.Group as={Col} md="3">
          {/* Add other form fields if needed */}
        </Form.Group>
        {!preview && (<Form.Group as={Col} md="3">
          <Form.Label>Upload Profile Photo</Form.Label>
      <Form.Control 
    type="file" 
    accept=".jpg,.jpeg,.png,.webp,.heic,.heif" 
    required 
    onChange={(e) => handleFileChange(e, "stu")} 
    isInvalid={!formValues.applicantPhotoFile.length}
  />
  <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
  <Form.Text className="text-muted">
    Please select image files (.jpg, .jpeg, .png, .webp, .heic, .heif formats). 
  </Form.Text>
          
        </Form.Group>)}
        {preview && (
          <Form.Group as={Col} md="3">
            <div className="image-container">
              <Image src={preview} alt="Profile Preview" className="passport-photo" thumbnail />
             
            </div>
         
            </Form.Group>
          )}
      </Row>
      <Row className="mb-4">
        <Form.Group as={Col} md="3">
          {/* Add other form fields if needed */}
        </Form.Group>
        <Form.Group as={Col} md="3">
          {/* Add other form fields if needed */}
        </Form.Group>
        <Form.Group as={Col} md="3">
          {/* Add other form fields if needed */}
        </Form.Group>
       
        {preview && (
          <Form.Group as={Col} md="3">
            
            <div className="mt-2">
                 <Button variant="danger" onClick={(e) => handleDeletePhoto(e, "stu")} >
                  Delete Photo
                </Button>
              </div>
            </Form.Group>
          )}
      </Row>
     <Row>
    
         <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Personal Information</span>
      
     </Row>
       <Form noValidate validated={validated} onSubmit={handleSubmit}>
     <Row className="mb-3">
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>First Name</Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="First Name"
           value={formValues.fName}
           onChange={(e)=>handleInputChange(e,'fName')}
           isInvalid={formValues.fName === ''}
         />
         <Form.Control.Feedback type="invalid">
   *Required
 </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Middle Name</Form.Label>
         <Form.Control
           type="text"
           placeholder="Middle Name"
           value={formValues.mName}
           onChange={(e)=>handleInputChange(e,'mName')}
         />
         <Form.Control.Feedback type="invalid">
 
 </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>Last Name</Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="Last Title"
           value={formValues.lName}
           onChange={(e)=>handleInputChange(e,'lName')}
           isInvalid={formValues.lName === ''}
         />
         <Form.Control.Feedback type="invalid">
          *Required
         </Form.Control.Feedback>
       </Form.Group>
      
     </Row>
     <Row className="mb-3">
     <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Gender</Form.Label>
       
         <Form.Select isInvalid={formValues.gender ===""} aria-label="Default select example"  
         onChange={(e)=>handleInputChange(e,'gender')}  placeholder="Gender"
         value={formValues.gender}
         >
         <option value="" disabled >
     --Select--
   </option>
           <option value="male">Male</option>
           <option value="female">Female</option>
           <option value="neuter">Neuter</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
            Please select 
           </Form.Control.Feedback>
       </Form.Group>
     <Form.Group as={Col} md="4" controlId="valiom02">
           <Form.Label>Date of Birth</Form.Label>
          
           <DatePicker
             selected={dob}
             onChange={handleDateChange}
             className={`form-control  custom-datepicker ${!dob ? 'is-invalid' : ''}`}
             dateFormat="MM/dd/yyyy"
             isInvalid={!dob}
             maxDate={maxDate}
             placeholderText="MM/DD/YYYY" // Placeholder added here
           />
          
           {/* {dob && (
             <p>
               Age: {calculateAge(dob)} years
             </p>
           )} */}
           <Form.Text style={{fontSize:"10pt"}} className={!dob ? 'text-danger' : ''}>
   {!dob && "Required"}
 </Form.Text>
         </Form.Group>
      
       {dob?(<Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>Age</Form.Label>
         <Form.Control
          type="text"
          disabled="true"
           value={dob &&`${'Age: '+ (calculateAge(dob))+' Years'}`}
         />
       </Form.Group>):""}
      
     </Row>
     <Row className="mb-3">
     <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>Blood Group</Form.Label>
         <Form.Select isInvalid={formValues.bGroup ===""} aria-label="Default select example"  
          onChange={(e)=>handleInputChange(e,'bGroup')}  placeholder="Blood Group"
         value={formValues.bGroup}
         >
         <option value="" disabled >
     --Select--
   </option>
           <option value="A+">A+</option>
           <option value="A-">A-</option>
           <option value="B+">B+</option>
           <option value="B-">B-</option>
           <option value="O+">O+</option>
           <option value="O-">O-</option>
           <option value="AB+">AB+</option>
           <option value="AB-">AB-</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
            Please select 
           </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Marital Status</Form.Label>
         <Form.Select isInvalid={formValues.mStatus ===""} aria-label="Default select example"  
         onChange={(e)=>handleInputChange(e,'mStatus')}  placeholder="Marital Status"
         value={formValues.mStatus}
         >
         <option value="" disabled >
     --Select--
   </option>
           <option value="Single">Single</option>
           <option value="Married">Married</option>
           <option value="Widowed">Widowed</option>
           <option value="Divorced">Divorced</option>
           <option value="Separated">Separated</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
            Please select
           </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Nationality</Form.Label>
         <Form.Control
           required
           type="text"
           placeholder="Nationality"
           value={formValues.nation}
           onChange={(e)=>handleInputChange(e,'nation')}
           isInvalid={formValues.nation === ''}
         />
         <Form.Control.Feedback type="invalid">
   *Required
 </Form.Control.Feedback>
       </Form.Group>
     </Row>
     <Row>
    
    <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Contact Information</span>
 
</Row>
<Row className="mb-3">
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Contact 1</Form.Label>
         <Form.Control
           required
          type="phone"
           placeholder="Contact 1"
           value={formValues.con1}
           onChange={(e)=>handleInputChange(e,'con1')}
           isInvalid={formValues.con1 === ''}
           maxLength={10}
         />
         {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
         <Form.Control.Feedback type="invalid">
   *Required
 </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Contact 2</Form.Label>
         <Form.Control
           type="text"
           placeholder="Contact 2"
           value={formValues.con2}
           onChange={(e)=>handleInputChange(e,'con2')}
         />
         <Form.Control.Feedback type="invalid">
  
 </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>Email</Form.Label>
         <Form.Control
           required
            type="email"
           placeholder="Email"
           value={formValues.email}
           onChange={(e)=>handleInputChange(e,'email')}
           isInvalid={formValues.email === ''}
         />
         {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
        <Form.Control.Feedback type="invalid">
   *Required
 </Form.Control.Feedback>
       </Form.Group>
      
     </Row>
     {getDataStatus === 'student'&&(<Row className="mb-3">
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Father's Name</Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="Father's Name"
           value={formValues.fatName}
           onChange={(e)=>handleInputChange(e,'fatName')}
           isInvalid={formValues.fatName === ''}
         />
         <Form.Control.Feedback type="invalid">
   *Required
 </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Father's Contact Number</Form.Label>
         <Form.Control
           required
           type="text"
           placeholder="Father's Contact Number"
           value={formValues.fPhone}
           onChange={(e)=>handleInputChange(e,'fPhone')}
           isInvalid={formValues.fPhone === ''}
           maxLength={10}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>Occupation </Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="Occupation"
           value={formValues.fOccu}
           onChange={(e)=>handleInputChange(e,'fOccu')}
           isInvalid={formValues.fOccu === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
      
     </Row>)}
      {getDataStatus === 'student'&&(
        <Row className="mb-3">
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Mother's Name</Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="Mother's Name"
           value={formValues.moName}
           onChange={(e)=>handleInputChange(e,'moName')}
           isInvalid={formValues.moName === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Mother's Contact Number</Form.Label>
         <Form.Control
           required
           type="text"
           placeholder="Mother's Contact Number"
           value={formValues.moPhone}
           onChange={(e)=>handleInputChange(e,'moPhone')}
           isInvalid={formValues.moPhone === ''}
           maxLength={10}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>Occupation </Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="Occupation"
           value={formValues.mOccu}
           onChange={(e)=>handleInputChange(e,'mOccu')}
           isInvalid={formValues.mOccu === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
      
     </Row>)}
     <Row>
    
    <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Present Address</span>
 
</Row>
     <Row className="mb-3">
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Street Address Line1</Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="Street Address Line1"
           value={formValues.tAddress1}
           onChange={(e)=>handleInputChange(e,'tAddress1')}
           isInvalid={formValues.tAddress1 === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Street Address Line2</Form.Label>
         <Form.Control
           required
           type="text"
           placeholder="Street Address Line2"
           value={formValues.tAddress2}
           onChange={(e)=>handleInputChange(e,'tAddress2')}
           isInvalid={formValues.tAddress2 === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>City </Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="City"
           value={formValues.tCity}
           onChange={(e)=>handleInputChange(e,'tCity')}
           isInvalid={formValues.tCity === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
      
     </Row>
     <Row className="mb-3">
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>District</Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="District"
           value={formValues.tDistrict}
           onChange={(e)=>handleInputChange(e,'tDistrict')}
           isInvalid={formValues.tDistrict === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Postal / Zip Code</Form.Label>
         <Form.Control
           required
           type="text"
           placeholder="Postal / Zip Code"
           value={formValues.tPost}
           onChange={(e)=>handleInputChange(e,'tPost')}
           isInvalid={formValues.tPost === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>State / Province </Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="State / Province"
           value={formValues.tState}
           onChange={(e)=>handleInputChange(e,'tState')}
           isInvalid={formValues.tState === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
      
     </Row>
     <Row>
    
         <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Permanent Address</span>
      
     </Row>
     <Row className="mb-3">
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Street Address Line1</Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="Street Address Line1"
           value={formValues.pAddress1}
           onChange={(e)=>handleInputChange(e,'pAddress1')}
           isInvalid={formValues.pAddress1 === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Street Address Line2</Form.Label>
         <Form.Control
           required
           type="text"
           placeholder="Street Address Line2"
           value={formValues.pAddress2}
           onChange={(e)=>handleInputChange(e,'pAddress2')}
           isInvalid={formValues.pAddress2 === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>City </Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="City"
           value={formValues.pCity}
           onChange={(e)=>handleInputChange(e,'pCity')}
           isInvalid={formValues.pCity === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
      
     </Row>
     <Row className="mb-3">
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>District</Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="District"
           value={formValues.pDistrict}
           onChange={(e)=>handleInputChange(e,'pDistrict')}
           isInvalid={formValues.pDistrict === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Postal / Zip Code</Form.Label>
         <Form.Control
           required
           type="text"
           placeholder="Postal / Zip Code"
           value={formValues.pPost}
           onChange={(e)=>handleInputChange(e,'pPost')}
           isInvalid={formValues.pPost === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom02">
         <Form.Label>State / Province </Form.Label>
         <Form.Control
           required
          type="text"
           placeholder="State / Province"
           value={formValues.pState}
           onChange={(e)=>handleInputChange(e,'pState')}
           isInvalid={formValues.pState === ''}
           />
           <Form.Control.Feedback type="invalid">
     *Required
   </Form.Control.Feedback>
       </Form.Group>
      
     </Row>
  

     <Row>
    
    <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Academic Background</span>
 
</Row>
<Row>
<Table striped bordered hover style={{borderBottom:"3px solid #7f083c",borderRadius:"15px"}}>
       <thead>
         <tr>
           <th>Academic Details</th>
           <th>College/University</th>
           <th>Year of Passout</th>
           <th>Total Marks</th>
           <th>Percentage</th>
           <th>Actions</th>
         </tr>
       </thead>
       <tbody>
         {formValues.academic.map((detail, index) => (
           <tr key={index}>
             <td>
               <Form.Select
                 isInvalid={detail.course === ""}
                 aria-label="Default select example"
                 onChange={(e) => handleAcademicChange(index, e)}
                 name="course"
                 value={detail.course}
                 required
               >
                 <option value="" disabled>
                   --Select--
                 </option>
                 <option value="SSLC">SSLC</option>
                 <option value="+2">+2</option>
                 <option value="B.A">B.A</option>
                 <option value="B.Com">B.Com</option>
                 <option value="B.B.A">B.B.A</option>
                 <option value="B.Sc">B.Sc</option>
                 <option value="B.E">B.E</option>
                 <option value="B.Tech">B.Tech</option>
                 <option value="B.Arch">B.Arch</option>
                 <option value="B.Ed">B.Ed</option>
                 <option value="B.Th">B.Th</option>
                 <option value="M.A">M.A</option>
                 <option value="M.Com">M.Com</option>
                 <option value="M.B.A">M.B.A</option>
                 <option value="M.Sc">M.Sc</option>
                 <option value="M.E">M.E</option>
                 <option value="M.Tech">M.Tech</option>
                 <option value="M.Arch">M.Arch</option>
                 <option value="M.Div">M.Div</option>
                 <option value="M.Th">M.Th</option>
                 <option value="M.Phil">M.Phil</option>
                 <option value="Ph.D">Ph.D</option>
               </Form.Select>
               <Form.Control.Feedback type="invalid">
                 Please select
               </Form.Control.Feedback>
             </td>

             <td>
               <Form.Control
                 required
                 type="text"
                 placeholder="College/University"
                 value={detail.university}
                 onChange={(e) => handleAcademicChange(index, e)}
                 name="university"
                 isInvalid={detail.university === ''}
                 />
                 <Form.Control.Feedback type="invalid">
           *Required
         </Form.Control.Feedback>
             </td>

             <td>
               <Form.Select
               required
                 isInvalid={detail.yearOfPassout === ""}
                 aria-label="Default select example"
                 onChange={(e) => handleAcademicChange(index, e)}
                 name="yearOfPassout"
                 value={detail.yearOfPassout}
               >
                 <option value="" disabled>
                   --Select--
                 </option>
                 {years.map((year) => (
                   <option key={year} value={year}>
                     {year}
                   </option>
                 ))}
               </Form.Select>
               <Form.Control.Feedback type="invalid">
                 Please select 
               </Form.Control.Feedback>
             </td>

             <td>
               <Form.Control
                 required
                 type="text"
                 placeholder="Total Marks"
                 value={detail.totalMarks}
                 onChange={(e) => handleAcademicChange(index, e)}
                 name="totalMarks"
                 isInvalid={detail.totalMarks === ''}
                 />
                 <Form.Control.Feedback type="invalid">
           *Required
         </Form.Control.Feedback>
             </td>

             <td>
               <Form.Control
                 required
                 type="text"
                 placeholder="%"
                 value={detail.percentage}
                 onChange={(e) => handleAcademicChange(index, e)}
                 name="percentage"
                 isInvalid={detail.percentage === ''}
                 />
                 <Form.Control.Feedback type="invalid">
           *Required
         </Form.Control.Feedback>
             </td>

             <td>
               <div style={{ display: "flex", justifyContent: "space-between" }}>
                 <AddIcon
                   onClick={handleAddRow}
                   style={{ fontWeight: "bold", color: "green", cursor: "pointer", fontSize: "25pt" }}
                   title="Add"
                 />
                 {formValues.academic.length > 1 && (
                   <DeleteIcon
                     onClick={() => handleDeleteRow(index)}
                     style={{ fontWeight: "bold", color: "red", cursor: "pointer", fontSize: "23pt" }}
                     title="Delete"
                   />
                 )}
               </div>
             </td>
           </tr>
         ))}
       </tbody>
     </Table></Row>
     {getDataStatus === 'student'&&( <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Preferred Program</span>)}
    {getDataStatus === 'student'&&( <Row className="mb-3">
     <Form.Group as={Col} md="4" controlId="validationCustom02">
     <Form.Label>Program of Interest</Form.Label>
     <Form.Select
     required
       aria-label="Default select example"
       onChange={(e)=>handleInputChange(e,'program')}
       name="program"
       value={formValues.program}
       isInvalid={formValues.program === ''}
     >
       <option value="" disabled>
         --Select--
       </option>
       {courseGet.map((option, idx) => (
         <option key={idx} value={option.courseId}>
           {option.courseShortName}
         </option>
       ))}
     </Form.Select>
     <Form.Control.Feedback type="invalid">
       Please select
     </Form.Control.Feedback>
   </Form.Group>
       
       <Form.Group as={Col} md="4" controlId="validationCustom01">
         <Form.Label>Degree Level</Form.Label>
       
         <Form.Select isInvalid={formValues.degreeLev ===""} aria-label="Default select example"  
         onChange={(e)=>handleInputChange(e,'degreeLev')}  placeholder="Degree Level"
         value={formValues.degreeLev} required
         >
         <option value="" disabled >
     --Select--
   </option>
           <option value="ug">Undergraduate</option>
           <option value="pg">Postgraduate</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
            Please select  
           </Form.Control.Feedback>
       </Form.Group>
       <Form.Group as={Col} md="4" controlId="validationCustom01">
       <Form.Label>Mode of Study</Form.Label>
       
         <Form.Select isInvalid={formValues.study ===""} aria-label="Default select example"  
         onChange={(e)=>handleInputChange(e,'study')}  placeholder="Mode of Study"
         value={formValues.study} required
         >
         <option value="" disabled >
     --Select--
   </option>
           <option value="Full">Full-time</option>
           <option value="Part">Part-time</option>
           <option value="Short">Short-studies</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
            Please select  
           </Form.Control.Feedback>
       </Form.Group>
      
     </Row>)}
     {formValues.program && (
 <Row className="mb-3">
   <Form.Group as={Col} md="2">
     <Form.Label>Total Year's of Course</Form.Label>
     <Form.Control
       type="text"
       disabled
       value={formValues.totalYear}
     />
    
   </Form.Group>

   <Form.Group as={Col} md="3" >
     <Form.Label>Program Type</Form.Label>
     <Form.Control
       type="text"
       disabled
       value={formValues.proType}
     />
     
   </Form.Group>

   <Form.Group as={Col} md="7">
     <Form.Label>Course Full Name</Form.Label>
     <Form.Control
       type="text"
       disabled
       value={formValues.courseFullName}
       title={formValues.courseFullName}
     />
     
   </Form.Group>
 </Row>
)}
 <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Experience Information</span>
<Row>
<Form.Group as={Col} md="2" controlId="validationCustom02">
<Form.Label>Do you have any experience?</Form.Label>
      
      </Form.Group>
      <Form.Group as={Col} md="2" controlId="validationCustom02">

      <div key={'radio'} style={{display:"flex"}} >
        <Form.Check 
          type="radio"
          label="Yes"
          value="yes"
          style={{paddingRight:"10px",cursor:"pointer"}}
          checked={formValues.experienceStatus === "yes"}
          onChange={(e)=>handleInputChange(e,'experience')}
          name="reactExperience"
        />
        <Form.Check 
          type="radio"
          label="No"
          value="no"
          style={{cursor:"pointer"}}
          checked={formValues.experienceStatus === "no"}
          onChange={(e)=>handleInputChange(e,'experience')}
          name="reactExperience"
        />
      </div>
      {errors.experience && <p style={{ color: "red" }}>{errors.experience}</p>}
      </Form.Group>
    </Row>
    {formValues.experienceStatus === 'yes' && (
  <Row>
    <Table striped bordered hover style={{ borderBottom: "3px solid #7f083c", borderRadius: "15px" }}>
      <thead>
        <tr>
          <th>Institution/Organisation</th>
          <th>Place</th>
          <th>Duration</th>
          <th>Position Held</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {formValues.workExperience.map((detail, index) => (
          <tr key={index}>
            <td>
              <Form.Control
                required
                type="text"
                placeholder="Institution/Organisation"
                value={detail.institute}
                onChange={(e) => handleExperienceChange(index, e)}
                name="institute"
                isInvalid={detail.institute === ''}
              />
              <Form.Control.Feedback type="invalid">
                *Required
              </Form.Control.Feedback>
            </td>
            <td>
              <Form.Control
                required
                type="text"
                placeholder="Place"
                value={detail.place}
                onChange={(e) => handleExperienceChange(index, e)}
                name="place"
                isInvalid={detail.place === ''}
              />
              <Form.Control.Feedback type="invalid">
                *Required
              </Form.Control.Feedback>
            </td>
            <td style={{ display: "flex", justifyContent: "space-between", gap: "5px", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <Form.Label style={{ marginTop: "5px", paddingRight: "5px" }}>From:</Form.Label>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <Form.Select
                    required
                    isInvalid={detail.durationFrom === "" || detail.durationFrom >= detail.durationTo}
                    aria-label="Duration From"
                    onChange={(e) => handleExperienceChange(index, e)}
                    name="durationFrom"
                    value={detail.durationFrom}
                  >
                    <option value="" disabled>--Select--</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {detail.durationFrom >= detail.durationTo
                      ? 'Start date should be less than end date'
                      : 'Please select'}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <Form.Label style={{ marginTop: "5px", paddingRight: "5px" }}>To:</Form.Label>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <Form.Select
                    required
                    isInvalid={detail.durationTo === "" || detail.durationFrom >= detail.durationTo}
                    aria-label="Duration To"
                    onChange={(e) => handleExperienceChange(index, e)}
                    name="durationTo"
                    value={detail.durationTo}
                  >
                    <option value="" disabled>--Select--</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {detail.durationFrom >= detail.durationTo
                      ? 'End date should be greater than start date'
                      : 'Please select'}
                  </Form.Control.Feedback>
                </div>
              </div>
            </td>
            <td>
              <Form.Control
                required
                type="text"
                placeholder="Position Held"
                value={detail.positionHeld}
                onChange={(e) => handleExperienceChange(index, e)}
                name="positionHeld"
                isInvalid={detail.positionHeld === ''}
              />
              <Form.Control.Feedback type="invalid">
                *Required
              </Form.Control.Feedback>
            </td>
            <td>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <AddIcon
                  onClick={handleExperienceAddRow}
                  style={{ fontWeight: "bold", color: "green", cursor: "pointer", fontSize: "25pt" }}
                  title="Add"
                />
                {formValues.workExperience.length > 1 && (
                  <DeleteIcon
                    onClick={() => handleExperienceDeleteRow(index)}
                    style={{ fontWeight: "bold", color: "red", cursor: "pointer", fontSize: "23pt" }}
                    title="Delete"
                  />
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={5}>
            <strong>Total Years of Experience:</strong> {calculateTotalYears(formValues.workExperience)}
          </td>
        </tr>
      </tfoot>
    </Table>
  </Row>
)}
     <Row className="mb-3">
     <Form.Group as={Col} md="4">
    
       </Form.Group>
       <Form.Group as={Col} md="4">
       </Form.Group>
     <Form.Group as={Col} md="4">
     <br/>
   <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
        buttonvariant="outline-success"  type="submit">
         Submit
       </Button>
      
     </Form.Group>
     </Row>
     {/* <Button type="submit">Submit form</Button> */}
   </Form>
  
     
  
   <ToastContainer
   
   autoClose={3000}
   closeOnClick={false}
   pauseOnHover
    />
    {loading && <Loader />}
    </Container>
    <Footermain/>
   </div>
 );
};
  
  export default Admission;