import {
    Container,
    Row ,
    Col

   } from "react-bootstrap";
import Homenav from "../components/homeNav";
import Footermain from "../components/footer";
import Slidermain from "../components/slider";
import './../styles/ImageSlider.css'
import Coursesmain from "./coursesMain";
import { useState, useEffect } from "react";
import VideoConference from "./videoconference/VideoConference";

function Homemain(prop) {
  const [login, setLogin] = useState("login");
    return (
     <div>
        <Homenav btnLogin={login}/>
        <Container fluid >
        <Row>
        <Col xs={6} md={6}>
         <Slidermain/>
        </Col>
        <Col xs={6} md={6}>
       <Coursesmain/>
        </Col>
      </Row>
    </Container>
    {/* <VideoConference/> */}
      <Footermain/>
        </div>
         
         
     
      
    );
  }
  
  export default Homemain;