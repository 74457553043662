import React, { useState, useEffect, useRef } from 'react';
import PageFlip  from 'react-pageflip';
import { Document, Page, pdfjs } from 'react-pdf';

import "./../../styles/pdf-flip.css";
const FlipBook = ({ pdf }) => {
  const [pages, setPages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const canvasRef = useRef(null);

  useEffect(() => {
    loadPDF(pdf);
  }, [pdf]);

  const loadPDF = (pdf) => {
    pdfjs.getDocument(pdf).promise.then((pdf) => {
      const loadedPages = [];
      const promises = [];
      for (let i = 1; i <= pdf.numPages; i++) {
        promises.push(pdf.getPage(i));
      }
      Promise.all(promises).then((pages) => {
        pages.forEach((page) => {
          const scale = 1.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext).promise.then(() => {
            const imageURL = canvas.toDataURL('image/png');
            loadedPages.push(imageURL);

            if (loadedPages.length === pdf.numPages) {
              setPages(loadedPages);
              setIsLoaded(true);
            }
          });
        });
      });
    });
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <PageFlip
      width={600}
      height={600}
      className="flip-book"
      size="stretch"
      minWidth={600}
      maxWidth={1000}
      minHeight={600}
      maxHeight={1533}
      orientation="horizontal"
      startPage={0}
      onPageChange={(page) => {
        console.log(`Current page: ${page}`);
      }}
    >
      {pages.map((page, index) => (
        <div className="page" key={index}>
          <img src={page} alt={`Page ${index + 1}`} />
        </div>
      ))}
    </PageFlip>
  );
};

export default FlipBook;
