import "./../../styles/home.css";
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Alert,
  Modal,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import  EncryptText from "../../encrypt-decrypt/encrypt";
function ClassroomMain() {
  const encrpt =EncryptText
  const [optionShow, setOptionShow] = useState(false);
  const [meetShow, setMeetShow] = useState(false);
  const [optionStatus, setOptionStatus] = useState(null);
  const [modelText, setModelText] = useState(null);
  const navigate = useNavigate();
  const modelHideClick =((e)=>{
    // setLgShow(false)
  })
  const classDeskClick = async (e,status)=>{
   if(status == "classroom")
   {
   setOptionShow(true)
    setOptionStatus("classroom")
   }
   else if(status == "meet")
    {
      setOptionShow(true)
      setOptionStatus("meet")
    }
   else
   {
    navigate('/user/dashboard-main?getData='+encrpt(status))
   }
    // navigate('/user/dashboard-main?getData='+encrpt(status))
   
}
const coursesClick = (async (el,status)=>{
  el.preventDefault()
  navigate('/user/dashboard-main?getData='+encrpt(status))
}) 
    return (
      <div>
        <div className="row text-center">
         <h4 style={{fontWeight:"bold"}}>CLASSROOM DESK</h4></div>
        <div className="row">
                        <div className="col-lg-3 mb-4">
                            <div title="click !"  onClick={(e)=>{classDeskClick(e,"classroom")}}  style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow text-center">
                                <div className="card-body">
                                 Class Room Visit
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !"    style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow text-center">
                                <div className="card-body">
                                Staff (or) Office Admit
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow text-center">
                                <div className="card-body">
                                Academic Resources
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" onClick={(e)=>{classDeskClick(e,"meet")}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow text-center">
                                <div className="card-body">
                                Generate Meet Link
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
       <div className="row">
        <div className="text-center">
         <h4 style={{fontWeight:"bold"}}>{}</h4></div>
                      
         <Modal
         size={"lg"}
         show={optionShow}
         onHide={() => setOptionShow(false)}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>setOptionShow(false)} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="example-modal-sizes-title-sm" style={{color:"white"}}>
           <span >Choose Options</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>

{optionStatus === "classroom"?<table style={{width:"100%",justifyContent:"space-between"}}>
  <tbody >
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       onClick={(e)=>{coursesClick(e,"classview")}}
       buttonvariant="outline-success"  
       type="submit"
       >
        Departments and Courses

      </Button></td>
    <td><Button onClick={(e)=>{classDeskClick(e,'tami-book')}} style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
       Student's
        
      </Button></td>
  </tr>
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       onClick={(e)=>{setMeetShow(true);setOptionShow(false)}}
       buttonvariant="outline-success"  
       type="submit"
       >
        Teacher's

      </Button></td>
    <td><Button onClick={(e)=>{classDeskClick(e,'tami-book')}} style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
       Library's
        
      </Button></td>
  </tr>
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       onClick={(e)=>{setMeetShow(true);setOptionShow(false)}}
       buttonvariant="outline-success"  
       type="submit"
       >
        Office's

      </Button></td>
    <td><Button onClick={(e)=>{classDeskClick(e,'tami-book')}} style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
       Others's
        
      </Button></td>
  </tr>
</tbody>
</table>:""}
{optionStatus === "meet"?<table style={{width:"100%",justifyContent:"space-between"}}>
  <tbody >
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       onClick={(e)=>{setMeetShow(true);setOptionShow(false)}}
       buttonvariant="outline-success"  
       type="submit"
       >
        Gererate classroom meet link

      </Button></td>
    <td><Button onClick={(e)=>{classDeskClick(e,'tami-book')}} style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
       Gererate Chapel meet link
        
      </Button></td>
  </tr>
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
        Gererate Live Conference meet link

      </Button></td>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
        Gererate Faculty & Staff meet link

      </Button></td>
  </tr>
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
       Gererate Teacher's meet link

      </Button></td>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
        Gererate Office's meet link

      </Button></td>
  </tr>
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
       Gererate Library's meet link

      </Button></td>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
        Gererate other's meet link

      </Button></td>
  </tr>
</tbody>
</table>:""}      
       


        </Modal.Body>
      </Modal>

      <Modal
         size={"lg"}
         show={meetShow}
         onHide={(e)=>{setOptionShow(false);setMeetShow(false)}}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>{setOptionShow(false);setMeetShow(false)}} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="example-modal-sizes-title-sm" style={{color:"white"}}>
           <span >Generate Meet Link</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>

<table style={{width:"100%",justifyContent:"space-between"}}>
  <tbody >
 
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
       Gererate Library's meet link

      </Button></td>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
        Gererate other's meet link

      </Button></td>
  </tr>
</tbody>
</table>
        
       


        </Modal.Body>
      </Modal>
                    </div>
                    <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
      </div>
    );
  }
  
  export default ClassroomMain;