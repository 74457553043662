import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextField, Button, List, ListItem, ListItemText, CircularProgress, 
    Snackbar, Alert, InputAdornment, IconButton 
} from '@mui/material';
import { addChatPhotos, addCommandsLiveChat, getApiBaseURL, getLiveChat } from '../../services';
import '../../styles/LiveChat.css';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InsertEmoticon from '@mui/icons-material/InsertEmoticon';
import EmojiPicker from 'emoji-picker-react'; // Import the emoji picker
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import {
    Row,
    Col,
    Form,
    Container,Modal
  } from 'react-bootstrap';
  import logo from './../../assets/images/admin.png'
import NavbarPublic from '../../components/navbar-public';
import Footermain from '../../components/footer';
import EncryptText from '../../encrypt-decrypt/encrypt';
import DecryptText from '../../encrypt-decrypt/decrypt';
import { useSearchParams } from 'react-router-dom'
const renderImages = async (photos) => {
  const urls = await Promise.all(photos.map(file => photoGetAndPreviews(file.url)));
        
  return urls.filter(url => url !== null);;
};
const photoGetAndPreviews = async (url) => {
  const serverUrl = getApiBaseURL();
  if (url) {
    const photoUrl = `${serverUrl}/user${url}`;
    try {
      const res = await fetch(photoUrl);
      if (!res.ok) {
        throw new Error(`Failed to fetch image: ${res.statusText}`);
      }
      const blob = await res.blob();
      const reader = new window.FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(new Error("Failed to read file"));
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching or processing the image:", error);
    }
  }
  return null; // Return null if no URL is provided
};
const LiveChat = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [messangerGet, setMessanger] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null); // State to handle selected message for quick replies
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to control emoji picker visibility
  const isMounted = useRef(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filess, setFiless] = useState([]);
  const [openAlert, setOpenAlert] = useState(false); // For alert after submission
  const [uploadPhotoPreview, setUploadPhotoPreview] = useState(false);
  const [modelSize, setModelSize] = useState('sm');
  const [imageWidth, setImageWidth] = useState(150);
  const [imageHeight, setImageHeight] = useState(150);
  const [login, setLogin] = useState("login");
  
  const encrpt = EncryptText;
    const decrypt =DecryptText
    const getReg = searchParams.get('getReg')
    const status = searchParams.get('status')
    var getStatus =decrypt(status)
  const modelHideClick =((e)=>{
    setUploadPhotoPreview(false)
  })
 // Handle multiple image file uploads and preview
 const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      return ['jpeg', 'jpg', 'png', 'gif'].includes(fileExtension);
    });

    if (validFiles.length > 0 && validFiles.length <=10) {
      setSelectedFiles(validFiles);
      setUploadPhotoPreview(true)
      if (validFiles.length >=2 && validFiles.length <=4)
      {
        setModelSize('lg')
        setImageHeight(185)
        setImageWidth(185)
      }
      else if (validFiles.length >=5 && validFiles.length <=10)
      {
        setModelSize('xl')
        setImageHeight(150)
        setImageWidth(150)
      }
        else
        {
            setModelSize('sm')
            setImageHeight(250)
            setImageWidth(250)
        }
         
    }
    else if (validFiles.length >10)
    {
        toast.warn('You have exceeded fixed image file limitation. Upload file limit maximum 10 image files');
    }
     else {
      e.target.value = ''; // Reset the input if no valid file
      toast.warn('Please select image files (.jpeg, .jpg, .png, .gif)');
    }
  };

  // Submit message and files
  const sendMessage = async () => {
    if (message) {
      try {
        // Prepare form data to send the message and files
        const formData = new FormData();
            formData.append('message', message.trim());
            formData.append('isUser', true);

        await addCommandsLiveChat(getReg,formData);
        setMessage('');
        setSelectedFiles([]);
        setOpenAlert(true); // Show alert after submission
        fetchMessages(true); // Fetch updated messages
      } catch (error) {
        setError('Error sending message.');
        console.error('Error sending message:', error);
      }
    }
  };
//chat photos post
const chatPhotosUploadClick=(async(e)=>{
    e.preventDefault()
    if (selectedFiles.length > 0) {
        try {
          // Prepare form data to send the message and files
          const formData = new FormData();
          formData.append('message', "");
          formData.append('isUser', true);
          selectedFiles.forEach((file) => formData.append('chatPhoto', file));
  
          await addChatPhotos(getReg,formData);
          setUploadPhotoPreview(false)
          setMessage('');
          setSelectedFiles([]);
          fetchMessages(true); // Fetch updated messages
          
        } catch (error) {
          setError('Error sending message.');
          console.error('Error sending message:', error);
        }
      }
})
  const openEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev); // Toggle emoji picker visibility
  };

  const onEmojiClick = (emojiObject) => {
    if (emojiObject && emojiObject.emoji) {
      setMessage((prev) => prev + emojiObject.emoji);
    } else {
      toast.error('Expected emoji property not found');
    }
    setShowEmojiPicker(false);
  };
  const formatDate = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30); // Rough approximation for months
    const years = Math.floor(days / 365); // Rough approximation for years
  
    if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now'; // Less than a minute ago
    }
  };
  const formatDays = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(date);;
    }
  };
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hour from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = String(hours).padStart(2, '0');
  
    return `${hours}:${minutes} ${ampm}`;
  };
  
  // Example usage
 
  
  const fetchMessages = useCallback(async (status) => {
    try {
      if(!status)return
      setLoading(false);
      const response = await getLiveChat();
      
      // if (isMounted.current) {
        if (response?.data?.[0]?.chat) {
          setMessages(response.data[0].chat); // Set chat messages
          setMessanger(response?.data)
        } 
      // }
    } catch (error) {
      setError('Error fetching chat messages.');
      console.error('Error fetching chat messages:', error);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, []);

  
  const handleQuickReply = (reply) => {
    setMessage(reply); // Set message to quick reply text
    sendMessage(); // Send quick reply message
    setSelectedMessage(null); // Close quick reply options
  };

  useEffect(() => {
    
    fetchMessages(true); // Initial fetch
    const interval = setInterval(fetchMessages, 5000);

    return () => {
      clearInterval(interval);
      isMounted.current = false; // Clean up on unmount
    };
  }, [fetchMessages]);
  const groupedMessages = messages.reduce((acc, msg) => {
    
    const date = formatDays(msg.timestamp);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(msg);
    return acc;
  }, {});

const previewFile = (url) => {
  if (url.startsWith('data:image')) {
    openImageInNewTab(url); // Use the openImageInNewTab function for base64 images
  } else {
    previewFile(url); // Call the previewFile function for other files
  }
};

//photos get & preview
const [imageUrls, setImageUrls] = useState({});

// Function to fetch image data URL
const photoGetAndPreview = async (url) => {
  const serverUrl = getApiBaseURL();
  if (url) {
    const photoUrl = `${serverUrl}/user${url}`;
    try {
      const res = await fetch(photoUrl);
      if (!res.ok) {
        throw new Error(`Failed to fetch image: ${res.statusText}`);
      }
      const blob = await res.blob();
      const reader = new window.FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(new Error("Failed to read file"));
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching or processing the image:", error);
    }
  }
  return null; // Return null if no URL is provided
};

// Fetch all images for each messenger
useEffect(() => {
  const fetchImages = async () => {
    const newImageUrls = {};
    for (const er of messangerGet) {
      if (er.photo && er.photo.length > 0) {
        const urls = await Promise.all(er.photo.map(file => photoGetAndPreview(file.url)));
        newImageUrls[er.id] = urls.filter(url => url !== null); // Assuming er has a unique 'id'
      }
    }
    setImageUrls(newImageUrls);
  };

  if (messangerGet && messangerGet.length > 0) {
    fetchImages();
  }
}, [messangerGet]);
  return (
    
    <div >
       <NavbarPublic btnLogin={login}/>
       {/* <Container fluid > */}
      <h2 className="chat-header">Live Chat</h2>
      <Container fluid>
      <Row className="mb-2">
      <Form.Group as={Col} md="3">
      
      <Container fluid style={{textAlign:'center'}} className="chat-messanger">
      {messangerGet && messangerGet.map((er, index) => (
        <div key={index}>
          <span style={{ fontWeight: 'bold', color: '#000' }}>Messenger Details</span>
          <div>
            <br />
            <div className="image-preview">
              {imageUrls[er.id] && imageUrls[er.id].length > 0 ? (
                imageUrls[er.id].map((imageUrl, fileIndex) => (
                  <div key={fileIndex} style={{ marginBottom: '10px' }}>
                    <img
                      src={imageUrl}
                      alt={`Image ${fileIndex}`}
                      style={{ width: '70px', height: '70px',borderRadius:'50%',cursor:'pointer' }}
                      title='Click Preview!'
                      onClick={() => previewFile(imageUrl)}
                    />
                  </div>
                ))
              ) : (
                ""
              )}
            </div>
          </div>
          <div style={{ textAlign: 'center', color: '#000' }}>
            <br />
            <table style={{ textAlign: 'justify', fontSize: '8pt', fontWeight: 'bold', width: '100%' }}>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td style={{ textAlign: 'left' }}>{er.fullName || 'Stephen'}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td style={{ textAlign: 'left' }}>{er.email || 'Test@gmail.com'}</td>
                </tr>
                <tr>
                  <td>Phone:</td>
                  <td style={{ textAlign: 'left' }}>{er.phone || '1111111111'}</td>
                </tr>
                <tr>
                  <td>Messenger:</td>
                  <td style={{ textAlign: 'left' }}>{er.messenger || 'Student'}</td>
                </tr>
                <tr>
                  <td>District:</td>
                  <td style={{ textAlign: 'left' }}>{er.district || 'Kanyakumari'}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td style={{ textAlign: 'left' }}>{er.state || 'Tamil Nadu'}</td>
                </tr>
                <tr>
                  <td>Nation:</td>
                  <td style={{ textAlign: 'left' }}>{er.nation || 'Indian'}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <span style={{ fontWeight: 'bold', color: '#000', fontSize: '9pt' }}>Status</span>
            <table style={{ textAlign: 'justify', fontSize: '8pt', fontWeight: 'bold', width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }}>{er.status || 'New Applicant'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
      </Container>
      </Form.Group>
      <Form.Group as={Col} md="9">
      <Container fluid  className="chat-container">
        {loading ? (
          <CircularProgress />
        ) : (
          <div className="chat-messages">
            <List>
              {Object.keys(groupedMessages).map((date, dateIndex) => (
                <div key={dateIndex}>
                  {/* Display the date only once per group */}
                  <div className='chat-date-flex'><div className="chat-date">{date}</div></div>
                  {groupedMessages[date].map((msg, index) => (
                    <div key={index}>
                      <ListItem
                        className={`chat-message ${msg.isUser ? 'sent' : 'received'}`}
                        onClick={() => setSelectedMessage(msg.message)}
                      >
                        <div className="chat-message-bubble">
                            <div style={{display:'grid',width:'100%',justifyContent:'space-between'}}>
                          <div className='font-size-set'>{msg.message}</div>
                          <div>
                          {msg.photos && msg.photos.length > 0 ? (
                              <LoadingImageRenderer photos={msg.photos} previewFile={previewFile} />
                            ) : (
                              ""
                            )}
                          </div></div>
                          <div className="chat-message-time" style={{float:'right'}}>
                            {formatTime(msg.timestamp) + ' (' + formatDate(msg.timestamp) + ')'}
                          </div>
                        </div>
                      </ListItem>
                      {/* {selectedMessage === msg.message && (
                        <div className="quick-replies">
                          <Button onClick={() => handleQuickReply('Quick Reply 1')}>Quick Reply 1</Button>
                          <Button onClick={() => handleQuickReply('Quick Reply 2')}>Quick Reply 2</Button>
                          <Button onClick={() => handleQuickReply('Quick Reply 3')}>Quick Reply 3</Button>
                        </div>
                      )} */}
                    </div>
                  ))}
                </div>
              ))}
            </List>
          </div>
        )}

        {/* Snackbar for error messages */}
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error">
            {error}
          </Alert>
        </Snackbar>

        {/* Chat input container */}
        
      </Container>
      
      </Form.Group>
      </Row></Container>
      <Container fluid>
      <Row className="mb-1">
      <Form.Group as={Col} md="12">
      <div className="chat-input-container">
      <TextField
  placeholder="Enter your message"
  variant="outlined"
  fullWidth
  multiline
  minRows={1}
  value={message} // Ensure this is controlled by the state
  onChange={(e) => setMessage(e.target.value)}
  onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage()}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <IconButton style={{ color: '#c90057' }} onClick={openEmojiPicker}>
          <InsertEmoticon />
        </IconButton>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="icon-button-file"
          type="file"
          onChange={handleImageUpload}
          multiple
        />
        <label htmlFor="icon-button-file">
          <IconButton style={{ color: '#c90057' }} aria-label="upload picture" component="span">
            <PhotoCamera />
          </IconButton>
        </label>
      </InputAdornment>
    ),
    endAdornment: (
      <Button
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          sendMessage();
        }}
        style={{ marginLeft: '10px', background: '#c90057', borderTopRightRadius: '100%', borderBottomRightRadius: '100%', border: '3px solid #7f083c' }}
      >
        Send
      </Button>
    ),
  }}
  style={{ overflow: 'hidden' }} // Ensures the height is adjusted smoothly
/>

        {/* Show emoji picker */}
        {showEmojiPicker && (
          <div className="emoji-picker">
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </div>
        )}
      </div>
      </Form.Group>
      </Row>
      </Container>
      {/* Show image preview */}
      <Modal
         size={modelSize}
         show={uploadPhotoPreview}
         onHide={() => modelHideClick()}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
         style={{
            zIndex: 1050, // Adjust the z-index as needed
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
          }}
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m2d" style={{color:"white"}}>
          <span>Selected files preview</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
        {selectedFiles.length > 0 && ( 
          <div className="image-preview">
            
            <table style={{ width: '100%', overflow: 'hidden' }}>
  <tbody style={{ 
    flexDirection: 'row', 
    display: 'flex', 
    maxWidth: '100%',
    justifyContent:'center', 
    flexWrap: 'wrap', /* Enable wrapping to the next row */
     /* Optional border for the tbody */
    overflow: 'hidden' /* Prevent overflow from escaping the container */
  }}>
    {selectedFiles.map((file, index) => (
      <tr key={index} style={{ border: '1px solid black',textAlign:'center' }}>
        <td style={{textAlign:'center'}}>
          <img 
            height={imageHeight} 
            width={imageWidth} 
            src={URL.createObjectURL(file)} 
            alt={file.name} 
            className="preview-image" 
            style={{ border: '1px solid lightgray' }} /* Optional border for each image */
          />
        </td>
      </tr>
    ))}
  </tbody>
</table>

             
            
          </div>
        )}
        <br />
        <hr style={{border:'1px solid #000'}}></hr>
        <br />
         <Row className="mb-4">
            
            <Form.Group as={Col} md="3"></Form.Group>
            <Form.Group as={Col} md="3"></Form.Group>
            <Form.Group as={Col} md="3">
            <Button
                    style={{ background: "#7f083c", border: "2px solid #c90057", width: "100%",color:'#fff' }}
                   
                    onClick={(e) => {chatPhotosUploadClick(e)}}
                  >
                    Send
                  </Button>
            </Form.Group>
            <Form.Group as={Col} md="3">
                  
                </Form.Group>
              </Row>
        </Modal.Body>
      </Modal>
      <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />  
       {/* </Container> */}
      <Footermain/>
    </div>
  );
};
const LoadingImageRenderer = ({ photos, previewFile }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);

  useEffect(() => {
    if (photos && photos.length > 0) {
      setLoadingImages(true);
      renderImages(photos)
        .then(urls => {
          setImageUrls(urls);
          setLoadingImages(false);
        })
        .catch(error => {
          console.error('Error loading images:', error);
          setLoadingImages(false); // Handle loading state on error
        });
    } else {
      setLoadingImages(false);
    }
  }, [photos]);

  return (
    <>
      {loadingImages ? (
        <p>Loading images...</p>
      ) : imageUrls.length > 0 ? (
        imageUrls.map((imageUrl, fileIndex) => (
          <div key={fileIndex} style={{ marginBottom: '10px',textAlign:'center' }}>
            <img
              src={imageUrl}
              alt={`Image ${fileIndex}`}
              style={{ width: '100%', height: '100%',maxWidth:'70%',maxHeight:'50%',cursor:'pointer' }}
                      title='Click Preview!'
              onClick={() => previewFile(imageUrl)}
            />
            
          </div>
        ))
      ) : (
        ""
      )}
    </>
  );
};

const openImageInNewTab = (base64Image) => {
  const byteCharacters = atob(base64Image.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpeg' });
  const blobUrl = URL.createObjectURL(blob);

  window.open(blobUrl);
};
export default LiveChat;
