
import { BrowserRouter, Routes, Route,Outlet } from "react-router-dom";
import Homemain from "../pages/homeMain";
import Login from "../admin/login";
import Register from "../admin/register";
import Registroption from "../admin/option-for-register";
import Secretcode from "../admin/secret-code";
import Dashboard from "../pages/Dashboard/dashboard";
import VideoConference from "../pages/videoconference/VideoConference";
import LibraryMain from "../pages/library/library-main";
import HomeVideo from "../pages/videoconference/Home";
import RoomVideo from "../pages/videoconference/Room";
import AuthShield from "../shields/AuthShield";
import MyBook from "../pages/pdfFlip/pdf-flip";
import FlipBook from "../pages/pdfFlip/html-flip";
import PDFViewer from "../pages/pdfFlip/pdf-viewer";
import PdfToImageConverter from "../pages/pdfFlip/pdf-to-image-converter";
import OfficeMain from "../pages/office/office-main";
import Layout from "../components/llayout";
import RegistrationVerification from "../pages/office/student-registration-verify-and-submit";
import Admission from "../pages/admission/admission-form";
import LiveChat from "../pages/livechat/live-chat";
import Tracking from "../pages/tracking/tracking-public";

function RouterAll() {
 
  return (
   
  <div >
     
 <BrowserRouter>
 <Layout>
    
      <Routes>
      <Route path="/" element={<Homemain/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/live-chat" element={<LiveChat/>}/>
        <Route path="/admission-form" element={<Admission/>}/>
        <Route path="/register-options" element={<Registroption/>}/>
        <Route path="/secret-code-register" element={<Secretcode/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/flip" element={<MyBook/>}/>
        <Route path="/pdfconvert" element={<PdfToImageConverter/>}/>
        <Route path="/student-registration-email-link" element={<RegistrationVerification/>}/>
        <Route path="/public-registration-email-link" element={<Tracking/>}/>
        
        <Route
              path="/user"
              element={
                <AuthShield>
                  <Outlet />
                </AuthShield>
              }>
       
        <Route path="dashboard-main" element={<Dashboard/>}/>
        <Route path="video-confrence" element={<VideoConference/>}/>
        <Route path="library-main" element={<LibraryMain/>}/>
        <Route path="home" element={<HomeVideo />} />
        <Route path="room/:roomID" element={<RoomVideo />} />
        <Route path="office-main" element={<OfficeMain />} />
        
        </Route>
          {/* <Route index element={<Home />} /> */}
          {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
       
      </Routes>
   </Layout>
   </BrowserRouter>
   
  </div>
  );
}

export default RouterAll;
