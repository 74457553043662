import React, { useRef, useEffect } from 'react';
import SimpleWebRTC from 'simplewebrtc';

const VideoConference = () => {
  const videoRef = useRef();

  useEffect(() => {
    const webrtc = new SimpleWebRTC({
      localVideoEl: videoRef.current,
      autoRequestMedia: true,
    });

    webrtc.on('readyToCall', () => {
      webrtc.joinRoom('your-room-name');
    });

    return () => {
      webrtc.leaveRoom();
    };
  }, []);

  return (
    <div>
      <video ref={videoRef} autoPlay playsInline muted mediaGroup controls controlsList crossOrigin />
    </div>
  );
};
export default VideoConference;