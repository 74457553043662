import React, { useState,useEffect,useRef } from 'react';
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Alert,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { Form, FormControl, Table, Pagination, Button, Dropdown, Modal } from 'react-bootstrap';
import ImageIcon from '@mui/icons-material/Image';
import { CSVLink } from 'react-csv';
import AddIcon from '@mui/icons-material/Add';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  PictureAsPdf,
  InsertDriveFile,
  Description,
  TableChart,
  TextSnippet,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import { addCourse, addEnglishBook, deleteCourse, deleteEnglishBook, deleteEnglishBookPDF, getCourseAndDepartments, getEnglishBook, getEnglishBookFiles, postBulkExcelFile, postBulkExcelFileCourse, updateCourse, updateEnglishBook } from '../../services';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import './../../styles/modelFulscreen.css';
import PdfFlip from '../../components/pdf-flip';
// import * as xlsx from 'xlsx';
const data = [
];

function ClassroomView(){
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(false);
  const fileSelectedHandler = (event,status) => {
  
   if(status === "bulk")
    {
      const file = event.target.files[0];
      if (file) {
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        if (fileExtension === 'xlsx') {
          setSelectedFile(file);
        } else {
          event.target.value = '';
          toast.warn('Please select an .xlsx file');
        }
      }
    }
    if (status === "addbook") {
      
      const file = event.target.files[0];
   
      if (file) {
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
        if (['pdf'].includes(fileExtension)) {
          
          setSelectedFile(file);
        } else {
          event.target.value = '';
          toast.warn('Please select a .pdf file');
        }
      }
    }
    
    
  };

  //field value set
  const [txtCourseFullName, setTxtCourseFullName] = useState("");
  const [txtCourseShortName, setTxtCourseShortName] = useState("");
  const [txtYears, setTxtYears] = useState("");
  const [txtStatus, setTxtStatus] = useState("");
  const [txtHeading, setTxtHeading] = useState("");
  const [txtUploadFileName, setTxtUploadFileName] = useState("");
  const [fileUrl, setFileUrl] = useState('');
  const [modelStatus, setModelStatus] = useState("");
  const [modelBulk, setmodelBulk] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newOptions, setNewOptions] = useState(false);
  const [validated, setValidated] = useState(false);
  const [dataGet, setDataGet] = useState([]);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null, first: null });
  const [showAddUpdateModal, setshowAddUpdateModal] = useState(false);
  const [showBulkUpload, setshowBulkUpload] = useState(false);
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [bookTitle, setBookTitle] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [files, setFiles] = useState([]);
  const [filesUpdate, setFilesUpdate] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showFileName, setShowFileName] = useState(null);
  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selected) => selected !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allItems = currentItems.map((item) => item.courseId);
      setSelectedItems(allItems);
    }
    setSelectAll(!selectAll);
  };
  const deleteRecordClick=(async (event,id)=> {
   
    event.preventDefault()
    if(selectedItems.length<1 )
        {
            toast.warn("You can't delete this row . Because you didn't select any record.  You can select atleast one record!")   
        }
        if(selectedItems.length>0 )
            {
                setShowDelete(true)
                setBookId(id)
            }
    
   
  })
  const deleteRecordSubmit=(async (event,ids)=> {
   
    event.preventDefault()
    try {
        
                const responses = await Promise.all(
                    ids.map(async (id) => {
                  const response = await deleteCourse(id).then((response) => {
                    if(response.data)
                      {
                        toast.success("Record is deleted successfully !")
                        window.location.reload()
                      }
                    
                  }).catch((error)=>{console.log(error)})
                }))
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  })
  const pdfViewClick=(async (event,id,book)=> {
    event.preventDefault()
    try {
      const response = await getEnglishBookFiles(id).then((response) => {
       
        // var url = URL.createObjectURL(response.data[0]);
        // console.log("response",url)
       
        setFiles(response.data)
       
      }).catch((error)=>{console.log(error)})
      setBookTitle(book);
      setShow(true);
    //   console.log("response",response)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
   
  })
  useEffect((e)=>{
    getCourseAndDepartments().then((response) => {
      if(response.data != undefined)
      {
        setDataGet(response.data)
        const groupedCourses = response.data.reduce((acc, course) => {
          const { heading, ...rest } = course;
          if (!acc[heading]) {
              acc[heading] = [];
          }
          acc[heading].push(rest);
          return acc;
      }, {});
      
      // Convert object to array
      const groupedCoursesArray = Object.keys(groupedCourses).map((heading) => ({
          heading,
          courses: groupedCourses[heading]
      }));
      
      console.log("groupedCoursesArray",groupedCoursesArray);

      }
      let key ="courseId"
      let direction = 'ascending';
      let first = 'yes';
      setSortConfig({ key, direction,first });
    }).catch((error)=>{console.log(error)})
  },[])
  const modelHideClick =((e)=>{
    setNewOptions(false)
    setshowAddUpdateModal(false)
    setshowBulkUpload(false)
    setmodelBulk(false)
    setShowDelete(false)
  })
  const handleSubmit = async (event) => {
    event.preventDefault()
   
    const formData = new FormData();
    formData.append("courseFullName", txtCourseFullName);
    formData.append("courseShortName", txtCourseShortName);
    formData.append("statusOfStudy", txtStatus);
    formData.append("heading", txtHeading);
    formData.append("years", txtYears);
    
    if(modelStatus === "Edit New Record")
      {
       
        updateCourse(formData,bookId).then((response) => {
          if(response.data)
            {
              toast.success("Record is Updated successfully !")
              setNewOptions(false)
              setshowAddUpdateModal(false)
              setshowBulkUpload(false)
              setmodelBulk(false)
              window.location.reload()
            }
          
        }).catch((error)=>{
          toast.warn(" This record is already exists !")
        }
        )
      }
      if(modelStatus === "Add New Record"){
        addCourse(formData).then((response) => {
          if(response.data)
            {
              toast.success("New record saved successfully !")
              setNewOptions(false)
              setshowAddUpdateModal(false)
              setshowBulkUpload(false)
              setmodelBulk(false)
              window.location.reload()
            }
          
        }).catch((error)=>{
          toast.warn(" This record is already exists !")
        }
        )
      }
    
    
  }
  const editRecords=(id,fName,SName,years,status,heading)=>{
    setTxtCourseFullName(fName)
    setTxtCourseShortName(SName)
    setTxtYears(years)
    setTxtStatus(status)
    setTxtHeading(heading)
    setBookId(id)
  }
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  
  const sortedData = dataGet
    .filter((item) => item?.courseId?.toLowerCase().includes(search?search.toLowerCase():"") 
    || item?.courseFullName?.toLowerCase().includes(search?search.toLowerCase():"")
    || item?.courseShortName?.toLowerCase().includes(search?search.toLowerCase():"")
    || item?.years?.toLowerCase().includes(search?search.toLowerCase():"")
    || item?.heading?.toLowerCase().includes(search?search.toLowerCase():"")
  )
    .sort((a, b) => {
      if (sortConfig.direction === 'ascending') {
        return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
      }
      if (sortConfig.direction === 'descending') {
        return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
      }
      return null;
    });
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const tableRef = useRef(null);
  const exportToExcel=()=> {
    const htmlTable = tableRef.current.outerHTML;
    const workbook = XLSX.utils.table_to_book(document.querySelector('table'));
    XLSX.writeFile(workbook, 'table-to-doc.xlsx');
  }
  const handleExport = (format) => {
    switch (format) {
      case 'pdf':
        return window.print();
      case 'excel':
        return exportToExcel()
      case 'doc':
        return saveAs(new Blob([tableRef.current.outerHTML], { type: 'application/msword' }), 'table-to-doc.doc');
      case 'csv':
        return saveAs(new Blob([tableToCSV(tableRef.current)], { type: 'text/csv;charset=utf-8' }), 'table-to-csv.csv');
      case 'txt':
        return saveAs(new Blob([tableToText(tableRef.current)], { type: 'text/plain;charset=utf-8' }), 'table-to-txt.txt');
      default:
        return null;
    }
  };
  const handleFirst = () => {
    setCurrentPage(1);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleLast = () => {
    setCurrentPage(Math.ceil(sortedData.length / perPage));
  };

  const handlePerPageChange = (size) => {
    setPerPage(size);
    setCurrentPage(1);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    let first = 'no';
      setSortConfig({ key, direction,first });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleAdd = () => {
    // Add your logic for adding data here
    
    console.log('Add');
  };

  const handleEdit = (item) => {
    // Add your logic for editing data here
    console.log('Edit', item);
  };

  const handleView = (item) => {
    // Add your logic for viewing data here
    console.log('View', item);
    // setModalData(item);
    // setShowModal(true);
  };

  const handleDelete = (id) => {
    // Add your logic for deleting data here
    console.log('Delete', id);
  };
  const tableToCSV = (table) => {
    let csv = [];
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (let j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(','));
    }
    return csv.join('\n');
  };

  const tableToText = (table) => {
    let text = [];
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (let j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      text.push(row.join('\t'));
    }
    return text.join('\n');
  };
  
  const exportPDF = () => {
   
  };
  const headers = [
    { label: 'Sno', key: 'bno' },
    { label: 'Author', key: 'author' },
    { label: 'Book', key: 'book' },
    { label: 'Place', key: 'place' },
    { label: 'Press', key: 'press' },
    { label: 'year', key: 'year' }

  ];
  const totalPages = Math.ceil(sortedData.length / perPage);

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => paginate(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };
  const renderPaginationItems = () => {
    const pageNumbers = getPageNumbers();
    const items = [];

    for (let i = 0; i < pageNumbers.length; i += 10) {
      items.push(
        <div key={i} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {pageNumbers.slice(i, i + 10)}
        </div>
      );
    }
    return items;
  };
  const fileUploadHandler = (async (el) => {
    el.preventDefault();
    const formData = new FormData();
    formData.append("courseExcel", selectedFile);
    const files = await postBulkExcelFileCourse(formData).then(async(response) => {
     
      if(response.data.alreadyExist == true)
        {
    try {
      const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(response.data.records);
        XLSX.utils.book_append_sheet(workbook, sheet, 'Existing Records');

        const fileBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'existing_records.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file: ', error);
    }
    toast.warn("Already Exists")
    setNewOptions(false)
    setshowAddUpdateModal(false)
    setshowBulkUpload(false)
    setmodelBulk(false)
    setShowDelete(false)
  }
 
  else if(response.data.missingData == true){
    toast.success("Your file column data is missing. Please verify !")
    try {
      const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(response.data.records);
        XLSX.utils.book_append_sheet(workbook, sheet, 'Existing Records');

        const fileBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'existing_records.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file: ', error);
    }
    setNewOptions(false)
    setshowAddUpdateModal(false)
    setshowBulkUpload(false)
    setmodelBulk(false)
    setShowDelete(false)
  } 
  else if(response.data.alreadyExist == false){
    toast.success("Excel bulk data saved successfully !")
  
    window.location.reload()
  }   
    }).catch((error)=>{
      toast.error("Your file headers (or) data is invalid. Please verify first!")
    })
    getEnglishBook().then((response) => {
     
    }).catch((error)=>{
      toast.error(error)
    })
  
  });
  const addOptions=()=>{
  setTxtCourseFullName("");
    setTxtCourseShortName("");
    setTxtYears("");
    setTxtStatus("");
    setTxtHeading("");
    setNewOptions(true)

  }
  const deletePdfSubmit=(async(e,id)=>{
    e.preventDefault()
    try {
     
      const response = await deleteEnglishBookPDF(id).then((response) => {
        if(response.data)
          {
            toast.success("PDF file is deleted successfully !")
            window.location.reload()
          }
        
      }).catch((error)=>{console.log(error)})
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
    
  })
  return (
    <div>
         <div className="row text-center">
         <h4 style={{fontWeight:"bold"}}>COURSES AND DEPARTMENTS</h4></div>
       <div className="row" >
                        <div className="col-lg-3 mb-4">
                        <Form inline className="mb-2">
        <FormControl
          type="text"
          placeholder="Search"
          className="mr-sm-2 custom-search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form>
                        </div>
                        <div className="col-lg-6 mb-8 text-center" >
                        {currentItems.length > 0 ? (<div style={{display:"flex",justifyContent:"space-between"}}>
                          <div onClick={exportPDF} style={{ color: 'red',cursor:"pointer"}} title='Export PDF'>
                            <PictureAsPdf  /> PDF</div>
                          <div title='Export Excel' onClick={() => handleExport('excel')} style={{ color: 'green',cursor:"pointer" }}>
                            <Description  /> Excel</div>
                          <div title='Export CSV' onClick={() => handleExport('csv')} style={{ color: 'blue',cursor:"pointer"}}>
                            <InsertDriveFile  />  CSV</div>
                          <div title='Export TXT' onClick={() => handleExport('txt')} style={{ color: 'orange',cursor:"pointer" }}>
                            <TextSnippet /> TXT</div>
                          <div title='Export Word' onClick={() => handleExport('doc')} style={{ color: 'purple',cursor:"pointer" }}>
                            <TableChart /> Word</div>
    </div>):""}
                        </div>
                       
                        {/* {currentItems.length > 0 ? ( <div className="col-lg-3 mb-4 no-print">
                        <Button variant="warning" className="mr-2" onClick={() => window.print()}>
            Print
          </Button>
          <CSVLink data={data} headers={headers} filename={'data.csv'}>
            <Button variant="info">Export CSV</Button>
          </CSVLink>
                        </div>):""} */}
                       <div className="col-lg-3 mb-4 no-print" >
                        <div style={{float:"right"}}>
                        <Button variant="success"  onClick={() => addOptions()}>
            Add New Options
          </Button></div>
                        </div>
                        </div>
               
              
                        {currentItems.length > 0 ? ( <div>
                          <Table striped ref={tableRef} hover className="custom-table" id="tablefg">
        <thead>
          <tr>
            <th>
              <input type="checkbox" style={{cursor:"pointer"}} title='seleect all' checked={selectAll} onChange={handleSelectAll} />
            </th>
            <th onClick={() => requestSort('courseId')} className={getClassNamesFor('courseId')}>
{sortConfig.first === "yes"? <div>S.no </div>:<div>S.no {sortConfig.key === 'courseId' && (sortConfig.direction === 'ascending' ? 
              <span>&#9650;</span> : <span>&#9660;</span>)}</div>}
             

            </th>
            <th onClick={() => requestSort('courseFullName')} className={getClassNamesFor('courseFullName')}>
              Course Name {sortConfig.key === 'courseFullName' && (sortConfig.direction === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>)}
            </th>
            <th onClick={() => requestSort('courseShortName')} className={getClassNamesFor('courseShortName')}>
            Departments {sortConfig.key === 'courseShortName' && (sortConfig.direction === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>)}
            </th>
            <th onClick={() => requestSort('heading')} className={getClassNamesFor('heading')}>
            Main Title {sortConfig.key === 'heading' && (sortConfig.direction === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>)}
            </th>
            <th onClick={() => requestSort('years')} className={getClassNamesFor('years')}>
            Year of Course {sortConfig.key === 'years' && (sortConfig.direction === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>)}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>
                <input
                style={{cursor:"pointer"}} title='seleect'
                  type="checkbox"
                  checked={selectedItems.includes(item.courseId)}
                  onChange={() => handleCheckboxChange(item.courseId)}
                />
              </td>
              <td>{item.courseId}</td>
              <td>{item.courseFullName}</td>
              <td>{item.courseShortName}</td>
              <td>{item.heading}</td>
              <td>{item.years}</td>
            
              <td>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                 
                  <div title="Edit">
                    <EditIcon
                      onClick={() => {
                        setNewOptions(false);
                        setshowAddUpdateModal(true);
                        setModelStatus("Edit New Record");
                        editRecords(item.courseId, item.courseFullName, item.courseShortName,
                             item.years, item.statusOfStudy,item.heading);
                      }}
                      style={{ fontWeight: "bold", color: "blue", cursor: "pointer" }}
                      title="Edit"
                    />
                  </div>
                  <div title="Delete">
                    <DeleteIcon
                      onClick={(e) => {
                        deleteRecordClick(e, item.courseId);
                      }}
                      style={{ fontWeight: "bold", color: "red", cursor: "pointer" }}
                      title="Delete"
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center no-print">
      <div>
          <Dropdown className='no-print'>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: '#7f083c',border:"1px solid #7f083c" }}>
              Per Page: {perPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handlePerPageChange(25)}>25</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(50)}>50</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(100)}>100</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(250)}>250</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(500)}>500</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(1000)}>1000</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(5000)}>5000</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(10000)}>10000</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Pagination className='no-prin'>
      <Pagination.First onClick={handleFirst} disabled={currentPage === 1} />
      <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1} />
      {renderPaginationItems()}
      <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages} />
      <Pagination.Last onClick={handleLast} disabled={currentPage === totalPages} />
    </Pagination>
       
      </div>
        </div>): <div className='text-center'><p style={{border:"1px dashed #000",color:"red"}}>No records found</p></div>}
        
        <Modal
    size={"lg"}
    show={showAddUpdateModal}
    onHide={() => modelHideClick()}
    aria-labelledby="example-modal-sizes-title-sm"
    centered
  >
    <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="m3d" style={{color:"white"}}>
      <span >{modelStatus}</span>
     </Modal.Title>
     </div>
   </Modal.Header>
   <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
   
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-2">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>Course Name</Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder="Course Name"
            value={txtCourseFullName}
            onChange={(e)=>{setTxtCourseFullName(e.target.value)}}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Main Title</Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder="Main Title"
            value={txtHeading}
            onChange={(e)=>{setTxtHeading(e.target.value)}}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} md="6" controlId="validationCustom03">
          <Form.Label>Departments</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Departments"
            value={txtCourseShortName}
            onChange={(e)=>{setTxtCourseShortName(e.target.value)}}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom04">
          <Form.Label>Years</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Years"
            value={txtYears}
            onChange={(e)=>{setTxtYears(e.target.value)}}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
       
      </Row>
     
      <Row className="mb-2">
       
        <Form.Group as={Col} md="6" controlId="validationCustom05">
          <Form.Label>Description</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Description"
            value={txtStatus}
            onChange={(e)=>{setTxtStatus(e.target.value)}}
            
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>

        </Row>
      
      <Row>
      <Form.Group className="mb-1">
      <br/>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-success"  type="submit">
          Submit
        </Button>
       
      </Form.Group>
      </Row>
      {/* <Button type="submit">Submit form</Button> */}
    </Form>
    
    </Modal.Body>
    </Modal>
    <Modal
         size={"md"}
         show={newOptions}
         onHide={() => modelHideClick()}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m2d" style={{color:"white"}}>
           <span >Choose Options</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>

<table style={{width:"100%",justifyContent:"space-between"}}>
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       onClick={() => {setNewOptions(false);setshowAddUpdateModal(true);setModelStatus("Add New Record")}}
       buttonvariant="outline-success"  
       type="submit"
       >
        Add New Record

      </Button></td>
    <td><Button  style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       onClick={() => {setNewOptions(false);setshowAddUpdateModal(false);setModelStatus("Add Excel Bulk Upload");setmodelBulk(true)}}
       buttonvariant="outline-success"  
       type="submit"
       >
        Add Excel Bulk Upload 
        
      </Button></td>
  </tr>
 

</table>
        
       


        </Modal.Body>
      </Modal>
      <Modal
         size={"md"}
         show={modelBulk}
         onHide={() => modelHideClick()}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m1d" style={{color:"white"}}>
           <span >{modelStatus}</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>


        <Form noValidate validated={validated} onSubmit={fileUploadHandler}>
      
     
      <Row>
       

        <Form.Group as={Col}  controlId="formFiles" className="mb-3">
        <Form.Label>Bulk File Upload</Form.Label>
        <Form.Control type="file" accept=".xlsx" onChange={(e)=>fileSelectedHandler(e,"bulk")} />
        <Form.Text className="text-muted">
          Please only select Excel files (.xlsx format).
        </Form.Text>
      </Form.Group>
        </Row>
      
      <Row>
      <Form.Group className="mb-1">
      <br/>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-success"  type="submit">
          Submit
        </Button>
       
      </Form.Group>
      </Row>
      {/* <Button type="submit">Submit form</Button> */}
    </Form>
       


        </Modal.Body>
      </Modal>
      {/* pdf model */}
      
      <Modal show={show}  size="lg" centered 
      dialogClassName="fullscreen-modal"
      onHide={() => setShow(false)}>
      <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="m3d" style={{color:"white"}}>
      <span >{bookTitle}</span>
     </Modal.Title>
     </div>
   </Modal.Header>
        <Modal.Body>
        {/* {files.map((file, index) => (
        <div key={index}> */}
        {files[0]?<PdfFlip pdf={files[0].url}/>:""}
          </Modal.Body>
      </Modal>

      <Modal show={showDelete}  size="md" centered 
      onHide={() => setShowDelete(false)}>
      <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="m3d" style={{color:"white"}}>
      <span >Delete Record</span>
     </Modal.Title>
     </div>
   </Modal.Header>
        <Modal.Body>
       <div><span>{selectedItems.length>1?"Are you sure? You have selected multiple records. Do you want delete multiple records?"
       :"Are you sure? Do you want delete this record?"}</span></div>
       <br/>
       <div style={{display:"flex",justifyContent:"space-between"}}>
       <div>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-danger"  type="submit" onClick={(e)=>deleteRecordSubmit(e,selectedItems)}>
          Yes
        </Button></div>
        <div>
        <Button style={{border:"2px solid #c90057",width:"100%"}} variant='light' buttonvariant="outline-light" onClick={() => modelHideClick()}>
          No
        </Button></div>
       </div>
          </Modal.Body>
      </Modal>
      <Modal show={deleteFile}  size="md" centered 
      onHide={() => setDeleteFile(false)}>
      <Modal.Header style={{background:"#c90057"}} onHide={() => setDeleteFile(false)} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="m3d" style={{color:"white"}}>
      <span >Delete File</span>
     </Modal.Title>
     </div>
   </Modal.Header>
        <Modal.Body>
       <div><span>Are you sure? Do you want delete this file?</span></div>
       <br/>
       <div style={{display:"flex",justifyContent:"space-between"}}>
       <div>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-danger"  type="submit" onClick={(e)=>deletePdfSubmit(e,bookId)}>
          Yes
        </Button></div>
        <div>
        <Button style={{border:"2px solid #c90057",width:"100%"}} variant='light' buttonvariant="outline-light" onClick={() => setDeleteFile(false)}>
          No
        </Button></div>
       </div>
          </Modal.Body>
      </Modal>
      
    <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
    </div>
  );
};

export default ClassroomView;