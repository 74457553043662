// Layout.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from './loader-custom';


const Layout = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => setLoading(false), 1000); // Simulated loading delay
    return () => clearTimeout(timeout);
  }, [location]);

  return (
    <div>
      {loading && <Loader />}
      {children}
    </div>
  );
};

export default Layout;
