import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
import 'react-awesome-slider/dist/styles.css';
import './../styles/marque.css'
// import './../styles/ImageSlider.css'
// import Marquee from "react-fast-marquee";
import { useState, useEffect } from "react";
import Img1 from './../assets/images/lib2.jpg'
import Img2 from './../assets/images/lib3.jpg'
import Img3 from './../assets/images/lib4.jpg'
import Img4 from './../assets/images/lib5.jpg'
import Img5 from './../assets/images/lib6.jpg'
import Img6 from './../assets/images/lib7.jpg'
import Img7 from './../assets/images/lib8.jpg'
import Img8 from './../assets/images/lib9.jpg'
import Img9 from './../assets/images/lib10.jpg'
import Img10 from './../assets/images/lib11.jpg'
import Img11 from './../assets/images/lib12.jpg'
import Img12 from './../assets/images/lib13.jpg'
import Img13 from './../assets/images/lib14.jpg'
import Img14 from './../assets/images/lib15.jpg'
import Img15 from './../assets/images/lib16.jpg'
import Img16 from './../assets/images/lib17.jpg'
import Img17 from './../assets/images/lib18.jpg'
import Img18 from './../assets/images/lib19.jpg'
import Img19 from './../assets/images/lib20.jpg'
import Img20 from './../assets/images/Logo - with white BG.png';
import {
    Container,
    Row ,
    Col

   } from "react-bootstrap";

 
   const imagesWithCaptions = [
    { src: Img1, caption: "Caption for Image 1" },
    { src: Img2, caption: "Caption for Image 2" },
    { src: Img3, caption: "Caption for Image 3" },
    { src: Img4, caption: "Caption for Image 4" },
    { src: Img5, caption: "Caption for Image 5" },
    { src: Img6, caption: "Caption for Image 6" },
    { src: Img7, caption: "Caption for Image 7" },
    { src: Img8, caption: "Caption for Image 8" },
    { src: Img9, caption: "Caption for Image 9" },
    { src: Img10, caption: "Caption for Image 10" },
    { src: Img11, caption: "Caption for Image 11" },
    { src: Img12, caption: "Caption for Image 12" },
    { src: Img13, caption: "Caption for Image 13" },
    { src: Img14, caption: "Caption for Image 14" },
    { src: Img15, caption: "Caption for Image 15" },
    { src: Img16, caption: "Caption for Image 16" },
    { src: Img17, caption: "Caption for Image 17" },
    { src: Img18, caption: "Caption for Image 18" },
    { src: Img19, caption: "Caption for Image 19" },
  ];
function Slidermain() {
  
   const AutoplaySlider = withAutoplay(AwesomeSlider);
 
    return (
    //   <div style={{width:"100%",height:"20%",paddingBottom:"30px"}}>
    <Container fluid >
    <Row >
    <Col style={{paddingBottom:"58px"}}>
    {/* <AutoplaySlider style={{height:"368px"}} animation="fallAnimation"
    play={true}
   
    interval={3000}
  >
    <div style={{borderRadius:"10%"}} data-src={Img1} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}}  data-src={Img2} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img3} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img4} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img5} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}}  data-src={Img6} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img7} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img8} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img9} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}}  data-src={Img10} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img11} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img12} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img13} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}}  data-src={Img14} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img15} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img16} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img17} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}}  data-src={Img18} data-caption="Caption for Image 17"/>
    <div style={{borderRadius:"10%"}} data-src={Img19} data-caption="Caption for Image 17"/>
  </AutoplaySlider> */}
  <AutoplaySlider 
    style={{ height: "350px" }} 
    animation="fallAnimation"
    play={true}
    interval={5000}
  >
    {imagesWithCaptions.map((item, index) => (
      <div key={index} style={{ position: 'relative', borderRadius: '10%' }} data-src={item.src}>
       <div class="marquee-container" style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
  <div class="marquee">
    <span>Your scrolling text goes here</span>
  </div>
  <div class="caption">Your caption text here</div>
</div>
      </div>
    ))}
  </AutoplaySlider>
   </Col>
   </Row>
   </Container>
    //  </div>
    );
  }
  
  export default Slidermain;