import {Container,
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    Button,
    Alert,
    Modal,
    Row,
    Col,
    InputGroup
} from 'react-bootstrap';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Homemain from '../pages/homeMain';
import { Eye, EyeSlashFill, Key } from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
function Secretcode() {
  const [validated, setValidated] = useState(false);
  const [lgShow, setLgShow] = useState(true);
  const [textSecret, setTextSecret] = useState("");
  const navigate = useNavigate();
  const modelHideClick =((e)=>{
    navigate('/')
  })
  const [showPass, setShowPass] = useState(false);
  const [showPassType, setShowPassType] = useState("password");
  const handleSubmit = (event,sec) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
  
    setValidated(true);
    if(sec == "JJJ")
    {
      navigate('/user/dashboard-main')
    }
    else{
      toast.error('Secret code is incorrect !');
    }
  };
  const clickHandler = () => {
    setShowPass((prev) => !prev);
    if(showPassType == "password")
    setShowPassType("text")
    if(showPassType == "text")
    setShowPassType("password")
  };
  const txtOnchange = ((event) => {
    setTextSecret(event.target.value);
  });
  return (
  
     <div>
     <Homemain/>
      <Modal
      size={"sm"}
      show={lgShow}
      onHide={() => modelHideClick()}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
      <div style={{width:"100%",textAlign:"center"}}>
        <Modal.Title id="example-modal-sizes-title-sm" style={{color:"white"}}>
        <span >Verifying Secret Code </span>
       </Modal.Title>
       </div>
     </Modal.Header>
     <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
   
     <Form noValidate validated={validated} onSubmit={(e)=>{handleSubmit(e,textSecret)}}>
      <Row className="mb-1">
      <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label style={{fontWeight:"bold"}}>Secret Code</Form.Label>
          <InputGroup className="mb-3">
        <InputGroup.Text id="password-input">
          <Key />
        </InputGroup.Text>
        <Form.Control
          type={showPassType}
          name="password"
          placeholder="Secret code"
          aria-label="password"
          aria-describedby="password-input"
          required
          value={textSecret}
          onChange={(e)=>{txtOnchange(e)}}
        />
        <InputGroup.Text onClick={clickHandler}>
          {/* You can use both. I ran into some width issues with FontAwesome but they can be fixed by playing around */}
          {/* <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} /> */}
          {showPass ? <EyeSlashFill style={{color:"#c90057",fontWeight:"bold"}} title='Click Hide'/> : 
          <Eye title='Click Show' style={{color:"#c90057",fontWeight:"bold"}} />}
        </InputGroup.Text>
      </InputGroup>
        </Form.Group>
      
      </Row>
      <Row className="mb-1">
        <Form.Group as={Col} md="12">
      <br/>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-success"  type="submit">
          Verify
        </Button>
        <div style={{padding:"5px"}}></div>
        {/* <Button variant='success' buttonvariant="outline-success" type="submit">
          Register
        </Button> */}
        <Button onClick={() => modelHideClick()} style={{border:"2px solid #c90057",width:"100%"}} variant='light' buttonvariant="outline-light" >
          Cancel
        </Button>
        </Form.Group>
      
      </Row>
    </Form>
   
   </Modal.Body>
   </Modal>
   <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
   </div>
  );
}

export default Secretcode;