import React, { useState } from 'react';
import { Form, FormControl, Table, Pagination, Button, Dropdown, Modal } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import AddIcon from '@mui/icons-material/Add';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  PictureAsPdf,
  InsertDriveFile,
  Description,
  TableChart,
  TextSnippet,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
const data = [
    { id: 1, name: 'John Doe', age: 25 },
  { id: 2, name: 'Jane Smith', age: 30 },
  { id: 3, name: 'Tom Jones', age: 28 },
  { id: 4, name: 'Alice Johnson', age: 35 },
  { id: 5, name: 'Bob Brown', age: 40 },
  { id: 6, name: 'Emma White', age: 22 },
  { id: 7, name: 'Michael Davis', age: 32 },
  { id: 8, name: 'Olivia Martinez', age: 27 },
  { id: 9, name: 'William Wilson', age: 38 },
  { id: 10, name: 'Sophia Anderson', age: 29 }
];

function CustomTable(){
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(3);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const sortedData = data
    .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      if (sortConfig.direction === 'ascending') {
        return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
      }
      if (sortConfig.direction === 'descending') {
        return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
      }
      return null;
    });
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleFirst = () => {
    setCurrentPage(1);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleLast = () => {
    setCurrentPage(Math.ceil(sortedData.length / perPage));
  };

  const handlePerPageChange = (size) => {
    setPerPage(size);
    setCurrentPage(1);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleAdd = () => {
    // Add your logic for adding data here
    console.log('Add');
  };

  const handleEdit = (item) => {
    // Add your logic for editing data here
    console.log('Edit', item);
  };

  const handleView = (item) => {
    // Add your logic for viewing data here
    console.log('View', item);
    setModalData(item);
    setShowModal(true);
  };

  const handleDelete = (id) => {
    // Add your logic for deleting data here
    console.log('Delete', id);
  };

  const headers = [
    { label: '#', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Age', key: 'age' }
  ];

  return (
    <div>
       <div className="row" >
                        <div className="col-lg-3 mb-4">
                        <Form inline className="mb-2">
        <FormControl
          type="text"
          placeholder="Search"
          className="mr-sm-2 custom-search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form>
                        </div>
                        <div className="col-lg-6 mb-8 text-center" >
                        {currentItems.length > 0 ? (<div style={{display:"flex",justifyContent:"space-between"}}>
                          <div><PictureAsPdf style={{ color: 'red'}} /> PDF</div>
                          <div><InsertDriveFile style={{ color: '#RRGGBB'}} /> Excel</div>
                          <div><Description style={{ color: 'green' }} /> CSV</div>
                          <div><TextSnippet style={{ color: 'orange' }}/> TXT</div>
                          <div><TableChart style={{ color: 'purple' }}/> Word</div>
    </div>):""}
                        </div>
                       
                        {/* {currentItems.length > 0 ? ( <div className="col-lg-3 mb-4 no-print">
                        <Button variant="warning" className="mr-2" onClick={() => window.print()}>
            Print
          </Button>
          <CSVLink data={data} headers={headers} filename={'data.csv'}>
            <Button variant="info">Export CSV</Button>
          </CSVLink>
                        </div>):""} */}
                       <div className="col-lg-3 mb-4 no-print" >
                        <div style={{float:"right"}}>
                        <Button variant="primary" but className="mr-2" onClick={() => window.print()}>
            Add New Record
          </Button></div>
                        </div>
                        </div>
               
              
                        {currentItems.length > 0 ? ( <div>
      <Table striped  hover className="custom-table">
        <thead>
          <tr>
            <th>S.no</th>
            <th onClick={() => requestSort('name')} className={getClassNamesFor('name')}>
            Author Name
            </th>
            <th onClick={() => requestSort('age')} className={getClassNamesFor('age')}>
            Book's Title
            </th>
            <th>Publication Place</th>
            <th>Publishers</th>
            <th>Publishing Year</th>
            <th >Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.age}</td>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.age}</td>
              <td>
                {/* <Button variant="info" size="sm" onClick={() => handleView(item)}> */}
                  {/* <AddIcon  style={{fontWeight:"bold",color:"green"}}/> */}
                  <EditIcon  style={{fontWeight:"bold",color:"blue"}}/>
                  <DeleteIcon  style={{fontWeight:"bold",color:"red"}}/>
                {/* </Button>{' '} */}
                {/* <Button variant="warning" size="sm" onClick={() => handleEdit(item)}>
                  Edit
                </Button>{' '} */}
                {/* <Button variant="danger" size="sm" onClick={() => handleDelete(item.id)}>
                  Delete
                </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center no-print">
      <div>
          <Dropdown className='no-print'>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: '#7f083c',border:"1px solid #7f083c" }}>
              Per Page: {perPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handlePerPageChange(3)}>3</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(5)}>5</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(10)}>10</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(100)}>100</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Pagination className='no-print'>
          <Pagination.First onClick={handleFirst} disabled={currentPage === 1} />
          <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1} />
          {[...Array(Math.ceil(sortedData.length / perPage)).keys()].map((number) => (
            <Pagination.Item
              key={number}
              active={number + 1 === currentPage}
              onClick={() => paginate(number + 1)}
            >
              {number + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={handleNext} disabled={currentPage === Math.ceil(sortedData.length / perPage)} />
          <Pagination.Last
            onClick={handleLast}
            disabled={currentPage === Math.ceil(sortedData.length / perPage)}
          />
        </Pagination>
       
      </div>
        </div>): <div className='text-center'><p style={{border:"1px dashed #000",color:"red"}}>No records found</p></div>}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>View Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Name: </strong>{modalData?.name}</p>
          <p><strong>Age: </strong>{modalData?.age}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomTable;
