import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Alert,
  Modal,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Homemain from '../pages/homeMain';
import { Envelope, Eye, EyeSlashFill, Key } from "react-bootstrap-icons";
import { getUserProfile, getUsers, userRegister } from '../services';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './../styles/custom-calander.css'
function Register() {
  const [dob, setDob] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [validated, setValidated] = useState(false);
  const [lgShow, setLgShow] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [showPassType, setShowPassType] = useState("password");
  const [showPassConType, setShowPassConType] = useState("password");
  const [textFName, setTextFName] = useState("");
  const [textLName, settextLName] = useState("");
  const [textGender, settextGender] = useState("");
  const [textAge, setTexttextAge] = useState("");
  const [textPhone, setTextPhone] = useState("");
  const [textEmail, setTextEmail] = useState("");
  const [textEmailStatus, setTextEmailStatus] = useState("");
  const [textPassword, setTextPassword] = useState("");
  const [textConPassword, setTextConPassword] = useState("");
  const [agree, setAgree] = useState(false);

  const handleCheckboxChange = (e) => {
    setAgree(e.target.checked);
  };
  const navigate = useNavigate();
  const modelHideClick =((e)=>{
    navigate('/')
  })
  const data= useEffect((e)=>{
    
  },[])
  const handleDateChange = (date) => {
    setDob(date);
    // setValidated(true); // Reset validation when date changes
  };
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 15);
  const calculateAge = (selectedDate) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if(!dob) return null
    // else{
      setValidated(true);
    // }

   
    let userReg
    let userGet = getUsers(textEmail).then((response) => {
     
      if(response.data == 'Yes')
      {
        toast.warning('( '+textEmail+' )  this email already exist!.');
      }
      if(response.data == 'No')
      {
        if (!validatePhone(textPhone)) {
          toast.warning("Please enter a valid Indian phone number");
          return;
        }
      
        if(agree)
        {
          if(textPassword == textConPassword && getPasswordStrength() !== "Weak" && getPasswordStrength() !== "Medium")
          {
            setPhoneError("");
            const formData = new FormData();
            formData.append("fName", textFName);
            formData.append("lName", textLName);
            formData.append("gender", textGender);
            formData.append("dob", new Date(dob));
            formData.append("age", calculateAge(dob));
            formData.append("email", textEmail);
            formData.append("phone", textPhone);
            formData.append("password", textPassword);
            formData.append("profilePhoto", null);

            userReg = userRegister(formData).then((response) => {
            if(response.data)
            {
              sessionStorage.setItem("auth-details",JSON.stringify(response.data));
              localStorage.setItem("auth-details",JSON.stringify(response.data));
              toast.success("Registerd Successfully!")
              setTimeout(() => {
                navigate('/login')
              }, 3000); // 60000 milliseconds = 1 minute
              
              
            }
             
            }).catch((error)=>{toast.error(error)})
          }
          else{
           
            if(getPasswordStrength() === "Weak")
            {
              toast.warn("Your password strength is Weak. So you can create password strength is strong !.")
            }
            if(getPasswordStrength() === "Medium")
            {
              toast.warn("Your password strength is Medium. So you can create password strength is strong !.")
            }
            else{
              toast.warn("Password is didn't match")
            }
            
          }
        }
      else{
        toast.warn("Please check terms and conditions")
      }
      
        
     
      }
    }).catch((error)=>{toast.error(error)})
  
    
    const validatePhone = (phone) => {
      const regex = /^[6-9]\d{9}$/;
      return regex.test(phone);
    };
    
    
  };
  const clickHandler = (el,status) => {
   if(status == "pass")
   {
    setShowPass((prev) => !prev);
    if(showPassType == "password")
    setShowPassType("text")
    if(showPassType == "text")
    setShowPassType("password")
   }
   if(status == "con")
   {
    if(showPassConType == "password")
    setShowPassConType("text")
    if(showPassConType == "text")
    setShowPassConType("password")
   }
  };
  const txtOnchange = ((event,status) => {
    if(status == "email")
    {
      setTextEmail(event.target.value);
    }
   
    if(status == "password")
    {
      setTextPassword(event.target.value);
    }
    if(status == "con-password")
    {
      setTextConPassword(event.target.value);
    }
   
  })
  const getPasswordStrength = () => {
    if (textPassword === "" || textPassword.length < 8) return "Weak";
    if (
      textPassword.length == 8 ||
      !/[a-z]/.test(textPassword) ||
      !/[A-Z]/.test(textPassword) ||
      !/\d/.test(textPassword) ||
      !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(textPassword)
    )
      return "Medium";
    return "Strong";
  };

  const getFeedbackColor = () => {
    if (textPassword === "") return "";
    if (getPasswordStrength() === "Weak") return "text-danger";
    if (getPasswordStrength() === "Medium") return "text-warning";
    return "text-success";
  };

  const getPasswordFeedback = () => {
    if (textPassword === "") return "";
    if (textPassword.length < 8) return "Password must contain at least 8 characters";
    if (!/[a-z]/.test(textPassword)) return "Password must contain at least one lowercase letter";
    if (!/[A-Z]/.test(textPassword)) return "Password must contain at least one uppercase letter";
    if (!/\d/.test(textPassword)) return "Password must contain at least one numeric character";
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(textPassword)) return "Password must contain at least one special character";
    return "Password strength: Strong";
  };

  return (

    <div>
    <Homemain/>
    <Modal
    size={"lg"}
    show={lgShow}
    onHide={() => modelHideClick()}
    aria-labelledby="example-modal-sizes-title-sm"
    centered
  >
    <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="example-modal-sizes-title-md" style={{color:"white"}}>
      <span >Register Options</span>
     </Modal.Title>
     </div>
   </Modal.Header>
   <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
   
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-2">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First name"
            value={textFName}
            onChange={(e)=>{setTextFName(e.target.value)}}
            isInvalid={textFName === ""}
          />
          <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" >
          <Form.Label>Last name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Last name"
            value={textLName}
            onChange={(e)=>{settextLName(e.target.value)}}
          />
          
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="valiom02">
            <Form.Label>Date of Birth</Form.Label>
            <DatePicker
              selected={dob}
              onChange={handleDateChange}
              className={`form-control custom-datepicker ${!dob ? 'is-invalid' : ''}`}
              dateFormat="MM/dd/yyyy"
              isInvalid={!dob}
              maxDate={maxDate}
              placeholderText="MM/DD/YYYY" // Placeholder added here
            />
           
            {dob && (
              <p>
                Age: {calculateAge(dob)} years
              </p>
            )}
            <Form.Text style={{fontSize:"10pt"}} className={!dob ? 'text-danger' : ''}>
    {!dob && "Required"}
  </Form.Text>
          </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Gender</Form.Label>
        
          <Form.Select isInvalid={textGender ===""} aria-label="Default select example"  
          onChange={(e)=>{settextGender(e.target.value)} }  placeholder="Last name"
          value={textGender}
          >
          <option value="" disabled >
      -Select Gender-
    </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="neuter">Neuter</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select one gender
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="formBasicEmail">
          <Form.Label >Email address</Form.Label>
          <InputGroup >
        <InputGroup.Text id="password-input">
        <Envelope/>
        </InputGroup.Text>
          <Form.Control 
          value={textEmail}
          onChange={(e)=>{txtOnchange(e,"email")}}
          required type="email" placeholder="Enter email"
           isInvalid={textEmail === ""}
          />
          <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>

         </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Phone Number"
            value={textPhone}
            onChange={(e)=>{setTextPhone(e.target.value)}}
            isInvalid={textPhone === ""}
            maxLength={10}
          />
           <Form.Control.Feedback type="invalid">
             Required
            </Form.Control.Feedback>
         
        </Form.Group>
      </Row>
     
        <Row className="mb-2">
        <Form.Group as={Col} md="4" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <InputGroup className="mb-3">
        <InputGroup.Text id="password-input">
          <Key />
        </InputGroup.Text>
        <Form.Control
          type={showPassType}
          name="password"
          placeholder="Password"
          aria-label="password"
          aria-describedby="password-input"
          required
          value={textPassword}
          onChange={(e) => txtOnchange(e, "password")}
          isInvalid={textPassword === ""}
        />
        <InputGroup.Text onClick={(e) => clickHandler(e, "pass")}>
          {showPass ? (
            <EyeSlashFill
              style={{ color: "#c90057", fontWeight: "bold" }}
              title="Click Hide"
            />
          ) : (
            <Eye
              title="Click Show"
              style={{ color: "#c90057", fontWeight: "bold" }}
            />
          )}
        </InputGroup.Text>
        <Form.Text className={getFeedbackColor()}>
          {getPasswordFeedback()}
        </Form.Text>
       
        <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
        <Form.Group as={Col} md="4" controlId="formBasicPassword">
          <Form.Label >Confirm Password</Form.Label>
          <InputGroup className="mb-3">
        <InputGroup.Text id="password-input">
          <Key />
        </InputGroup.Text>
        <Form.Control
          type={showPassConType}
          name="conpassword"
          placeholder="Confirm Password"
          aria-label="password"
          aria-describedby="password-input"
          required
          value={textConPassword}
          onChange={(e)=>{txtOnchange(e,"con-password")}}
          isInvalid={textConPassword === ""}
          />
         
        <InputGroup.Text onClick={(e)=>{clickHandler(e,"con")}}>
       
          {showPassConType ? <EyeSlashFill style={{color:"#c90057",fontWeight:"bold"}} title='Click Hide'/> : 
          <Eye title='Click Show' style={{color:"#c90057",fontWeight:"bold"}} />}
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
      </InputGroup>
     
        </Form.Group>
       </Row>
     
     
      <Row >
      <Form.Group className="mb-1" >
        <Form.Check
          required
          id="termsAndConditionsCheckbox"
          label={
            <Form.Label style={{ cursor: 'pointer' }} htmlFor="termsAndConditionsCheckbox">
              Agree to terms and conditions
            </Form.Label>
          }
          feedback="You must agree before submitting."
          feedbackType="invalid"
          onChange={handleCheckboxChange}
          checked={agree}
          style={{ cursor: 'pointer' }}
        />
      </Form.Group>
      </Row>
      <Row>
      <Form.Group className="mb-1">
      <br/>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-success"  type="submit">
          Verify
        </Button>
        <div style={{padding:"5px"}}></div>
     
        <Button style={{border:"2px solid #c90057",width:"100%"}} variant='light' buttonvariant="outline-light"
        onClick={() => modelHideClick()} >
          Cancel
        </Button>
      </Form.Group>
      </Row>
     
    </Form>
    
    </Modal.Body>
    </Modal>
    <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
    </div>
   
  );
}

export default Register;