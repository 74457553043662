import {
    Container,
    Row ,
    Col

   } from "react-bootstrap";
   import "./../styles/footer.css";
function Footermain() {
    return (
       <Container fluid className="footerColor">
         <Row >
        <Col xs={12} md={8}>
        <div >
      <span style={{color:"white"}}>
    © {new Date().getFullYear()} Copyright : </span>
    <a style={{color:"#00ffff"}} href="https://shepherdsindia.org/">shepherdsindia.org</a>
<span style={{color:"white"}}>.All Rights Reserved</span>
</div>
        </Col>
        <Col xs={6} md={4}>
        <div className="rounded-social-buttons">
                    <a className="social-button facebook" href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                    <a className="social-button twitter" href="https://www.twitter.com/" target="_blank"><i className="fab fa-twitter"></i></a>
                    <a className="social-button linkedin" href="https://www.linkedin.com/" target="_blank"><i className="fab fa-linkedin"></i></a>
                    <a className="social-button youtube" href="https://www.youtube.com/" target="_blank"><i className="fab fa-youtube"></i></a>
                    <a className="social-button instagram" href="https://www.instagram.com/" target="_blank"><i className="fab fa-instagram"></i></a>
                </div>
        </Col>
      </Row>
         </Container>
         
         
     
      
    );
  }
  
  export default Footermain;