import crypto from 'crypto-js';
function EncryptText(el) {
  const CryptoJS = require("crypto-js");
  if(el !== "" && el !== null &&  el !== undefined)
  {
    // const secretPass = "XkhZG4fW2t2WE546C8D";
    // const str = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI1ZDg5MjMxMjc5OTkxYjJhNGMwMjdjMGIiLCJoc2`;
//   const encryptData = (el) => {
    // const data = CryptoJS.AES.encrypt(
    //   JSON.stringify(el),
    //   secretPass
    // ).toString();
    // const cryptoInfo = crypto.AES.encrypt(JSON.stringify(el), str).toString();
  //  return cryptoInfo;
   //var Key = C.enc.Utf8.parse("6il7YCRSqIOB9NooY225lPKQ0KuAF/nkFX6cY3vJkS0=");
   var Key = CryptoJS.enc.Utf8.parse("6il7YCRSqIOB9NooY225lPKQ0KuAF/nkFX6cY3vJkS0=");  // 1. Replace C by CryptoJS
   var IV = CryptoJS.enc.Utf8.parse("0123456789ABCDEF");
   var encryptedText = CryptoJS.AES.encrypt(JSON.stringify(el), Key, {
       iv: IV,
       mode: CryptoJS.mode.CBC,
       padding: CryptoJS.pad.Pkcs7
   });
   //return encryptedText.toString(CryptoJS.format.Hex);
   return encryptedText.toString(CryptoJS.format.Base64);        
    }
//   };
}
export default EncryptText;