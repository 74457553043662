import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import FlipBook from './pdf-viewer';
import "./../../styles/pdf-flip.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfToImageConverter = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState(null);
  
    function onFileChange(event) {
      const selectedFile = event.target.files[0];
      setFile(URL.createObjectURL(selectedFile));
	  // console.loh("file",file)
    }
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
    return (
      <div>
        <input type="file" onChange={onFileChange} />
        <div>
          {file && (
            <div>
            {/* <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document> */}
			{file}
            <FlipBook pdf={file}/>
            </div>
          )}
        </div>
        <p>Page {pageNumber} of {numPages}</p>
      </div>
    );
  };
export default PdfToImageConverter;
