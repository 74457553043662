import axios from "axios";

export const getApiBaseURL = () => {
  // let apiBaseURL = "http://localhost:3030/api";
  // let apiBaseURL = "https://qa.unityon.com/api";
  let apiBaseURL = null;;
  let apiProtocol = window.location.protocol;
  let apiHost = window.location.host;
  if (process.env.REACT_APP_API_PROTOCOL !== undefined) apiProtocol = process.env.REACT_APP_API_PROTOCOL;
  if (process.env.REACT_APP_API_HOST !== undefined) apiHost = process.env.REACT_APP_API_HOST;
  apiBaseURL = apiProtocol + '//' + apiHost + '/api';
  return apiBaseURL;
}

export const api = axios.create({
  // baseURL: "http://localhost:3030/api",
  // baseURL: "https://qa.unityon.com/api",
  // console.log("test")
  baseURL: getApiBaseURL(),
});

export const getProperties = () => {
  return api.get("/user/post");
};
export const getUsers = (email) => {
  return api.get("/user/email-status/"+email);
};
// login
export const getUsersDetails =(async (email,password) => {
  return await api.post("/user/login",{email,password});
});
// get chat
export const getLiveChat =(async() => {
  return await api.get("/user/chat")
})
// post chat message
export const addCommandsLiveChat =(async (id,formdata) => {
  return await api.post(`/user/chat-message/${id}`,formdata,{
    headers: { "Content-Type": "multipart/form-data" },
  });
});
// post chat photos
export const addChatPhotos =(async (id,formdata) => {
  return await api.post(`/user/chat-photos/${id}`,formdata,
    {
      headers: { "Content-Type": "multipart/form-data" },
    });
});
// excel bulk file upload
export const postBulkExcelFile =(async (file) => {
  return  await api.post("/user/excel-bulk-upload",file,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});
// excel bulk file upload course
export const postBulkExcelFileCourse =(async (file) => {
  return  await api.post("/user/excel-bulk-upload-course",file,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});
// add english book
export const addEnglishBook =(async (file) => {
  return await api.post("/user/english-book-add",file,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});
// update english book
export const updateEnglishBook =(async (file,id) => {
  return await api.post(`/user/english-book-update/${id}`,file,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});

// delete english book
export const deleteEnglishBook =(async (id) => {
  return await api.delete(`/user/english-book-delete/${id}`);
});
// delete english pdf
export const deleteEnglishBookPDF =(async (id) => {
  return await api.delete(`/user/english-book-delete-pdf/${id}`);
});
// get english book
export const getEnglishBook =(async() => {
  return await api.get("/user/english-book")
})
// add course
export const addCourse =(async (file) => {
  return await api.post("/user/course-add",file,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});
// update course
export const updateCourse =(async (file,id) => {
  return await api.post(`/user/course-update/${id}`,file,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});
// delete course
export const deleteCourse =(async (id) => {
  return await api.delete(`/user/course-delete/${id}`);
});
// get course 
export const getCourseAndDepartments =(async() => {
  return await api.get("/user/courses-departments")
})
// get english book files
export const getEnglishBookFiles =(async(id) => {
  return await api.get(`/user/english-book-files/${id}`)
})

export const userRegister = (async(data) => {
  // let item ={fName:fName,lName:lName,email:email,password:password,phone:phone,gender:gender,age:age}
  return api.post("/user/register", data,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});

export const getStudentRegistration = (async() => {
  return await api.get(`/admin/student-serialno`)
});
// student registration
export const getStudentRegistrationAll = (async() => {
  return await api.get(`/admin/student-registration`)
});
// otp verify
export const otpVerify = (async(data) => {
  return await api.post("/admin/otp-verify",data);
});
// otp verify
export const trackingCodeVerify = (async(data) => {
  return await api.post("/admin/tracking-code-verify",data);
});
// student registration status
export const studentRegistrationStatus =(async (id) => {
  return await api.get("/admin/student-registration-status/"+id);
});
// send otp
export const sendOTP =(async (id) => {
  return await api.post("/admin/send-otp",id);
});
//student register
export const studentRegister = (async(data) => {
  // let item ={fName:fName,lName:lName,email:email,password:password,phone:phone,gender:gender,age:age}
  return api.post("/admin/student-register", data,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});
//public registration
export const publicRegistration = (async(data) => {
  // let item ={fName:fName,lName:lName,email:email,password:password,phone:phone,gender:gender,age:age}
  return api.post("/admin/public-registration", data,
  {
    headers: { "Content-Type": "multipart/form-data" },
  });
});

