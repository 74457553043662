
import {Container,
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    Button,
    Alert,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Homemain from '../pages/homeMain';
function Registroption() {
  const [lgShow, setLgShow] = useState(true);
  const navigate = useNavigate();
  const modelHideClick =((e)=>{
    navigate('/')
  })
  const btnSecretClick =((e)=>{
    navigate('/secret-code-register')
  })
  const btnRegisterClick =((e)=>{
    navigate('/register')
  })
  
    return (
      <div>
      <Homemain/>
      <Modal
      size={"md"}
      show={lgShow}
      onHide={() => modelHideClick()}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
      <div style={{width:"100%",textAlign:"center"}}>
        <Modal.Title id="example-modal-sizes-title-md" style={{color:"white"}}>
        <span >Register Options</span>
       </Modal.Title>
       </div>
     </Modal.Header>
     <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
      <div style={{textAlign:"center"}}>
      {/* <Form>
      <fieldset>
        <Form.Group as={Row} className="mb-3">
         
          <Col sm={10}>
            <Form.Check
              type="radio"
              label="first radio"
              name="formHorizontalRadios"
              id="formHorizontalRadios1"
            />
            <Form.Check
              type="radio"
              label="second radio"
              name="formHorizontalRadios"
              id="formHorizontalRadios2"
            />
            <Form.Check
              type="radio"
              label="third radio"
              name="formHorizontalRadios"
              id="formHorizontalRadios3"
            />
          </Col>
        </Form.Group>
      </fieldset>
       
      </Form> */}
      <div style={{display:"flex",justifyContent:"space-evenly"}}>
      <p className='garmanFontReg'>
        Do you have secret code ? 
      </p> <Button variant='success' style={{border:"2px solid #c90057"}} 
         size='sm' onClick={(e) => btnSecretClick()} >Click Here</Button></div>
      <br/>
      <div style={{display:"flex",justifyContent:"center"}}>
        <span style={{color:"#dadada"}}>--------------   </span> <h4> OR </h4>
        <span style={{color:"#dadada"}}>   --------------</span></div>
      <br/>
      <div style={{display:"flex",justifyContent:"space-evenly"}}>
      <p className='garmanFontReg'>Guest (or) Welcomer's</p><Button style={{background:"#7f083c",border:"2px solid #c90057"}} size='sm' 
      variant='danger' onClick={(e) => btnRegisterClick()} >Click Here</Button></div>
      </div>
      </Modal.Body>
      </Modal>
      </div>
    );
  }
  
  export default Registroption;