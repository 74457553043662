import React,{ useState, useEffect } from "react";
import ReactDOM from "react-dom";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";

import "./../../styles/pdf-flip.css";


const Page = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <div className="pageContent">
          <h1>Page Header</h1>
          <p>{props.children}</p>
          <p>Page number: {props.number}</p>
        </div>
      </div>
    );
  });
  
function MyBook(props) {
    const [file, setFile] = useState(null);

    const [pdfBlob, setPdfBlob] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onloadend = () => {
            setFile(reader.result);
        };
    
        if (file) {
          reader.readAsDataURL(file);
        }
      };
    
      const handleUpload = () => {
        // Here you can upload 'file' to your server or perform other operations
        console.log('Uploading file:', file);
      };
const samplePDF ="./../../assets/images/sample.pdf";
useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(samplePDF);
        const pdfData = await response.blob();
        setPdfBlob(pdfData);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
    

    return () => {
        // Clean up the object URL to avoid memory leaks
        if (pdfBlob) {
          URL.revokeObjectURL(pdfBlob);
        }
      };
    }, []);
  
    const createPDFUrl = () => {
      if (pdfBlob) {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        return pdfUrl;
      }
      return null;
    };
  return (
   
        <div className="container">
          <HTMLFlipBook
            className="flip-book"
            size="stretch"
            width={250}
            height={250}
          >
            <div>TESTING</div>
            <Page number="1">Page text</Page>
            <div>TESTING3
                <div>
            {file && <img src={file} alt="Uploaded" style={{ width: '200px', height: '200px' }} />}
    </div>
            </div>
            <Page number="2">Page text</Page>

            <Page number="3">Page text</Page>
            <Page number="4">Page text</Page>
          </HTMLFlipBook>
          <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
        </div>
        
      );
}export default MyBook;