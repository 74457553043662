import React, { useEffect, useState } from 'react';
import '../../styles/LiveChatButton.css';
import EncryptText from '../../encrypt-decrypt/encrypt';
import { useNavigate } from 'react-router-dom';
const LiveChatButton = ({getReg}) => {
  const encrpt = EncryptText;
  const navigate = useNavigate();
  const [color, setColor] = useState('#007bff'); // Initial color
  const [isBlinking, setIsBlinking] = useState(true); // State for blinking effect

  // Function to generate a random color
  const randomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Change color every second
  useEffect(() => {
    const interval = setInterval(() => {
      setColor(randomColor());
    }, 1000); // Change color every second

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  // Function to handle button click
  const handleClick = () => {
    let apiProtocol = window.location.protocol;
  let apiHost = window.location.host;
  navigate('/live-chat?getReg='+getReg+'&status='+encrpt('student'));
}
       
 

  return (
    <div className="button-container">
      <button
        onClick={handleClick}
        className={`live-chat-button ${isBlinking ? 'blink' : ''}`} // Apply blinking class if needed
        style={{ backgroundColor: color }} // Apply random color
        title='If you have any query click here!'
      >
        💬 Chat with us!
      </button>
    </div>
  );
};
export default LiveChatButton;
