
import "./../../styles/home.css";
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom'
import CryptoJS from "crypto-js";
import  EncryptText from "../../encrypt-decrypt/encrypt";
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Alert,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { Form, FormControl, Table, Pagination, Button, Dropdown, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import DecryptText from "../../encrypt-decrypt/decrypt";
import { getStudentRegistration, otpVerify, studentRegistrationStatus } from "../../services";
import OTPInput, { ResendOTP } from "otp-input-react";
function RegistrationVerification() {
  const [searchParams, setSearchParams] = useSearchParams();
    const encrpt = EncryptText;
    const decrypt =DecryptText
    const getReg = searchParams.get('getReg')
    const status = searchParams.get('status')
    var getStatus =decrypt(status)
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [modelBulk, setModelBulk] = useState(false);
  const [validated, setValidated] = useState(false);
  const [otpGet, setOtp] = useState("");
  const isInvalid = otpGet.length !== 6;
  const fileSelectedHandler = (event, status) => {
    if (status === "bulk") {
      const file = event.target.files;
      if (file) {
        for (let fl of file)
            {
                const fileNameParts = fl.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        if (['jpeg', 'jpg', 'png', 'gif'].includes(fileExtension.toLowerCase())) {
          setSelectedFile(file);
        } else {
          event.target.value = '';
          toast.warn('Please select an image file (.jpeg, .jpg, .png, .gif format)');
        }
            }
        
      } else {
        // No file selected
        setSelectedFile(null); // Clear the selected file
        toast.warn('Please select a file.');
      }
    }
  };

  const fileUploadHandler = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
  
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (!selectedFile) {
        // Handle the case when no file is selected
        toast.warn("Please select a file.");
      } else {
        // Handle the case when file is selected
        const formData = new FormData();
        formData.append("excelFile", selectedFile);
  
        try {
            console.log("selectedFile",selectedFile)
          // Upload the file
          // Example: await uploadFile(formData);
          toast.success("File uploaded successfully");
          setModelBulk(false);
        } catch (error) {
          console.error('Error uploading the file: ', error);
          toast.error("Error uploading the file");
        }
      }
    }
    setValidated(true);
  };

  const adminClick = (event,status) => {
   
    navigate(`/user/dashboard-main?getData=${encrpt(status)}`);
  }
  useEffect((e)=>{
   try{
    studentRegistrationStatus(getReg).then((response) => {
      if(response.data.error && getStatus === 'student')
        {
          toast.error(response.data.error)
        }
        if(response.data.finalStatus && getStatus === 'student')
          {
            toast.success(response.data.finalStatus)
          }
          if(response.data.managerPending && getStatus === 'student')
            {
              toast.success(response.data.managerPending)
            }
            if(response.data.success && getStatus === 'student')
              {
                setModelBulk(true)
              }    
              if(response.data.success && getStatus === 'manager')
                {
                  setModelBulk(false)
                }    
        
    }).catch((error)=>{console.log(error)})
   }
   catch(error){
    toast.error(error)
   }
    
   
    
  },[])
  const verifySubmit=(event)=>{
    event.preventDefault();
    const form = event.currentTarget;
    if (otpGet === '' || isInvalid == true) {
      event.stopPropagation();
      setValidated(true);
      toast.error('Please fill in the required field.');
      return;
    }
    setValidated(false);
    try{
      otpVerify({getReg,otpGet}).then((response) => {
       
        if(response.data != undefined)
          {
           
            if(response.data.error)
              toast.error(response.data.error)
            if(response.data.success == true)
              toast.success("Your otp is verified successfully !")
          }
          
      }).catch((error)=>{toast.error(error)})
    }
    catch(error){toast.error(error)}
   
    
  }
    return (
      <div>
        <div className="row text-center">
        <h4 style={{fontWeight:"bold"}}></h4></div>
     
                    <Modal
         size={"md"}
         show={modelBulk}
         onHide={() => setModelBulk(false)}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
         style={{
          zIndex: 1050, // Adjust the z-index as needed
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background
        }}
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>setModelBulk(false)} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m1d" style={{color:"white"}}>
           <span >OTP Verification</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
        <Form noValidate validated={validated} onSubmit={verifySubmit}>
              <Row>
              <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>OTP</Form.Label>
             
          <OTPInput
            value={otpGet}
    onChange={setOtp}
    autoFocus
    OTPLength={6}
    otpType="number"
    disabled={false}
    inputClassName="otp-input" // Apply your custom class to style each OTP input box
    className={isInvalid ? 'is-invalid otp-container' : 'otp-container'} // Custom container class
          />
            
            {isInvalid && (
        <div className="invalid-feedback">
          *Required and must be 6 digits
        </div>
      )}
          <Form.Text className="text-muted">
          Please enter your six-digit OTP send by us in your email.
  </Form.Text>
          </Form.Group>
              </Row>
              <br/>
              <br/>
              <Row>
                
                <Form.Group className="mb-1">
                  <Button
                    style={{ background: "#7f083c", border: "2px solid #c90057", width: "100%" }}
                   
                    type="submit"
                  >
                    Verify
                  </Button>
                </Form.Group>
              </Row>
            </Form>
        </Modal.Body>
      </Modal>   
      <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />  
    
      </div>
    );
  }
  
  export default RegistrationVerification;