import axios from "axios";
import { API_URL } from "../constant/api.constant";
import getToken from "./tokensetter";

export const getApiBaseURL = () => {
  // let apiBaseURL = "http://localhost:3030/api";
  // let apiBaseURL = "https://qa.unityon.com/api";
  let apiBaseURL = null;;
  let apiProtocol = window.location.protocol;
  let apiHost = window.location.host;
  if (process.env.REACT_APP_API_PROTOCOL !== undefined) apiProtocol = process.env.REACT_APP_API_PROTOCOL;
  if (process.env.REACT_APP_API_HOST !== undefined) apiHost = process.env.REACT_APP_API_HOST;
  apiBaseURL = apiProtocol + '//' + apiHost + '/api';
  return apiBaseURL;
}

// export const api = axios.create({
//   // baseURL: "http://localhost:3030/api",
//   // baseURL: "https://qa.unityon.com/api",
//   // console.log("test")
//   baseURL: getApiBaseURL(),
// });
const axiosInstance = axios.create({
  baseURL: getApiBaseURL(),
  // headers: {
  //   "Content-Type": "application/json",
  // },
  // timeout: 2000,

});


axiosInstance.interceptors.request.use(function (config) {
  // const token = getToken();
  // if (token) config.headers["Authorization"] = `Bearer ${token}`;

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  const originalConfig = error.config;
    if (error.response) {
      // Access Token was expired
      if (error.response.status === 401) {
        console.log("user Not loggedIn", { error });
      }
      if (error.response.status === 403 && error.response.data) {
        return Promise.reject(error.response.data);
      }
    }
  return Promise.reject(error);
});
export default axiosInstance;
export const postAPI = (url, data) => axiosInstance.post(`${url}`, data);
export const putAPI = (url, data) => axiosInstance.put(`${url}`, data);
export const getAPI = (url) => axiosInstance.get(`${url}`);
export const getQueryAPI = (url, params) =>  axiosInstance.get(`${url}`, { params });
export const patchAPI = (url) => axiosInstance.patch(`${url}`);
export const patchQueryAPI = (url,data) => axiosInstance.patch(`${url}`,data);
export const deleteAPI = (url) => axiosInstance.delete(`${url}`);
export const deleteQueryAPI = (url, params) =>  axiosInstance.delete(`${url}`, { params });
