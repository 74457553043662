import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function AuthShield({ children }) {
    const prevLocation = useLocation();
    const token = sessionStorage.getItem('accessToken');

    if (
        prevLocation.pathname &&
        token
    ) {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const status = decodedToken.exp * 1000 < Date.now();

        if (status) {
            sessionStorage.setItem('previousurl', prevLocation.pathname);
            return <Navigate to="/login" state={{ from: prevLocation }} replace />;
        }
    } else {
        sessionStorage.setItem('previousurl', prevLocation.pathname);
        return <Navigate to="/login" state={{ from: prevLocation }} replace />;
    }

    return children;
}

export default AuthShield;
