
import Logos from "./../assets/images/te.ico"
import "./../styles/navBar.css";
import "./../styles/navSub.css";
import {
    Container,
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    Button,
    Alert,
    Modal 
   } from "react-bootstrap";
   import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import EncryptText from "../encrypt-decrypt/encrypt";
function NavbarPublic(props) {
  const navigate = useNavigate();
  const encry =EncryptText
  var magnifierHeight = 250;
  var magnifieWidth = 250;
  var zoomLevel = 3;
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  
   const btnLogin=((e)=>{
 
    navigate('/login')
  })
 
  const subMenuClick =(e,status)=>{
    navigate('/admission-form?status='+encry(status))

  }
 
    return (
    <div>
      <Navbar expand="lg"    variant="dark">
    <Container fluid style={{background:"#c90057"}}>
      <Navbar.Brand href="#">
         <img src={Logos}
       onMouseEnter={(e) => {
        // update image size and turn-on magnifier
        const elem = e.currentTarget;
        const { width, height } = elem.getBoundingClientRect();
        setSize([100, 100]);
        setShowMagnifier(true);
      }}
      onMouseMove={(e) => {
        // update cursor position
        const elem = e.currentTarget;
        const { top, left } = elem.getBoundingClientRect();

        // calculate cursor position on the image
        const x = e.pageX - left - window.pageXOffset;
        const y = e.pageY - top - window.pageYOffset;
        setXY([x, y]);
      }}
      onMouseLeave={() => {
        // close magnifier
        setShowMagnifier(false);
      }}
       width="75" height="75" ></img> 
         <div
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",

          // prevent magnifier blocks the mousemove event of img
          pointerEvents: "none",
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: "1", // reduce opacity so you can verify position
          border: "1px solid lightgray",
          backgroundColor: "white",
          backgroundImage: `url('${Logos}')`,
          backgroundRepeat: "no-repeat",
          borderRadius:"50%",
          //calculate zoomed image size
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,

          //calculate position of zoomed image.
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
        }}
      ></div>
     </Navbar.Brand>
     <span className="logoText d-none d-md-block" >
            SHEPHERD BIBLICAL SEMINARY
          </span>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll"><Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
           
            aria-label="Search"
          />
          {/* <Button  style={{color:"white"}} variant="outline-success">Search</Button> */}
        </Form>
        <Nav
        className="mr-auto"
        bg="#c90057"
        
         variant="tabs" 
        defaultActiveKey="/"
        >
          <Nav.Link style={{background:"#c90057"}}  href="/">Home</Nav.Link>
           <Nav.Link style={{background:"#c90057"}} href="about-us">About Us</Nav.Link>
           {/* <Nav className="custom-nav"> */}
  <NavDropdown
    title="Admission Form"
    id="news-and-events-dropdown"
    className="hover-dropdown"
    style={{ background: "#c90057" }}
  >
    <NavDropdown.Item href="#" onClick={(e)=>subMenuClick(e,'student')}>
      Student Information
    </NavDropdown.Item>
    <NavDropdown.Item href="#" onClick={(e)=>subMenuClick(e,'faculty')}>
      Faculty Information
    </NavDropdown.Item>
    {/* <NavDropdown.Divider /> */}
    <NavDropdown.Item href="#" onClick={(e)=>subMenuClick(e,'STAFF')}>
      Staff Information
    </NavDropdown.Item>
  </NavDropdown>
{/* </Nav> */}
         
          <Nav.Link style={{background:"#c90057"}} href="contact-us">Contact Us</Nav.Link>
        </Nav>
        <Nav
     
        >
        <Button style={{background:"#7f083c",border:"2px solid #fff"}} onClick={(e) => btnLogin()} className="me-2">Login</Button>
        
          {/* <Nav.Link href="#action2" className="nav-item">Link</Nav.Link> */}
          {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action4">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action5">
              Something else here
            </NavDropdown.Item>
          </NavDropdown> */}
          {/* <Nav.Link href="#" disabled>
            Link
          </Nav.Link> */}
        </Nav>
        
      </Navbar.Collapse>
    </Container>
  </Navbar>

 

    </div>
    );
  }
  
  export default NavbarPublic;