// import CryptoJS from "crypto-js";
function DecryptText(el) {
  const CryptoJS = require("crypto-js");
  if(el !== "" && el !== null &&  el !== undefined)
  {
    // const secretPass = "XkhZG4fW2t2WE546C8D";
//   const encryptData = (el) => {
  // const str = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI1ZDg5MjMxMjc5OTkxYjJhNGMwMjdjMGIiLCJoc2`;
    // const bytes = CryptoJS.AES.decrypt(el, secretPass);
    // const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //   const info2 = crypto.AES.decrypt(el, str).toString(crypto.enc.Utf8);
  //   const result = JSON.parse(info2);
  //  return result
  var C = CryptoJS;
  //encryptedData = C.enc.Base64.parse(encryptedData);                                // 3. Remove line
  var Key = C.enc.Utf8.parse("6il7YCRSqIOB9NooY225lPKQ0KuAF/nkFX6cY3vJkS0=");
  var IV = C.enc.Utf8.parse("0123456789ABCDEF");
  //var decryptedText = C.AES.encrypt(encryptedData, Key, {
  var decryptedText = C.AES.decrypt(el, Key, {                             // 4. Use decrypt instead of encrypt
      iv: IV,
      mode: C.mode.CBC,
      padding: C.pad.Pkcs7
  });
  //return encryptedData.toString(CryptoJS.enc.Utf8);
  // return decryptedText.toString(CryptoJS.enc.Utf8);  
  return  JSON.parse(decryptedText.toString(CryptoJS.enc.Utf8));
  }
//   };
}
export default DecryptText;