import React, { useState, useEffect } from 'react';
import {
  Card,
  Button,
  Collapse
} from "react-bootstrap";
import { getCourseAndDepartments } from "../services";
import "./../styles/Coursesmain.css"

function Coursesmain() {
  const [dataGet, setDataGet] = useState([]);
  const [open, setOpen] = useState([]);
  const [cardColors, setCardColors] = useState([]);

  useEffect(() => {
    getCourseAndDepartments().then((response) => {
      if (response.data !== undefined) {

        const groupedCourses = response.data.reduce((acc, course) => {
          const { heading, ...rest } = course;
          if (!acc[heading]) {
            acc[heading] = [];
          }
          acc[heading].push(rest);
          return acc;
        }, {});

        // Convert object to array
        const groupedCoursesArray = Object.keys(groupedCourses).map((heading) => ({
          heading,
          courses: groupedCourses[heading]
        }));

        // Initialize open state array and card color array
        setOpen(new Array(groupedCoursesArray.length).fill(false));
        setCardColors(generateRandomColors(groupedCoursesArray.length));

        setDataGet(groupedCoursesArray);
      }

    }).catch((error) => { console.log(error) })
  }, [])

  const generateRandomColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      colors.push(randomColor);
    }
    return colors;
  }

  const handleOpen = (index) => {
    const updatedOpen = [...open];
    updatedOpen[index] = !updatedOpen[index];
    setOpen(updatedOpen);
  }

  return (
    <div>
      {dataGet.map((item, index) => (
        <div style={{ padding: "10px" }} key={index}>
          <Card
            style={{
              backgroundColor: `
                ${index===0?"rgb(225, 253, 198)":index===1?"rgb(253, 227, 178)":index===2?"rgb(189, 234, 245)":""}`,
                borderBottom:`${index===0?"3px solid rgb(4, 65, 4)":index===1?"3px solid rgb(155, 102, 3)":index===2?"3px solid rgb(1, 90, 112)":""}`
            }}
          >
            <Card.Header  style={{
              backgroundImage: `linear-gradient(to bottom right, 
                ${index===0?"rgb(4, 65, 4)":index===1?"rgb(155, 102, 3)":index===2?"rgb(1, 90, 112)":"#000"}, #ffffff)`,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.08)",
              borderTop:`${index===0?"10px solid rgb(4, 65, 4)":index===1?"10px solid rgb(155, 102, 3)":index===2?"10px solid rgb(1, 90, 112)":""}`
              ,borderBottom:`${index===0?"3px solid rgb(4, 65, 4)":index===1?"3px solid rgb(155, 102, 3)":index===2?"3px solid rgb(1, 90, 112)":""}`
            }}>
              <Card.Title
               style={{color:"#fff",fontWeight:"bold",fontSize:"12pt"}}>{item.heading}</Card.Title>
              <div style={{float:"right"}}>
                <Button
                  variant="outline-danger"
                  onClick={() => handleOpen(index)}
                  aria-controls={`collapse-${index}`}
                  aria-expanded={open[index]}
                  className={`hand-animation ${open[index] ? "read-less" : "read-more"} ${open[index] ? "button-click-animation" : ""}`}
                >
                  <span role="img" aria-label="hand" className={open[index] ? "hand-animation" :""} >👉</span> {open[index] ? 'Read Less' : 'Read More'}
                </Button>
              </div>
            </Card.Header>
            <Collapse in={open[index]}>
              <Card.Body>
                {item.courses.map((el, i) => (
                  <div key={i}>
                    <span style={{color:"red"}} > *</span>{el.courseFullName}
                  </div>
                ))}
              </Card.Body>
            </Collapse>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default Coursesmain;
