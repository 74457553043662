import React, { useState,useEffect,useRef } from 'react';
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Alert,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { Form, FormControl, Table, Pagination, Button, Dropdown, Modal, ProgressBar } from 'react-bootstrap';
import ImageIcon from '@mui/icons-material/Image';
import { CSVLink } from 'react-csv';
import AddIcon from '@mui/icons-material/Add';
import SignaturePad from "react-signature-canvas";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  PictureAsPdf,
  InsertDriveFile,
  Description,
  TableChart,
  TextSnippet,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import { addCourse, addEnglishBook, deleteCourse, deleteEnglishBook, deleteEnglishBookPDF, getCourseAndDepartments, getEnglishBook, getEnglishBookFiles, getStudentRegistration, getStudentRegistrationAll, postBulkExcelFile, postBulkExcelFileCourse, sendOTP, studentRegister, updateCourse, updateEnglishBook } from '../../services';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import './../../styles/modelFulscreen.css';
import PdfFlip from '../../components/pdf-flip';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './../../styles/custom-calander.css'
import DecryptText from '../../encrypt-decrypt/decrypt';
import EncryptText from '../../encrypt-decrypt/encrypt';
import Loader from '../../components/loader-custom';
// import * as xlsx from 'xlsx';
const data = [
];

function OfficeStuRegView(){
  const [loading, setLoading] = useState(false);
   var serialNo =""
  
  const [formValues, setFormValues] = useState({
    regNo: '',fName: '', mName: '',lName: '',gender: '',dob: '',age: '',bGroup: '',mStatus: '', nation: '',con1: '',
    con2: '', email: '',fatName: '',fPhone: '',fOccu: '',moName: '',moPhone: '', mOccu: '',tAddress1: '',tAddress2: '',
    tCity: '',tDistrict: '', tPost: '',tState: '',pAddress1: '',pAddress2: '',pCity: '',pDistrict: '',pPost: '', pState: '',
    emConName: '', relation: '',emPhone: '',emEmail: '',academic: [{ course: "", university: "",yearOfPassout: "", totalMarks: "",percentage: "",
    }], program: '',degreeLev: '',study: '',totalYear: '',otp:'',otpVerify:false,studentVerify:false,managerVerify:false,
    proType: '',courseFullName: '',curriculum: null,curriculumOther: '',paricipatin: '',resource: '', skil: '', sports: '',
    tcFiles: [], markFiles: [],otherFiles: [],regName: '',regEmail: '',stuSign: null,diractorName: '',diractorEmail: '',diractorSign: null,
    studentVerifyUrl:'',managerVerifyUrl:''
  
  });

    
    const decry =DecryptText
    const encry =EncryptText
    
   
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [dob, setDob] = useState(null);
  const [stuRegNo, setStuRegNo] = useState("");
  const [textGender, settextGender] = useState("");
   //field value set
   const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
   const [txtCourseFullName, setTxtCourseFullName] = useState("");
   const [txtCourseShortName, setTxtCourseShortName] = useState("");
   const [txtYears, setTxtYears] = useState("");
   const [txtStatus, setTxtStatus] = useState("");
   const [txtHeading, setTxtHeading] = useState("");
   const [modelStatus, setModelStatus] = useState("");
   const [modelBulk, setmodelBulk] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const [newOptions, setNewOptions] = useState(false);
   const [validated, setValidated] = useState(false);
   const [dataGet, setDataGet] = useState([]);
   const [courseGet, setCourseGet] = useState([]);
   const [search, setSearch] = useState('');
   const [perPage, setPerPage] = useState(20);
   const [sortConfig, setSortConfig] = useState({ key: null, direction: null, first: null });
   const [showAddUpdateModal, setshowAddUpdateModal] = useState(false);
   const [showBulkUpload, setshowBulkUpload] = useState(false);
   const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
   const [fullscreen, setFullscreen] = useState(true);
   const [show, setShow] = useState(false);
   const [bookTitle, setBookTitle] = useState(null);
   const [bookId, setBookId] = useState(null);
   const [files, setFiles] = useState([]);
   const [showDelete, setShowDelete] = useState(false);
  const currentYear = new Date().getFullYear();
  let sigPad = useRef({});
  const handleDateChange = (date) => {
    setDob(date);
    setFormValues(prevValues => ({
      ...prevValues,
      dob:new Date(date),
      age:(calculateAge(date))+' Years'
    }));
    // setValidated(true); // Reset validation when date changes
  };
  const years = [];
  for (let i = 1950; i <= currentYear; i++) {
    years.push(i);
  }
  

  // Handle add row
  const handleAddRow = () => {
    const isAnyFieldEmpty = formValues.academic.some(detail => {
      return (
        detail.course === "" ||
        detail.university === "" ||
        detail.yearOfPassout === "" ||
        detail.totalMarks === "" ||
        detail.percentage === ""
      );
    });
  
    if (isAnyFieldEmpty) {
      // Show an error or toast message if needed
      toast.error("Please fill in all the fields before adding a new row.");
      return;
    }
    setFormValues(prevValues => ({
        ...prevValues,
        academic: [
            ...prevValues.academic,
            { course: "", university: "", yearOfPassout: "", totalMarks: "", percentage: "" }
        ]
    }));
};

const handleDeleteRow = index => {
    setFormValues(prevValues => ({
        ...prevValues,
        academic: prevValues.academic.filter((_, i) => i !== index)
    }));
};


  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 15);
  const calculateAge = (selectedDate) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  const fileSelectedHandler = (event, status) => {
    const files = event.target.files;
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp', 'heic', 'heif'];

    if (files.length > 0) {
        let allValid = true;

        // Check if all files are valid
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                allValid = false;
                break;
            }
        }

        if (allValid) {
            // Update the appropriate file array in formValues based on status
            setFormValues(prevValues => {
                let updatedFiles;
                if (status === "tc") {
                    updatedFiles = Array.from(files);
                    return {
                        ...prevValues,
                        tcFiles: updatedFiles
                    };
                } else if (status === "mark") {
                    updatedFiles = Array.from(files);
                    return {
                        ...prevValues,
                        markFiles: updatedFiles
                    };
                } else if (status === "other") {
                    updatedFiles = Array.from(files);
                    return {
                        ...prevValues,
                        otherFiles: updatedFiles
                    };
                } else if (status === "curriculum") {
                    updatedFiles = Array.from(files);
                    return {
                        ...prevValues,
                        curriculum: updatedFiles
                    };
                }
            });
            setValidated(false); // Reset validation if valid files are selected
        } else {
            event.target.value = ''; // Clear invalid selection
            setFormValues(prevValues => ({
                ...prevValues,
                [status === "tc" ? "tcFiles" : status === "mark" ? "markFiles" : status === "other" ? "otherFiles" : "curriculum"]: [] // Reset the appropriate file array
            }));
            setValidated(true); // Show validation error
            toast.warn('Please select valid image files (.jpg, .jpeg, .png, .webp, .heic, .heif)');
        }
    }
};

  const clearSig = (e) => {
    e.preventDefault();
    sigPad.clear();
    setIsSigned(false);
}
  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selected) => selected !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allItems = currentItems.map((item) => item.courseId);
      setSelectedItems(allItems);
    }
    setSelectAll(!selectAll);
  };
  const deleteRecordClick=(async (event,id)=> {
   
    event.preventDefault()
    if(selectedItems.length<1 )
        {
            toast.warn("You can't delete this row . Because you didn't select any record.  You can select atleast one record!")   
        }
        if(selectedItems.length>0 )
            {
                setShowDelete(true)
                setBookId(id)
            }
    
   
  })
  const deleteRecordSubmit=(async (event,ids)=> {
   
    event.preventDefault()
    try {
        
                const responses = await Promise.all(
                    ids.map(async (id) => {
                  const response = await deleteCourse(id).then((response) => {
                    if(response.data)
                      {
                        toast.success("Record is deleted successfully !")
                        window.location.reload()
                      }
                    
                  }).catch((error)=>{console.log(error)})
                }))
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  })
  const pdfViewClick=(async (event,id,book)=> {
    event.preventDefault()
    try {
      const response = await getEnglishBookFiles(id).then((response) => {
       
        // var url = URL.createObjectURL(response.data[0]);
        // console.log("response",url)
       
        setFiles(response.data)
       
      }).catch((error)=>{console.log(error)})
      setBookTitle(book);
      setShow(true);
    //   console.log("response",response)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
   
  })
  useEffect(async (e)=>{
    let otp = await generateOTP()
    await getStudentRegistration().then((response) => {
      if(response.data != undefined)
        {
      setStuRegNo(response.data)
      
      let apiBaseURL = null;;
      let apiProtocol = window.location.protocol;
      let apiHost = window.location.host;
      const fullName = sessionStorage.getItem("fullName");
      const emailGet = sessionStorage.getItem("emailGet");
      
      if (fullName && emailGet) {
       
        const decryptedName = decry(fullName).replace(/\"/g, "");
        const decrypteEmail = decry(emailGet).replace(/\"/g, "");
        setFormValues(prevValues => ({
          ...prevValues,
          regNo:response.data,
          regName: decryptedName,
          regEmail:decrypteEmail,
          otp:otp,
          studentVerifyUrl:apiProtocol + '//' + apiHost + '/student-registration-email-link?getReg='+response.data+'&status='+encry('student'),
          managerVerifyUrl:apiProtocol + '//' + apiHost + '/student-registration-email-link?getReg='+response.data+'&status='+encry('manager')
  
        }));
      }
        }
    }).catch((error)=>{console.log(error)})
    getStudentRegistrationAll().then((response) => {
      if(response.data != undefined)
      {
        setDataGet(response.data)
        const groupedCourses = response.data.reduce((acc, course) => {
          const { heading, ...rest } = course;
          if (!acc[heading]) {
              acc[heading] = [];
          }
          acc[heading].push(rest);
          return acc;
      }, {});
      
      // Convert object to array
     
      const groupedCoursesArray = Object.keys(groupedCourses).map((heading) => ({
          heading,
          courses: groupedCourses[heading]
      }));
      
      

      }
      let key ="courseId"
      let direction = 'ascending';
      let first = 'yes';
      setSortConfig({ key, direction,first });
    }).catch((error)=>{console.log(error)})
    getCourseAndDepartments().then((response) => {
      if(response.data != undefined)
      {
        setCourseGet(response.data)
      }
      let key ="courseId"
      let direction = 'ascending';
      let first = 'yes';
      setSortConfig({ key, direction,first });
    }).catch((error)=>{console.log(error)})
   
  },[])
  const modelHideClick =((e)=>{
    setNewOptions(false)
    setshowAddUpdateModal(false)
    setshowBulkUpload(false)
    setmodelBulk(false)
    setShowDelete(false)
  })
  const handleSubmits = async (event) => {
    event.preventDefault()
   
    const formData = new FormData();
    formData.append("courseFullName", txtCourseFullName);
    formData.append("courseShortName", txtCourseShortName);
    formData.append("statusOfStudy", txtStatus);
    formData.append("heading", txtHeading);
    formData.append("years", txtYears);
    
    if(modelStatus === "Edit New Record")
      {
       
        updateCourse(formData,bookId).then((response) => {
          if(response.data)
            {
              toast.success("Record is Updated successfully !")
              setNewOptions(false)
              setshowAddUpdateModal(false)
              setshowBulkUpload(false)
              setmodelBulk(false)
              window.location.reload()
            }
          
        }).catch((error)=>{
          toast.warn(" This record is already exists !")
        }
        )
      }
      if(modelStatus === "Add New Record"){
        addCourse(formData).then((response) => {
          if(response.data)
            {
              toast.success("New record saved successfully !")
              setNewOptions(false)
              setshowAddUpdateModal(false)
              setshowBulkUpload(false)
              setmodelBulk(false)
              window.location.reload()
            }
          
        }).catch((error)=>{
          toast.warn(" This record is already exists !")
        }
        )
      }
    
    
  }
  const editRecords=(id,fName,SName,years,status,heading)=>{
    setTxtCourseFullName(fName)
    setTxtCourseShortName(SName)
    setTxtYears(years)
    setTxtStatus(status)
    setTxtHeading(heading)
    setBookId(id)
  }
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  
  const sortedData = dataGet
    .filter((item) => item?.regNo?.toLowerCase().includes(search?search.toLowerCase():"") 
    || item?.fName?.toLowerCase().includes(search?search.toLowerCase():"")
    || item?.mName?.toLowerCase().includes(search?search.toLowerCase():"")
    || item?.lName?.toLowerCase().includes(search?search.toLowerCase():"")
    || item?.program?.toLowerCase().includes(search?search.toLowerCase():"")
  )
    .sort((a, b) => {
      if (sortConfig.direction === 'ascending') {
        return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
      }
      if (sortConfig.direction === 'descending') {
        return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
      }
      return null;
    });
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const tableRef = useRef(null);
  const exportToExcel=()=> {
    const htmlTable = tableRef.current.outerHTML;
    const workbook = XLSX.utils.table_to_book(document.querySelector('table'));
    XLSX.writeFile(workbook, 'table-to-doc.xlsx');
  }
  const handleExport = (format) => {
    switch (format) {
      case 'pdf':
        return window.print();
      case 'excel':
        return exportToExcel()
      case 'doc':
        return saveAs(new Blob([tableRef.current.outerHTML], { type: 'application/msword' }), 'table-to-doc.doc');
      case 'csv':
        return saveAs(new Blob([tableToCSV(tableRef.current)], { type: 'text/csv;charset=utf-8' }), 'table-to-csv.csv');
      case 'txt':
        return saveAs(new Blob([tableToText(tableRef.current)], { type: 'text/plain;charset=utf-8' }), 'table-to-txt.txt');
      default:
        return null;
    }
  };
  const handleFirst = () => {
    setCurrentPage(1);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNexts = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleLast = () => {
    setCurrentPage(Math.ceil(sortedData.length / perPage));
  };

  const handlePerPageChange = (size) => {
    setPerPage(size);
    setCurrentPage(1);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    let first = 'no';
      setSortConfig({ key, direction,first });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleAdd = () => {
    // Add your logic for adding data here
    
    console.log('Add');
  };

  const handleEdit = (item) => {
    // Add your logic for editing data here
    console.log('Edit', item);
  };
  async function generateOTP() {
    return Math.floor(100000 + Math.random() * 900000).toString();
}

async function sendOTPs(stuRegNo) {
   
    let sendOtpEmail= await sendOTP({stuRegNo}).then((response) => {
      if(response.data != undefined)
        {
          if(response.data.message)
          {
             toast.success(response.data.message)
          window.location.reload()
          }
          if(response.data.error)
            toast.error(response.data.error)
        }
    }).catch((error)=>{toast.log(error)})

  
}
  const handleView = (item) => {
    // Add your logic for viewing data here
    console.log('View', item);
    // setModalData(item);
    // setShowModal(true);
  };

  const handleDelete = (id) => {
    // Add your logic for deleting data here
    console.log('Delete', id);
  };
  const tableToCSV = (table) => {
    let csv = [];
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (let j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(','));
    }
    return csv.join('\n');
  };

  const tableToText = (table) => {
    let text = [];
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (let j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      text.push(row.join('\t'));
    }
    return text.join('\n');
  };
  
  const exportPDF = () => {
   
  };
  const headers = [
    { label: 'Sno', key: 'bno' },
    { label: 'Author', key: 'author' },
    { label: 'Book', key: 'book' },
    { label: 'Place', key: 'place' },
    { label: 'Press', key: 'press' },
    { label: 'year', key: 'year' }

  ];
  const totalPages = Math.ceil(sortedData.length / perPage);

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => paginate(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };
  const renderPaginationItems = () => {
    const pageNumbers = getPageNumbers();
    const items = [];

    for (let i = 0; i < pageNumbers.length; i += 10) {
      items.push(
        <div key={i} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {pageNumbers.slice(i, i + 10)}
        </div>
      );
    }
    return items;
  };
  const fileUploadHandler = (async (el) => {
    el.preventDefault();
    const formData = new FormData();
    formData.append("courseExcel", selectedFile);
    const files = await postBulkExcelFileCourse(formData).then(async(response) => {
     
      if(response.data.alreadyExist == true)
        {
    try {
      const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(response.data.records);
        XLSX.utils.book_append_sheet(workbook, sheet, 'Existing Records');

        const fileBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'existing_records.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file: ', error);
    }
    toast.warn("Already Exists")
    setNewOptions(false)
    setshowAddUpdateModal(false)
    setshowBulkUpload(false)
    setmodelBulk(false)
    setShowDelete(false)
  }
 
  else if(response.data.missingData == true){
    toast.success("Your file column data is missing. Please verify !")
    try {
      const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(response.data.records);
        XLSX.utils.book_append_sheet(workbook, sheet, 'Existing Records');

        const fileBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'existing_records.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file: ', error);
    }
    setNewOptions(false)
    setshowAddUpdateModal(false)
    setshowBulkUpload(false)
    setmodelBulk(false)
    setShowDelete(false)
  } 
  else if(response.data.alreadyExist == false){
    toast.success("Excel bulk data saved successfully !")
  
    window.location.reload()
  }   
    }).catch((error)=>{
      toast.error("Your file headers (or) data is invalid. Please verify first!")
    })
    getEnglishBook().then((response) => {
     
    }).catch((error)=>{
      toast.error(error)
    })
  
  });
  const addRegister=()=>{
  setTxtCourseFullName("");
    setTxtCourseShortName("");
    setTxtYears("");
    setTxtStatus("");
    setTxtHeading("");
    setNewOptions(true)

  }
  const deletePdfSubmit=(async(e,id)=>{
    e.preventDefault()
    try {
     
      const response = await deleteEnglishBookPDF(id).then((response) => {
        if(response.data)
          {
            toast.success("PDF file is deleted successfully !")
            window.location.reload()
          }
        
      }).catch((error)=>{console.log(error)})
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
    
  })

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  async function handleSubmit( event){
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      toast.error('Please fill in the required fields.');
      return;
    }
    if (!formValues.tcFiles.length && !formValues.markFiles.length) {
      toast.error('Please upload the required files.');
      return;
    }
    try {
     
      setLoading(true)
      const formData = new FormData();

        // Append files if they exist
        if (formValues.curriculum && formValues.curriculum.length > 0) {
          formData.append('curriculum', formValues.curriculum[0]); // Only one curriculum file
      }
        if (formValues.tcFiles) {
            formValues.tcFiles.forEach(file => formData.append('tcFiles', file));
        }
        if (formValues.markFiles) {
            formValues.markFiles.forEach(file => formData.append('markFiles', file));
        }
        if (formValues.otherFiles) {
            formValues.otherFiles.forEach(file => formData.append('otherFiles', file));
        }
        formData.append('academic', JSON.stringify(formValues.academic));
        // Append form fields
        Object.keys(formValues).forEach(key => {
          if (!['tcFiles', 'markFiles', 'otherFiles', 'curriculum', 'academic'].includes(key)) {
              formData.append(key, formValues[key]);
          }
      });
       
      const responses = await studentRegister(formData)
  .then(async (response) => {
    try {
      if (response.data) {
        toast.success("Student Registration saved successfully !");
        await sendOTPs(stuRegNo); // Await sendOTPs here
      }
    } catch (error) {
      // Catch any errors that occur inside the .then() block
      toast.error("Error sending OTP: " + error.message);
    }
  })
  .catch((error) => {
    // Catch errors from studentRegister or the whole promise chain
    toast.error("Error during registration: " + error.message);
  });

    }
    catch (error) {
      toast.error('Failed to save registration. Please try again.');
    }
    finally{
      setLoading(false)
    }
    setValidated(false);
    
   
  };
  const handleAcademicChange = (index, event) => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
        ...prevValues,
        academic: prevValues.academic.map((item, i) =>
            i === index ? { ...item, [name]: value } : item
        )
    }));
};
  
  const handleInputChange = (e,status) => {
    const { name, value } = e.target;
    let updatedValues
    
    setFormValues((prevValues) => {
      // Update the selected field (like 'program')
      if(status === 'program')
      {
        updatedValues = { ...prevValues, [value]: value };
        const selectedCourse = courseGet.find(course => course.courseId === value);
  
          if (selectedCourse) {
            // Update additional fields based on the selected course
            updatedValues = {
              ...updatedValues,
              program:selectedCourse.courseShortName,
              totalYear: selectedCourse.years+' Years',
              proType: selectedCourse.statusOfStudy== 'biblical'?"Biblical Program":selectedCourse.proType == 'lay'?"Lay People Program":"Short Study Program",
              courseFullName: selectedCourse.courseFullName,
            };
          }
      }
     
        if(status === 'fName')
          updatedValues = { ...prevValues, fName: value };
        if(status === 'mName')
          updatedValues = { ...prevValues, mName: value };
        if(status === 'lName')
          updatedValues = { ...prevValues, lName: value };
        if(status === 'gender')
          updatedValues = { ...prevValues, gender: value };
        if(status === 'bGroup')
          updatedValues = { ...prevValues, bGroup: value };
        if(status === 'mStatus')
          updatedValues = { ...prevValues, mStatus: value };
        if(status === 'nation')
          updatedValues = { ...prevValues, nation: value };
        if(status === 'con1')
          updatedValues = { ...prevValues, con1: value };
        if(status === 'con2')
          updatedValues = { ...prevValues, con2: value };
        if(status === 'email')
          updatedValues = { ...prevValues, email: value };
        if(status === 'fatName')
          updatedValues = { ...prevValues, fatName: value };
        if(status === 'fPhone')
          updatedValues = { ...prevValues, fPhone: value };
        if(status === 'fOccu')
          updatedValues = { ...prevValues, fOccu: value };
        if(status === 'moName')
          updatedValues = { ...prevValues, moName: value };
        if(status === 'moPhone')
          updatedValues = { ...prevValues, moPhone: value };
        if(status === 'mOccu')
          updatedValues = { ...prevValues, mOccu: value };
        if(status === 'tAddress1')
          updatedValues = { ...prevValues, tAddress1: value };
        if(status === 'tAddress2')
          updatedValues = { ...prevValues, tAddress2: value };
        if(status === 'tCity')
          updatedValues = { ...prevValues, tCity: value };
        if(status === 'tDistrict')
          updatedValues = { ...prevValues, tDistrict: value };
        if(status === 'tPost')
          updatedValues = { ...prevValues, tPost: value };
        if(status === 'tState')
          updatedValues = { ...prevValues, tState: value };
        if(status === 'pAddress1')
          updatedValues = { ...prevValues, pAddress1: value };
        if(status === 'pAddress2')
          updatedValues = { ...prevValues, pAddress2: value };
        if(status === 'pCity')
          updatedValues = { ...prevValues, pCity: value };
        if(status === 'pDistrict')
          updatedValues = { ...prevValues, pDistrict: value };
        if(status === 'pPost')
          updatedValues = { ...prevValues, pPost: value };
        if(status === 'pState')
          updatedValues = { ...prevValues, pState: value };
        if(status === 'emConName')
          updatedValues = { ...prevValues, emConName: value };
        if(status === 'relation')
          updatedValues = { ...prevValues, relation: value };
        if(status === 'emPhone')
          updatedValues = { ...prevValues, emPhone: value };
        if(status === 'emEmail')
          updatedValues = { ...prevValues, emEmail: value };
        if(status === 'degreeLev')
          updatedValues = { ...prevValues, degreeLev: value };
        if(status === 'study')
          updatedValues = { ...prevValues, study: value };
        if(status === 'curriculumOther')
          updatedValues = { ...prevValues, curriculumOther: value };
        if(status === 'paricipatin')
          updatedValues = { ...prevValues, paricipatin: value };
        if(status === 'resource')
          updatedValues = { ...prevValues, resource: value };
        if(status === 'skil')
          updatedValues = { ...prevValues, skil: value };
        if(status === 'sports')
          updatedValues = { ...prevValues, sports: value };
 
      return updatedValues;
    });
  };

  return (
    <div>
      
         <div className="row text-center">
         <h4 style={{fontWeight:"bold",textTransform:"uppercase"}}>Admission and Document Verification</h4></div>
       <div className="row" >
                        <div className="col-lg-3 mb-4">
                        <Form inline className="mb-2">
        <FormControl
          type="text"
          placeholder="Search"
          className="mr-sm-2 custom-search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form>
                        </div>
                        <div className="col-lg-6 mb-8 text-center" >
                        {currentItems.length > 0 ? (<div style={{display:"flex",justifyContent:"space-between"}}>
                          <div onClick={exportPDF} style={{ color: 'red',cursor:"pointer"}} title='Export PDF'>
                            <PictureAsPdf  /> PDF</div>
                          <div title='Export Excel' onClick={() => handleExport('excel')} style={{ color: 'green',cursor:"pointer" }}>
                            <Description  /> Excel</div>
                          <div title='Export CSV' onClick={() => handleExport('csv')} style={{ color: 'blue',cursor:"pointer"}}>
                            <InsertDriveFile  />  CSV</div>
                          <div title='Export TXT' onClick={() => handleExport('txt')} style={{ color: 'orange',cursor:"pointer" }}>
                            <TextSnippet /> TXT</div>
                          <div title='Export Word' onClick={() => handleExport('doc')} style={{ color: 'purple',cursor:"pointer" }}>
                            <TableChart /> Word</div>
    </div>):""}
                        </div>
                       
                        {/* {currentItems.length > 0 ? ( <div className="col-lg-3 mb-4 no-print">
                        <Button variant="warning" className="mr-2" onClick={() => window.print()}>
            Print
          </Button>
          <CSVLink data={data} headers={headers} filename={'data.csv'}>
            <Button variant="info">Export CSV</Button>
          </CSVLink>
                        </div>):""} */}
                       <div className="col-lg-3 mb-4 no-print" >
                        <div style={{float:"right"}}>
                        <Button variant="success"  onClick={() => addRegister()}>
            Add New Student Registration
          </Button></div>
                        </div>
                        </div>
               
              
                        {currentItems.length > 0 ? ( <div>
                          <Table striped ref={tableRef} hover className="custom-table" id="tablefg">
        <thead>
          <tr>
            <th>
              <input type="checkbox" style={{cursor:"pointer"}} title='seleect all' checked={selectAll} onChange={handleSelectAll} />
            </th>
            <th onClick={() => requestSort('courseId')} className={getClassNamesFor('courseId')}>
{sortConfig.first === "yes"? <div>S.no </div>:<div>S.no {sortConfig.key === 'courseId' && (sortConfig.direction === 'ascending' ? 
              <span>&#9650;</span> : <span>&#9660;</span>)}</div>}
             

            </th>
            <th onClick={() => requestSort('courseFullName')} className={getClassNamesFor('courseFullName')}>
              Full Name {sortConfig.key === 'courseFullName' && (sortConfig.direction === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>)}
            </th>
            <th onClick={() => requestSort('courseShortName')} className={getClassNamesFor('courseShortName')}>
            Course Name {sortConfig.key === 'courseShortName' && (sortConfig.direction === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>)}
            </th>
            <th onClick={() => requestSort('heading')} className={getClassNamesFor('heading')}>
            Course Type {sortConfig.key === 'heading' && (sortConfig.direction === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>)}
            </th>
            <th onClick={() => requestSort('years')} className={getClassNamesFor('years')}>
            Year of Course {sortConfig.key === 'years' && (sortConfig.direction === 'ascending' ? <span>&#9650;</span> : <span>&#9660;</span>)}
            </th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>
                <input
                style={{cursor:"pointer"}} title='seleect'
                  type="checkbox"
                  checked={selectedItems.includes(item.regNo)}
                  onChange={() => handleCheckboxChange(item.regNo)}
                />
              </td>
              <td>{item.regNo}</td>
              <td>{item.fName +' '+ item.mName +' '+ item.lName}</td>
              <td>{item.courseFullName}</td>
              <td>{item.proType}</td>
              <td>{item.totalYear}</td>
            
              <td>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                 
                  <div title="Edit">
                    <EditIcon
                      onClick={() => {
                        setNewOptions(false);
                        setshowAddUpdateModal(true);
                        setModelStatus("Edit New Record");
                        editRecords(item.regNo, item.courseFullName, item.courseShortName,
                             item.years, item.statusOfStudy,item.heading);
                      }}
                      style={{ fontWeight: "bold", color: "blue", cursor: "pointer" }}
                      title="Edit"
                    />
                  </div>
                  <div title="Delete">
                    <DeleteIcon
                      onClick={(e) => {
                        deleteRecordClick(e, item.regNo);
                      }}
                      style={{ fontWeight: "bold", color: "red", cursor: "pointer" }}
                      title="Delete"
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center no-print">
      <div>
          <Dropdown className='no-print'>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ backgroundColor: '#7f083c',border:"1px solid #7f083c" }}>
              Per Page: {perPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handlePerPageChange(25)}>25</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(50)}>50</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(100)}>100</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(250)}>250</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(500)}>500</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(1000)}>1000</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(5000)}>5000</Dropdown.Item>
              <Dropdown.Item onClick={() => handlePerPageChange(10000)}>10000</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Pagination className='no-prin'>
      <Pagination.First onClick={handleFirst} disabled={currentPage === 1} />
      <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1} />
      {renderPaginationItems()}
      <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages} />
      <Pagination.Last onClick={handleLast} disabled={currentPage === totalPages} />
    </Pagination>
       
      </div>
        </div>): <div className='text-center'><p style={{border:"1px dashed #000",color:"red"}}>No records found</p></div>}
        
        <Modal
    size={"lg"}
    show={showAddUpdateModal}
    onHide={() => modelHideClick()}
    aria-labelledby="example-modal-sizes-title-sm"
    centered
  >
    <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="m3d" style={{color:"white"}}>
      <span >{modelStatus}</span>
     </Modal.Title>
     </div>
   </Modal.Header>
   <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
   
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-2">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>Course Name</Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder="Course Name"
            value={txtCourseFullName}
            onChange={(e)=>{setTxtCourseFullName(e.target.value)}}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Main Title</Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder="Main Title"
            value={txtHeading}
            onChange={(e)=>{setTxtHeading(e.target.value)}}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} md="6" controlId="validationCustom03">
          <Form.Label>Departments</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Departments"
            value={txtCourseShortName}
            onChange={(e)=>{setTxtCourseShortName(e.target.value)}}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom04">
          <Form.Label>Years</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Years"
            value={txtYears}
            onChange={(e)=>{setTxtYears(e.target.value)}}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
       
      </Row>
     
      <Row className="mb-2">
       
        <Form.Group as={Col} md="6" controlId="validationCustom05">
          <Form.Label>Description</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Description"
            value={txtStatus}
            onChange={(e)=>{setTxtStatus(e.target.value)}}
            
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>

        </Row>
      
      <Row>
      <Form.Group className="mb-1">
      <br/>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-success"  type="submit">
          Submit
        </Button>
       
      </Form.Group>
      </Row>
      {/* <Button type="submit">Submit form</Button> */}
    </Form>
    
    </Modal.Body>
    </Modal>
    <Modal
         size={"xl"}
         show={newOptions}
         onHide={() => modelHideClick()}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m2d" style={{color:"white"}}>
           <span >Student Registration Form</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
        <Row className="mb-12" >
        <div style={{width:"100%",textAlign:"center"}}>
         <div style={{display:"flex",width:"100%",justifyContent:"center"}}>
          <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Student Registration Number: &nbsp;</span>
          <span style={{fontSize:"15pt",color:"blue",fontWeight:"bold"}}>{stuRegNo}</span></div></div>
        
      </Row>
      <Row>
     
          <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Personal Information</span>
       
      </Row>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="First Name"
            value={formValues.fName}
            onChange={(e)=>handleInputChange(e,'fName')}
            isInvalid={formValues.fName === ''}
          />
          <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Middle Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Middle Name"
            value={formValues.mName}
            onChange={(e)=>handleInputChange(e,'mName')}
          />
          <Form.Control.Feedback type="invalid">
  
  </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Last Title"
            value={formValues.lName}
            onChange={(e)=>handleInputChange(e,'lName')}
            isInvalid={formValues.lName === ''}
          />
          <Form.Control.Feedback type="invalid">
           *Required
          </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row className="mb-3">
      <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Gender</Form.Label>
        
          <Form.Select isInvalid={formValues.gender ===""} aria-label="Default select example"  
          onChange={(e)=>handleInputChange(e,'gender')}  placeholder="Gender"
          value={formValues.gender}
          >
          <option value="" disabled >
      --Select--
    </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="neuter">Neuter</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select 
            </Form.Control.Feedback>
        </Form.Group>
      <Form.Group as={Col} md="4" controlId="valiom02">
            <Form.Label>Date of Birth</Form.Label>
           
            <DatePicker
              selected={dob}
              onChange={handleDateChange}
              className={`form-control  custom-datepicker ${!dob ? 'is-invalid' : ''}`}
              dateFormat="MM/dd/yyyy"
              isInvalid={!dob}
              maxDate={maxDate}
              placeholderText="MM/DD/YYYY" // Placeholder added here
            />
           
            {/* {dob && (
              <p>
                Age: {calculateAge(dob)} years
              </p>
            )} */}
            <Form.Text style={{fontSize:"10pt"}} className={!dob ? 'text-danger' : ''}>
    {!dob && "Required"}
  </Form.Text>
          </Form.Group>
       
        {dob?(<Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Age</Form.Label>
          <Form.Control
           type="text"
           disabled="true"
            value={dob &&`${'Age: '+ (calculateAge(dob))+' Years'}`}
          />
        </Form.Group>):""}
       
      </Row>
      <Row className="mb-3">
      <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Blood Group</Form.Label>
          <Form.Select isInvalid={formValues.bGroup ===""} aria-label="Default select example"  
           onChange={(e)=>handleInputChange(e,'bGroup')}  placeholder="Blood Group"
          value={formValues.bGroup}
          >
          <option value="" disabled >
      --Select--
    </option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select 
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Marital Status</Form.Label>
          <Form.Select isInvalid={formValues.mStatus ===""} aria-label="Default select example"  
          onChange={(e)=>handleInputChange(e,'mStatus')}  placeholder="Marital Status"
          value={formValues.mStatus}
          >
          <option value="" disabled >
      --Select--
    </option>
            <option value="Single">Single</option>
            <option value="Married">Married</option>
            <option value="Widowed">Widowed</option>
            <option value="Divorced">Divorced</option>
            <option value="Separated">Separated</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Nationality</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Nationality"
            value={formValues.nation}
            onChange={(e)=>handleInputChange(e,'nation')}
            isInvalid={formValues.nation === ''}
          />
          <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
     
     <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Contact Information</span>
  
 </Row>
 <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Contact 1</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Contact 1"
            value={formValues.con1}
            onChange={(e)=>handleInputChange(e,'con1')}
            isInvalid={formValues.con1 === ''}
          />
          <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Contact 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Contact 2"
            value={formValues.con2}
            onChange={(e)=>handleInputChange(e,'con2')}
          />
          <Form.Control.Feedback type="invalid">
   
  </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Email"
            value={formValues.email}
            onChange={(e)=>handleInputChange(e,'email')}
            isInvalid={formValues.email === ''}
          />
         <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Father's Name</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Father's Name"
            value={formValues.fatName}
            onChange={(e)=>handleInputChange(e,'fatName')}
            isInvalid={formValues.fatName === ''}
          />
          <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Father's Contact Number</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Father's Contact Number"
            value={formValues.fPhone}
            onChange={(e)=>handleInputChange(e,'fPhone')}
            isInvalid={formValues.fPhone === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Occupation </Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Occupation"
            value={formValues.fOccu}
            onChange={(e)=>handleInputChange(e,'fOccu')}
            isInvalid={formValues.fOccu === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Mother's Name</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Mother's Name"
            value={formValues.moName}
            onChange={(e)=>handleInputChange(e,'moName')}
            isInvalid={formValues.moName === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Mother's Contact Number</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Mother's Contact Number"
            value={formValues.moPhone}
            onChange={(e)=>handleInputChange(e,'moPhone')}
            isInvalid={formValues.moPhone === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Occupation </Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Occupation"
            value={formValues.mOccu}
            onChange={(e)=>handleInputChange(e,'mOccu')}
            isInvalid={formValues.mOccu === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row>
     
     <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Present Address</span>
  
 </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Street Address Line1</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Street Address Line1"
            value={formValues.tAddress1}
            onChange={(e)=>handleInputChange(e,'tAddress1')}
            isInvalid={formValues.tAddress1 === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Street Address Line2</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Street Address Line2"
            value={formValues.tAddress2}
            onChange={(e)=>handleInputChange(e,'tAddress2')}
            isInvalid={formValues.tAddress2 === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>City </Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="City"
            value={formValues.tCity}
            onChange={(e)=>handleInputChange(e,'tCity')}
            isInvalid={formValues.tCity === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>District</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="District"
            value={formValues.tDistrict}
            onChange={(e)=>handleInputChange(e,'tDistrict')}
            isInvalid={formValues.tDistrict === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Postal / Zip Code</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Postal / Zip Code"
            value={formValues.tPost}
            onChange={(e)=>handleInputChange(e,'tPost')}
            isInvalid={formValues.tPost === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>State / Province </Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="State / Province"
            value={formValues.tState}
            onChange={(e)=>handleInputChange(e,'tState')}
            isInvalid={formValues.tState === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row>
     
          <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Permanent Address</span>
       
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Street Address Line1</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Street Address Line1"
            value={formValues.pAddress1}
            onChange={(e)=>handleInputChange(e,'pAddress1')}
            isInvalid={formValues.pAddress1 === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Street Address Line2</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Street Address Line2"
            value={formValues.pAddress2}
            onChange={(e)=>handleInputChange(e,'pAddress2')}
            isInvalid={formValues.pAddress2 === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>City </Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="City"
            value={formValues.pCity}
            onChange={(e)=>handleInputChange(e,'pCity')}
            isInvalid={formValues.pCity === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>District</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="District"
            value={formValues.pDistrict}
            onChange={(e)=>handleInputChange(e,'pDistrict')}
            isInvalid={formValues.pDistrict === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Postal / Zip Code</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Postal / Zip Code"
            value={formValues.pPost}
            onChange={(e)=>handleInputChange(e,'pPost')}
            isInvalid={formValues.pPost === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>State / Province </Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="State / Province"
            value={formValues.pState}
            onChange={(e)=>handleInputChange(e,'pState')}
            isInvalid={formValues.pState === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row>
     
     <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Emergency Contact Information</span>
  
 </Row>
 <Row className="mb-2">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>Emergency Contact Name</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Emergency Contact Name"
            value={formValues.emConName}
            onChange={(e)=>handleInputChange(e,'emConName')}
            isInvalid={formValues.emConName === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
        
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Relationship to Student </Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Relationship to Student"
            value={formValues.relation}
            onChange={(e)=>handleInputChange(e,'relation')}
            isInvalid={formValues.relation === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>Emergency Contact Phone Number</Form.Label>
          <Form.Control
            required
           type="text"
            placeholder="Emergency Contact Phone Number"
            value={formValues.emPhone}
            onChange={(e)=>handleInputChange(e,'emPhone')}
            isInvalid={formValues.emPhone === ''}
            />
            <Form.Control.Feedback type="invalid">
      *Required
    </Form.Control.Feedback>
        </Form.Group>
      
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Emergency Contact Email Address </Form.Label>
          <Form.Control
           type="text"
            placeholder="Emergency Contact Email Address"
            value={formValues.emEmail}
            onChange={(e)=>handleInputChange(e,'emEmail')}
            isInvalid={formValues.pState === ''}
            />
            <Form.Control.Feedback type="invalid">
      
    </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Row>
     
     <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Academic Background</span>
  
 </Row>
 <Table striped bordered hover style={{borderBottom:"3px solid #7f083c",borderRadius:"15px"}}>
        <thead>
          <tr>
            <th>Academic Details</th>
            <th>College/University</th>
            <th>Year of Passout</th>
            <th>Total Marks</th>
            <th>Percentage</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {formValues.academic.map((detail, index) => (
            <tr key={index}>
              <td>
                <Form.Select
                  isInvalid={detail.course === ""}
                  aria-label="Default select example"
                  onChange={(e) => handleAcademicChange(index, e)}
                  name="course"
                  value={detail.course}
                  required
                >
                  <option value="" disabled>
                    --Select--
                  </option>
                  <option value="SSLC">SSLC</option>
                  <option value="+2">+2</option>
                  <option value="B.A">B.A</option>
                  <option value="B.Com">B.Com</option>
                  <option value="B.B.A">B.B.A</option>
                  <option value="B.Sc">B.Sc</option>
                  <option value="B.E">B.E</option>
                  <option value="B.Tech">B.Tech</option>
                  <option value="B.Arch">B.Arch</option>
                  <option value="B.Ed">B.Ed</option>
                  <option value="B.Th">B.Th</option>
                  <option value="M.A">M.A</option>
                  <option value="M.Com">M.Com</option>
                  <option value="M.B.A">M.B.A</option>
                  <option value="M.Sc">M.Sc</option>
                  <option value="M.E">M.E</option>
                  <option value="M.Tech">M.Tech</option>
                  <option value="M.Arch">M.Arch</option>
                  <option value="M.Div">M.Div</option>
                  <option value="M.Th">M.Th</option>
                  <option value="M.Phil">M.Phil</option>
                  <option value="Ph.D">Ph.D</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select
                </Form.Control.Feedback>
              </td>

              <td>
                <Form.Control
                  required
                  type="text"
                  placeholder="College/University"
                  value={detail.university}
                  onChange={(e) => handleAcademicChange(index, e)}
                  name="university"
                  isInvalid={detail.university === ''}
                  />
                  <Form.Control.Feedback type="invalid">
            *Required
          </Form.Control.Feedback>
              </td>

              <td>
                <Form.Select
                required
                  isInvalid={detail.yearOfPassout === ""}
                  aria-label="Default select example"
                  onChange={(e) => handleAcademicChange(index, e)}
                  name="yearOfPassout"
                  value={detail.yearOfPassout}
                >
                  <option value="" disabled>
                    --Select--
                  </option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select 
                </Form.Control.Feedback>
              </td>

              <td>
                <Form.Control
                  required
                  type="text"
                  placeholder="Total Marks"
                  value={detail.totalMarks}
                  onChange={(e) => handleAcademicChange(index, e)}
                  name="totalMarks"
                  isInvalid={detail.totalMarks === ''}
                  />
                  <Form.Control.Feedback type="invalid">
            *Required
          </Form.Control.Feedback>
              </td>

              <td>
                <Form.Control
                  required
                  type="text"
                  placeholder="%"
                  value={detail.percentage}
                  onChange={(e) => handleAcademicChange(index, e)}
                  name="percentage"
                  isInvalid={detail.percentage === ''}
                  />
                  <Form.Control.Feedback type="invalid">
            *Required
          </Form.Control.Feedback>
              </td>

              <td>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <AddIcon
                    onClick={handleAddRow}
                    style={{ fontWeight: "bold", color: "green", cursor: "pointer", fontSize: "25pt" }}
                    title="Add"
                  />
                  {formValues.academic.length > 1 && (
                    <DeleteIcon
                      onClick={() => handleDeleteRow(index)}
                      style={{ fontWeight: "bold", color: "red", cursor: "pointer", fontSize: "23pt" }}
                      title="Delete"
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}>Preferred Program</span>
      <Row className="mb-3">
      <Form.Group as={Col} md="4" controlId="validationCustom02">
      <Form.Label>Program of Interest</Form.Label>
      <Form.Select
      required
        aria-label="Default select example"
        onChange={(e)=>handleInputChange(e,'program')}
        name="program"
        value={formValues.program}
        isInvalid={formValues.program === ''}
      >
        <option value="" disabled>
          --Select--
        </option>
        {courseGet.map((option, idx) => (
          <option key={idx} value={option.courseId}>
            {option.courseShortName}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        Please select
      </Form.Control.Feedback>
    </Form.Group>
        
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Degree Level</Form.Label>
        
          <Form.Select isInvalid={formValues.degreeLev ===""} aria-label="Default select example"  
          onChange={(e)=>handleInputChange(e,'degreeLev')}  placeholder="Degree Level"
          value={formValues.degreeLev} required
          >
          <option value="" disabled >
      --Select--
    </option>
            <option value="ug">Undergraduate</option>
            <option value="pg">Postgraduate</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select  
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
        <Form.Label>Mode of Study</Form.Label>
        
          <Form.Select isInvalid={formValues.study ===""} aria-label="Default select example"  
          onChange={(e)=>handleInputChange(e,'study')}  placeholder="Mode of Study"
          value={formValues.study} required
          >
          <option value="" disabled >
      --Select--
    </option>
            <option value="Full">Full-time</option>
            <option value="Part">Part-time</option>
            <option value="Short">Short-studies</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select  
            </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      {formValues.program && (
  <Row className="mb-3">
    <Form.Group as={Col} md="4">
      <Form.Label>Total Year's of Course</Form.Label>
      <Form.Control
        type="text"
        disabled
        value={formValues.totalYear}
      />
     
    </Form.Group>

    <Form.Group as={Col} md="4" >
      <Form.Label>Program Type</Form.Label>
      <Form.Control
        type="text"
        disabled
        value={formValues.proType}
      />
      
    </Form.Group>

    <Form.Group as={Col} md="4">
      <Form.Label>Course Full Name</Form.Label>
      <Form.Control
        type="text"
        disabled
        value={formValues.courseFullName}
        title={formValues.courseFullName}
      />
      
    </Form.Group>
  </Row>
)}


      <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}> Additional Information</span>
      <Row className="mb-3">
      <Form.Group as={Col} md="4" controlId="validationCustom02">
      <Form.Label> Curriculum Vitae or List of Achievements</Form.Label>
      <Form.Control 
    type="file" 
    accept=".jpg,.jpeg,.png,.webp,.heic,.heif" 
     
    
    onChange={(e) => fileSelectedHandler(e, "curriculum")} 
  />
  <Form.Control.Feedback type="invalid">
  </Form.Control.Feedback>
  <Form.Text className="text-muted">
    Please select image files (.jpg, .jpeg, .png, .webp, .heic, .heif formats). You can select multiple files.
  </Form.Text>
        </Form.Group>
        
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Other Extra Curricular Activities</Form.Label>
        
          <Form.Control
           type="text"
            placeholder="Other Extra Curricular Activities"
            value={formValues.curriculumOther}
            onChange={(e)=>handleInputChange(e,'curriculumOther')}
          />
         <Form.Control.Feedback type="invalid">
   
  </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
        <Form.Label>Extra Curricular Participation</Form.Label>
        
          <Form.Select aria-label="Default select example"  
          placeholder="Extra Curricular Participation"
          value={formValues.paricipatin}
            onChange={(e)=>handleInputChange(e,'paricipatin')}
          >
          <option value="" disabled >
      --Select--
    </option>
            <option value="Full">Student Council</option>
            <option value="Part">Class Officer</option>
            <option value="Short">Club/Organization</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select  
            </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}> Other Details</span>
      <Row className="mb-3">
     
        <Form.Group as={Col} md="4" controlId="validationCustom01">
        <Form.Label>Resources Available</Form.Label>
        
          <Form.Select  aria-label="Default select example"  
          placeholder="Resources Available"
          value={formValues.resource}
            onChange={(e)=>handleInputChange(e,'resource')}
          >
          <option value="" disabled >
      --Select--
    </option>
            <option value="Full">Car/Transportation</option>
            <option value="Part">Event Venue/House</option>
            <option value="Short">Printer</option>
            <option value="Part">LCD Projector</option>
            <option value="Short">Laptop</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select  
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
        <Form.Label>Skills/Talents</Form.Label>
        
          <Form.Select isInvalid={formValues.skil ===""} aria-label="Default select example"  
           placeholder="Skills/Talents"
           value={formValues.skil}
           onChange={(e)=>handleInputChange(e,'skil')}
          >
          <option value="" disabled >
      --Select--
    </option>
            <option value="Full">Acting</option>
            <option value="Part">Arts/Crafts</option>
            <option value="Short">Calculating</option>
            <option value="Part">Dancing</option>
            <option value="Short">Eating</option>
            <option value="Full">Fashion</option>
            <option value="Part">Photography</option>
            <option value="Short">Playing drums</option>
            <option value="Part">Playing guitar</option>
            <option value="Short">Playing piano</option>
            <option value="Full">Programming</option>
            <option value="Part">Singing</option>
            <option value="Short">Writing</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select  
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
        <Form.Label>Sports</Form.Label>
        
          <Form.Select isInvalid={formValues.sports ===""} aria-label="Default select example"  
           placeholder="Sports"
           value={formValues.sports}
           onChange={(e)=>handleInputChange(e,'sports')}
          >
          <option value="" disabled >
      --Select--
    </option>
    <option value="Full">Badminton</option>
            <option value="Part">Basketball</option>
            <option value="Short">Bowling</option>
            <option value="Part">Chess</option>
            <option value="Short">Frisbee</option>
            <option value="Full">Scrabble</option>
            <option value="Part">Soccer</option>
            <option value="Short">Softball</option>
            <option value="Part">Swimming</option>
            <option value="Short">Table Tennis</option>
            <option value="Full">Track and Field</option>
            <option value="Part">Tennis</option>
            <option value="Short">Writing</option>
            <option value="Short">Volleyball</option>
         </Form.Select>
         <Form.Control.Feedback type="invalid">
             Please select  
            </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <span style={{fontSize:"15pt",color:"#000",fontWeight:"bold"}}> Documents</span>
      <Row className="mb-3">
      <Form.Group as={Col} md="4" controlId="validationCustom02">
  <Form.Label>Transfer Certificate</Form.Label>
  <Form.Control 
    type="file" 
    accept=".jpg,.jpeg,.png,.webp,.heic,.heif" 
    multiple 
    required 
    onChange={(e) => fileSelectedHandler(e, "tc")} 
    isInvalid={!formValues.tcFiles.length}
  />
  <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
  <Form.Text className="text-muted">
    Please select image files (.jpg, .jpeg, .png, .webp, .heic, .heif formats). You can select multiple files.
  </Form.Text>
</Form.Group>

        <Form.Group as={Col} md="4" controlId="validationCustom03">
      <Form.Label>Mark Sheet</Form.Label>
      <Form.Control 
    type="file" 
    accept=".jpg,.jpeg,.png,.webp,.heic,.heif" 
    multiple
    required  
    onChange={(e) => fileSelectedHandler(e, "mark")} 
    isInvalid={!formValues.markFiles.length}
  />
  <Form.Control.Feedback type="invalid">
    *Required
  </Form.Control.Feedback>
  <Form.Text className="text-muted">
    Please select image files (.jpg, .jpeg, .png, .webp, .heic, .heif formats). You can select multiple files.
  </Form.Text>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
      <Form.Label>Other Certificate</Form.Label>
      <Form.Control 
    type="file" 
    accept=".jpg,.jpeg,.png,.webp,.heic,.heif" 
    multiple 
    
    onChange={(e) => fileSelectedHandler(e, "other")} 
  />
  <Form.Control.Feedback type="invalid">
  </Form.Control.Feedback>
  <Form.Text className="text-muted">
    Please select image files (.jpg, .jpeg, .png, .webp, .heic, .heif formats). You can select multiple files.
  </Form.Text>
        </Form.Group>
       
      </Row>
        {/* <Form.Group as={Col} md="4" controlId="validationCustom02">
        <Form.Label>Signature</Form.Label>
       
        <SignaturePad penColor='#4285f4'
            onEnd={() => { setIsSigned(true) }}
            canvasProps={{ width: 300, height: 200, className: 'sig-canvas curSig' }}
            ref={(ref) => { sigPad = ref }} />
                                    <div>
                                      <br/>
                                    <Button onClick={(e) => clearSig(e)}>Clear</Button>
                                    </div>
        </Form.Group> */}
      
      <Row className="mb-3">
      <Form.Group as={Col} md="4">
        <br/>
      <Button style={{border:"2px solid #c90057",width:"100%"}} variant='light' buttonvariant="outline-light" onClick={() => modelHideClick()}>
          Cancel
        </Button>
        </Form.Group>
        <Form.Group as={Col} md="4">
        </Form.Group>
      <Form.Group as={Col} md="4">
      <br/>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-success"  type="submit">
          Submit
        </Button>
       
      </Form.Group>
      </Row>
      {/* <Button type="submit">Submit form</Button> */}
    </Form>
   
        </Modal.Body>
      </Modal>
      <Modal
         size={"md"}
         show={modelBulk}
         onHide={() => modelHideClick()}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="m1d" style={{color:"white"}}>
           <span >{modelStatus}</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>


        <Form noValidate validated={validated} onSubmit={fileUploadHandler}>
      
     
      <Row>
       

        <Form.Group as={Col}  controlId="formFiles" className="mb-3">
        <Form.Label>Bulk File Upload</Form.Label>
        <Form.Control type="file" accept=".xlsx" onChange={(e)=>fileSelectedHandler(e,"bulk")} />
        <Form.Text className="text-muted">
          Please only select Excel files (.xlsx format).
        </Form.Text>
      </Form.Group>
        </Row>
      
      <Row>
      <Form.Group className="mb-1">
      <br/>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-success"  type="submit">
          Submit
        </Button>
       
      </Form.Group>
      </Row>
      {/* <Button type="submit">Submit form</Button> */}
    </Form>
       


        </Modal.Body>
      </Modal>
      {/* pdf model */}
      
      <Modal show={show}  size="lg" centered 
      dialogClassName="fullscreen-modal"
      onHide={() => setShow(false)}>
      <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="m3d" style={{color:"white"}}>
      <span >{bookTitle}</span>
     </Modal.Title>
     </div>
   </Modal.Header>
        <Modal.Body>
        {/* {files.map((file, index) => (
        <div key={index}> */}
        {files[0]?<PdfFlip pdf={files[0].url}/>:""}
          </Modal.Body>
      </Modal>

      <Modal show={showDelete}  size="md" centered 
      onHide={() => setShowDelete(false)}>
      <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="m3d" style={{color:"white"}}>
      <span >Delete Record</span>
     </Modal.Title>
     </div>
   </Modal.Header>
        <Modal.Body>
       <div><span>{selectedItems.length>1?"Are you sure? You have selected multiple records. Do you want delete multiple records?"
       :"Are you sure? Do you want delete this record?"}</span></div>
       <br/>
       <div style={{display:"flex",justifyContent:"space-between"}}>
       <div>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-danger"  type="submit" onClick={(e)=>deleteRecordSubmit(e,selectedItems)}>
          Yes
        </Button></div>
        <div>
        <Button style={{border:"2px solid #c90057",width:"100%"}} variant='light' buttonvariant="outline-light" onClick={() => modelHideClick()}>
          No
        </Button></div>
       </div>
          </Modal.Body>
      </Modal>
      <Modal show={deleteFile}  size="md" centered 
      onHide={() => setDeleteFile(false)}>
      <Modal.Header style={{background:"#c90057"}} onHide={() => setDeleteFile(false)} closeButton closeVariant="white">
    <div style={{width:"100%",textAlign:"center"}}>
      <Modal.Title id="m3d" style={{color:"white"}}>
      <span >Delete File</span>
     </Modal.Title>
     </div>
   </Modal.Header>
        <Modal.Body>
       <div><span>Are you sure? Do you want delete this file?</span></div>
       <br/>
       <div style={{display:"flex",justifyContent:"space-between"}}>
       <div>
    <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
         buttonvariant="outline-danger"  type="submit" onClick={(e)=>deletePdfSubmit(e,bookId)}>
          Yes
        </Button></div>
        <div>
        <Button style={{border:"2px solid #c90057",width:"100%"}} variant='light' buttonvariant="outline-light" onClick={() => setDeleteFile(false)}>
          No
        </Button></div>
       </div>
          </Modal.Body>
      </Modal>
      
    <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
     {loading && <Loader />}
    </div>
  );
};

export default OfficeStuRegView;