import "./../../styles/home.css";
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Alert,
  Modal,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import  EncryptText from "../../encrypt-decrypt/encrypt";
function LibraryMain() {
  const encrpt =EncryptText
  const [validated, setValidated] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [modelText, setModelText] = useState(null);
  const navigate = useNavigate();
  const modelHideClick =((e)=>{
    setLgShow(false)
  })
  const lbryDeskClick = async (e,status)=>{
   if(status == "catlog")
   {
    setLgShow(true)
   }
   else
   {
    navigate('/user/dashboard-main?getData='+encrpt(status))
   }
    // navigate('/user/dashboard-main?getData='+encrpt(status))
   
}
    return (
      <div>
        <div className="row text-center">
         <h4 style={{fontWeight:"bold"}}>LIBRARY DESK</h4></div>
        <div className="row">
                        <div className="col-lg-3 mb-4">
                            <div title="click !"  style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow text-center">
                                <div className="card-body">
                                Student's Details
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !"    style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow text-center">
                                <div className="card-body">
                                Visitor's Details
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-8">
                            <div title="click !" onClick={(e)=>{lbryDeskClick(e,'catlog')}} style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow text-center">
                                <div className="card-body">
                                Cateloging of books and journals
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow text-center">
                                <div className="card-body">
                                Books and Journal View
                                    
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 mb-4">
                            <div title="click !" style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Guest ( or ) Visitor's Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Musical Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Teacher's Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Classroom Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                            <div title="click !" style={{background:"#7f083c",cursor:"pointer"}} className="card text-white shadow">
                                <div className="card-body">
                                    Other's Desk
                                    <div className="text-white-50 small">{'( Add, view , delete , update )'}</div>
                                </div>
                            </div>
                        
                        </div> */}
                    </div>
       <div className="row">
        <div className="text-center">
         <h4 style={{fontWeight:"bold"}}>{}</h4></div>
                      
         <Modal
         size={"lg"}
         show={lgShow}
         onHide={() => modelHideClick()}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="example-modal-sizes-title-sm" style={{color:"white"}}>
           <span >Choose Options</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>

<table style={{width:"100%",justifyContent:"space-between"}}>
  <tbody>
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       onClick={(e)=>{lbryDeskClick(e,'english-book')}}
       buttonvariant="outline-success"  
       type="submit"
       >
        Digital Biblical English Library

      </Button></td>
    <td><Button onClick={(e)=>{lbryDeskClick(e,'tami-book')}} style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
        Digital Biblical Tamil Library
        
      </Button></td>
  </tr>
  <tr>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
        Digital Biblical Music Library

      </Button></td>
    <td><Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
       
       buttonvariant="outline-success"  
       type="submit"
       >
        Journals

      </Button></td>
  </tr>
</tbody>
</table>
        
       


        </Modal.Body>
      </Modal>
                    </div>
                    <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
      </div>
    );
  }
  
  export default LibraryMain;