import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import conf from "./../../assets/images/conf.jpg";
import { getUser } from "../../service/registration/onboardingUser";
import { error } from "jquery";
import { getProperties } from "../../services";
// import Navbar from "./../../components/Navbar";

const HomeVideo = () => {
  const [RoomCode, setRoomCode] = useState("");
  const navigate = useNavigate();

  const submitCode = (e) => {
    let user1 = getProperties().then((response) => {}).catch((error)=>{console.log("test")})
     //random link generated start
  const chars = [['0', 10], ['a', 26], ['A', 26], ['-'], ['_']]
  .flatMap(([c, length=1]) =>
    Array.from({length}, (_,i) => String.fromCharCode(c.charCodeAt(0)+i)))

const gen = length => Array.from({length},
  () => chars[Math.random()*chars.length|0]).join('')
var str =gen(12);
  var output = [str.slice(0, 3), str.slice(4,8), str.slice(8,13)].join('-');
 
  //random link generated end
    e.preventDefault();
    navigate(`/user/room/${output}`);
  };
  
 
  return (
    <div className=" ">
      {/* Navbar */}
      {/* <Navbar /> */}
      {/* Hero */}
      <div className="relative h-screen ">
        {/* Image */}
        <div className="absolute h-full w-full flex overflow-hidden">
          <img src={conf} width={100} height={100} className="object-cover  w-full h-full" />
        </div>
        {/* Overlay */}
        <div className="absolute h-full w-full flex overflow-hidden bg-black/60"></div>
        {/* Hero Info */}
        <div className="lg:flex lg:pt-20 flex-col items-center justify-center relative z-10 px-6 md:max-w-[90vw] mx-auto">
          {/* Main */}
          <div className=" flex flex-col items-center justify-center pb-8">
            <h1 className="text-[50px] md:text-[80px] text-white font-bold pt-12">
              Video Chat App
            </h1>
            <p className="text-[26px] text-white  -mt-2">With ZegoCloud</p>
          </div>

          {/* Enter Code */}
          <form
            onSubmit={submitCode}
            className="text-white md:pt-12 flex flex-col items-center justify-center"
          >
            <div className=" flex flex-col justify-center items-center">
              <label className="text-[30px] md:text-[40px] font-bold pt-6">
                Enter Room Code 
              </label>

              {/* <input
                type="text"
                required
                placeholder="Enter Room Code"
                value={RoomCode}
                onChange={(e) => setRoomCode(e.target.value)}
                className="py-1.5 md:py-2 px-4 rounded-full max-w-[14rem] mt-2 text-black md:mt-6 outline-0"
              /> */}
            </div>
            <button
              type="submit"
              className=" bg-blue-500 hover:bg-blue-400 duration-100 ease-out font-bold w-[5rem] md:w-[7rem] rounded-full py-[5px] md:py-[7px] mt-2 md:mt-4 "
            >
              Go
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HomeVideo;
