// Loader.js
import React, { useEffect, useState } from 'react';
import '../styles/Loader.css';

const Loader = () => {
  const [borderColor, setBorderColor] = useState('red');

  useEffect(() => {
    const interval = setInterval(() => {
      // Generate a random color
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      setBorderColor(randomColor);
    }, 1000); // Change color every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const spinnerStyle = {
    borderTopColor: borderColor, // Apply the random color dynamically
  };

  return (
    <div className="loader">
      <div className="spinner" style={spinnerStyle}></div>
    </div>
  );
};

export default Loader;
