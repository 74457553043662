
import {Container,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Alert,
  Modal,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';

import { Envelope, Eye, EyeSlashFill, Key } from "react-bootstrap-icons";

import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Homemain from '../pages/homeMain';
import { getUsers, getUsersDetails } from '../services';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  import  EncryptText from "../encrypt-decrypt/encrypt";
function Login() {
  const encrpt =EncryptText
  const [validated, setValidated] = useState(false);
  const [lgShow, setLgShow] = useState(true);
  const navigate = useNavigate();
  const modelHideClick =((e)=>{
    navigate('/')
  })
  const btnRegisterOption =((e)=>{
    navigate('/register-options')
  })
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
   
    setValidated(true);
  };
  const [showPass, setShowPass] = useState(false);
  const [showPassType, setShowPassType] = useState("password");
  const [textEmail, setTextEmail] = useState("");
  const [textPassword, setTextPassword] = useState("");
  const loginClick =(async(el)=>{
    el.preventDefault();
    let userGet =await getUsersDetails(textEmail,textPassword).then((response) => {
     
      if(response.data !== false)
      {
        sessionStorage.setItem("accessToken",JSON.stringify(response.data.accessToken));
        sessionStorage.setItem("refreshToken",JSON.stringify(response.data.refreshToken));
        sessionStorage.setItem("fullName",encrpt(JSON.stringify(response.data.fullName)));
        sessionStorage.setItem("emailGet",encrpt(JSON.stringify(response.data.emailGet)));
        sessionStorage.setItem("age",encrpt(JSON.stringify(response.data.age)));
        sessionStorage.setItem("gender",encrpt(JSON.stringify(response.data.gender)));
        sessionStorage.setItem("phone",encrpt(JSON.stringify(response.data.phone)));
        if(
          sessionStorage.getItem('previousurl') != undefined &&
          sessionStorage.getItem('previousurl') != null &&
          sessionStorage.getItem('previousurl') != ""
        )
        {
          const url =sessionStorage.getItem('previousurl')
          
          navigate(url)

          sessionStorage.removeItem('previousurl')
        }
        else{
          sessionStorage.removeItem('previousurl')
           toast.success('You have successfully login');
           navigate('/secret-code-register')
          //  navigate('/user/dashboard-main')
        }
       
      }
      else if(response.data == false)
      {
        toast.error('Email or password is incorrect !');
      }
    }).catch((error)=>{toast.error(error)})
    
  })
  const clickHandler = (el) => {
   
    setShowPass((prev) => !prev);
    if(showPassType == "password")
    setShowPassType("text")
    if(showPassType == "text")
    setShowPassType("password")
  };
  const txtOnchange = ((event,status) => {
    if(status == "email")
    setTextEmail(event.target.value);
    if(status == "password")
    setTextPassword(event.target.value);
  });

    return (
      <div>
        <Homemain/>
         <Modal
         size={"sm"}
         show={lgShow}
         onHide={() => modelHideClick()}
         aria-labelledby="example-modal-sizes-title-sm"
         centered
       >
         <Modal.Header style={{background:"#c90057"}} onHide={(e)=>modelHideClick()} closeButton closeVariant="white">
         <div style={{width:"100%",textAlign:"center"}}>
           <Modal.Title id="example-modal-sizes-title-sm" style={{color:"white"}}>
           <span >Login</span>
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body  style={{borderBottom:"5px solid #c90057"}}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{fontWeight:"bold"}}>Email address</Form.Label>
          <InputGroup className="mb-3">
        <InputGroup.Text id="password-input">
        <Envelope/>
        </InputGroup.Text>
          <Form.Control 
          value={textEmail}
          onChange={(e)=>{txtOnchange(e,"email")}}
          required type="email" placeholder="Enter email" />
         </InputGroup>
        </Form.Group>
 
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label style={{fontWeight:"bold"}}>Password</Form.Label>
          <InputGroup className="mb-3">
        <InputGroup.Text id="password-input">
          <Key />
        </InputGroup.Text>
        <Form.Control
          type={showPassType}
          name="password"
          placeholder="Password"
          aria-label="password"
          aria-describedby="password-input"
          required
          value={textPassword}
          onChange={(e)=>{txtOnchange(e,"password")}}
        />
        <InputGroup.Text onClick={()=>{clickHandler()}}>
          {/* You can use both. I ran into some width issues with FontAwesome but they can be fixed by playing around */}
          {/* <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} /> */}
          {showPass ? <EyeSlashFill style={{color:"#c90057",fontWeight:"bold"}} title='Click Hide'/> : 
          <Eye title='Click Show' style={{color:"#c90057",fontWeight:"bold"}} />}
        </InputGroup.Text>
      </InputGroup>
        </Form.Group>
        <Form.Group style={{display:"flex",justifyContent:"space-between"}} className="mb-3" controlId="formBasicCheckbox">
          <Form.Check style={{fontSize:"10pt"}} type="checkbox" label="Remember me" />
          <a style={{color:"#7f083c",fontSize:"10pt"}} href='#'>Forgot password?</a>
        </Form.Group>
       
        
        
       
       
      </Form>
      <Button style={{background:"#7f083c",border:"2px solid #c90057",width:"100%"}}
        onClick={(e)=>{loginClick(e)}}
         buttonvariant="outline-success"  
         type="submit"
         >
          Click Gateway
        </Button>
        <div style={{padding:"5px"}}></div>
        {/* <Button variant='success' buttonvariant="outline-success" type="submit">
          Register
        </Button> */}
        <Button style={{border:"2px solid #c90057",width:"100%"}} variant='light' buttonvariant="outline-light" onClick={() => modelHideClick()}>
          Cancel
        </Button>
      <br/>
      <div style={{fontSize:"10pt",textAlign:"center",paddingTop:"10px"}}>
        Don't have an account?<a style={{color:"#7f083c"}} href="#" onClick={(e) => btnRegisterOption()}> Register</a>
        </div>
      </Modal.Body>
      </Modal>
      <ToastContainer
    
    autoClose={3000}
    closeOnClick={false}
    pauseOnHover
     />
      </div>
    );
  }
  
  export default Login;